import { FC } from 'react'
import { useQuery } from 'urql'
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard } from '@fortawesome/pro-duotone-svg-icons'

import Alert from 'src/components/01-atoms/Alert'
import Card from 'src/components/01-atoms/Card'
import useAppParams from 'src/utils/hooks/useAppParams'

import getCarrierInfo from 'src/graphql/queries/getCarrierInfo.graphql'
import {
  IGetCarrierInfoQuery,
  IGetCarrierInfoQueryVariables,
} from 'src/graphql/queries/getCarrierInfo.types'

import classNames from 'classnames'
import CardAccordion from 'src/components/02-molecules/CardAccordion'
import CardAccordionHeader from 'src/components/02-molecules/CardAccordion/header'
import CardAccordionContent from 'src/components/02-molecules/CardAccordion/content'
import CarrierInfoContent from './content'

const CarrierInfo: FC = () => {
  const { mdashAccountId, toShipOnAsString } = useAppParams()

  const [{ fetching: fetchingCarrierInfo, error: carrierInfoError, data: carrierInfoData }] =
    useQuery<IGetCarrierInfoQuery, IGetCarrierInfoQueryVariables>({
      query: getCarrierInfo,
      variables: {
        mdashAccountId,
        toShipOn: toShipOnAsString,
      },
      requestPolicy: 'cache-and-network',
      pause: Number( mdashAccountId || 0 ) < 1,
    })

  const carrierInfo = carrierInfoData?.manifestCarrierInfo

  return (
    <div className={classNames({ 'p-0': fetchingCarrierInfo }, 'col-span-12 lg:col-span-3' )}>
      {carrierInfoError && <Alert type="error">{carrierInfoError.message}</Alert>}
      {fetchingCarrierInfo ? (
        <Card className="p-0 hidden lg:block h-full bg-white">
          <Skeleton className="items-center opacity-25" height="100%" width="100%" />
        </Card>
      ) : (
        carrierInfo &&
        !carrierInfoError && (
          <>
            <Card className="hidden lg:block h-full">
              <div>
                <FontAwesomeIcon icon={faAddressCard} className="text-gb-gray-900 text-2xl" />
              </div>
              <div className="font-semibold mb-2">
                {carrierInfo.carrierName} Pick-up Information
              </div>
              <CarrierInfoContent {...carrierInfo} />
            </Card>

            <CardAccordion className="lg:hidden">
              <CardAccordionHeader>
                <div className="flex">
                  <FontAwesomeIcon
                    icon={faAddressCard}
                    className="text-gb-gray-900 text-2xl mr-3"
                  />{' '}
                  <span className="font-semibold">
                    {carrierInfo.carrierName} Pick-up Information
                  </span>
                </div>
              </CardAccordionHeader>
              <CardAccordionContent className="py-2">
                <CarrierInfoContent {...carrierInfo} />
              </CardAccordionContent>
            </CardAccordion>
          </>
        )
      )}
    </div>
  )
}

export default CarrierInfo

import { FC } from 'react'
import Modal, { IModalProps } from 'src/components/02-molecules/Modal'
import Button from 'src/components/01-atoms/Button'
import { GbRocketFlying } from 'src/components/01-atoms/Svg/Icons/GbRocketFlying'

export interface IOrderConfirmationProps extends IModalProps {
  /**
   * The text for the modal title.
   */
  title?: string

  /**
   * The text for the modal body.
   */
  messageBody?: string

  /**
   * Function to be called upon primary button click
   */
  primaryButtonFn?: () => void

  /**
   * Text for the first button.
   */
  primaryButtonText?: string | null

  /**
   * Function to be called upon secondary button click
   */
  secondaryButtonFn?: () => void

  /**
   * Text for the second button.
   */
  secondaryButtonText?: string | null
}

const OrderConfirmationModal: FC<IOrderConfirmationProps> = ({
  title = 'Order has been placed!',
  messageBody = 'The purchaser should receive a confirmation email shortly.',
  primaryButtonText = 'Return to Orders Overview',
  secondaryButtonText = 'Place Another Order ',
  primaryButtonFn = () => {},
  secondaryButtonFn = () => {},
  ...modalProps
}) => (
  <Modal {...modalProps} contentClassName="md:center w-10/12 sm:max-w-xl lg:max-w-2xl rounded p-0">
    <div
      data-testid="order-complete-modal"
      className="md:flex md:flex-col md:center text-sm w-full"
    >
      <div className="w-full flex center mt-8">
        <div className="aspect-square w-36 h-36 flex center ">
          <GbRocketFlying className="max-w-full w-36" />
        </div>
      </div>
      <div className="text-center px-12">
        <p className="font-semibold text-2xl mb-2">{title}</p>
        <div className="text-base" data-testid="message-text">
          {messageBody}
        </div>
      </div>
      <Button
        data-testid="order-confirmation-modal-primary-button"
        onClick={primaryButtonFn}
        className="mt-8 mx-auto"
      >
        {primaryButtonText}
      </Button>
      <Button
        data-testid="order-confirmation-modal-secondary-button"
        onClick={secondaryButtonFn}
        className="mt-2 mb-8 mx-auto"
        size="small"
        variant="clear"
      >
        {secondaryButtonText}
      </Button>
    </div>
  </Modal>
)

export default OrderConfirmationModal

mutation setNotificationSettings (
  $input: MerchantNotificationSettingsInput!
  ) {
  merchantNotificationSettings (
    input: $input
  ) {
    mdashAccount {
      id
      smsNotificationEnabled
      shippers {
        id
        receivesRealtimeNotifications
      }
      merchantNotificationContacts {
        id
        phoneNumber
        firstName
        lastName
        role
      }
    }
    errors
  }
}
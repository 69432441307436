query getPurchaserOrderDetails($id: ID!, $mdashAccountId: ID) {
  order(id: $id, mdashAccountId: $mdashAccountId) {
    id
    deliveryFirstName
    deliveryLastName
    deliveryCompany
    deliveryStreet1
    deliveryStreet2
    deliveryCity
    deliveryState
    deliveryPostalCode
    deliveryCountry
    deliveryPhone
    deliveryEmail
    cartItems {
      id
      quantity
      specialInstructions
      product {
        id
        name
      }
      selectedProductOptions {
        productOption {
          id
          name
        }
        variance {
          id
          name
        }
      }
    }
    packages {
      requestedDeliveryOn
      toShipOn
    }
  }
}
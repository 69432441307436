query getReshipmentIssues($mdashAccountId: ID, $startDate: String, $endDate: String) {
  reshipmentIssues(mdashAccountId: $mdashAccountId, startDate: $startDate, endDate: $endDate) {
    adjustmentsInCents
    category
    label
    numberOfIssues
    numberOfPackages
    percentOfPackagesWithIssues
    reshipments {
      adjustmentCostInCents
      comment
      id
      newShipOn
      orderId
      originalShipOn
      packageId
      reshipmentDate
      productNames
    }
  }
}

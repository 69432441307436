import { FC } from 'react'
import { useQuery } from 'urql'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'

import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import Table from 'src/components/02-molecules/Table'
import StatementPageHeader from 'src/components/02-molecules/StatementPageHeader'
import SectionCard from 'src/components/01-atoms/SectionCard'
import USDCellValue from 'src/components/01-atoms/USDCellValue'

import MDashHead from 'src/pages/elements/MDashHead'
import useAppParams from 'src/utils/hooks/useAppParams'
import { gbmcOrderLink } from 'src/utils/gbmcLink'
import { dateForManifest, stringAsDate } from 'src/utils/helpers/date'
import useMakeCSVDownloadButtonProps, {
  CSVData,
} from 'src/utils/hooks/useMakeCSVDownloadButtonProps'

import getInvoiceAdjustmentsReport from 'src/graphql/queries/getInvoiceAdjustmentsReport.graphql'
import {
  IGetInvoiceAdjustmentsReportQuery,
  IGetInvoiceAdjustmentsReportQueryVariables,
} from 'src/graphql/queries/getInvoiceAdjustmentsReport.types'

import '../../components/02-molecules/StatementsTable/styles.css'

const Others: FC = () => {
  const { isAdmin, mdashAccountId } = useAppParams()
  const { statementId } = useParams()

  const columnWidths = [{}, {}, {}, { className: 'lg:w-64' }, {}]

  const tableCellClasses = 'flex gap-x-4 first-of-type:pt-4 pb-4 pl-4 pr-4 lg:p-4 lg:table-cell '
  const [{ fetching, error, data }] = useQuery<
    IGetInvoiceAdjustmentsReportQuery,
    IGetInvoiceAdjustmentsReportQueryVariables
  >({
    query: getInvoiceAdjustmentsReport,
    variables: {
      mdashAccountId: mdashAccountId ?? '',
      invoiceId: statementId ?? '',
    },
    requestPolicy: 'cache-and-network',
  })

  const adjustments = data?.invoiceAdjustmentsReport?.map(({ createdAt, ...rest }) => ({
    createdAt: dateForManifest( stringAsDate( createdAt )),
    ...rest,
  }))

  const columnHeaders = [
    { key: 'packageId', displayLabel: 'Package ID' },
    { key: 'orderId', displayLabel: 'Customer Order' },
    { key: 'createdAt', displayLabel: 'Create Date' },
    { key: 'details', displayLabel: 'Details' },
    { key: 'amount', displayLabel: 'Amount' },
  ]
  const downloadButtonProps = useMakeCSVDownloadButtonProps({
    filename: `statement-${statementId}-other-adjustments`,
    columnHeaders,
    csvData: adjustments as CSVData,
    fetching,
  })

  const tableHeaders = columnHeaders.map(( header ) => ({ name: header.displayLabel }))

  return (
    <div className="flex grow flex-col">
      <MDashHead pageTitle="Statements: Other Adjustments" />

      <Container className="pt-6">
        <StatementPageHeader
          title="Other Adjustments"
          mdashAccountId={mdashAccountId}
          total={adjustments?.length}
          statementId={statementId}
          downloadButtonProps={downloadButtonProps}
        />

        {fetching && <Skeleton className="mt-4.5" height="80vh" />}
        {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}
        {adjustments && (
          <SectionCard className="col-span-12 mb-8">
            <Table
              tableClasses="statements"
              headers={tableHeaders}
              columnWidths={columnWidths}
              borderRule="headers-only"
            >
              {adjustments.map(({ id, packageId, orderId, details, createdAt, amount }, index ) => (
                <tr
                  key={`missed-shipment-${id}`}
                  className="flex flex-col even:bg-gb-gray-50 odd:bg-white lg:table-row"
                >
                  <td className={tableCellClasses}>
                    <div className="font-bold w-1/3 lg:hidden">{tableHeaders[0].name}</div>
                    {packageId}
                  </td>
                  <td className={tableCellClasses}>
                    <div className="font-bold w-1/3 lg:hidden">{tableHeaders[1].name}</div>
                    {orderId}
                    {isAdmin && (
                      <a
                        className="block text-2xs text-gb-blue-600 hover:text-gb-blue-700 transition-colors"
                        href={gbmcOrderLink( orderId ?? '' )}
                        target="_blank"
                        rel="noreferrer"
                      >
                        VIEW IN GBMC
                      </a>
                    )}
                  </td>
                  <td className={tableCellClasses}>
                    <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
                    {createdAt}
                  </td>
                  <td className={tableCellClasses}>
                    <div className="font-bold w-1/3 lg:hidden">{tableHeaders[3].name}</div>
                    {details}
                  </td>
                  <td className={tableCellClasses} data-testid={`total-adjustment-${index}`}>
                    <div className="font-bold w-1/3 lg:hidden">{tableHeaders[4].name}</div>
                    <USDCellValue amount={amount} />
                  </td>
                </tr>
              ))}
            </Table>
          </SectionCard>
        )}
      </Container>
    </div>
  )
}
export default Others

query getManifestCorporateOrders(
  $toShipOn: String!
  $mdashAccountId: ID
  $facilityId: ID
  $facilityType: FacilityType
  $labelPrintStatus: ShippingLabelStatus
  $productIds: [ID!]
  $productCountInPackages: [Int!]
  $recipientCountries: [String!]
  $recipientStates: [String!]
  $shippingSpeeds: [String!]
  $query: String
  $packageIds: [ID!]
) {
  manifestPackages(
    toShipOn: $toShipOn
    mdashAccountId: $mdashAccountId
    facilityId: $facilityId
    facilityType: $facilityType
    labelPrintStatus: $labelPrintStatus
    productIds: $productIds
    productCountInPackages: $productCountInPackages
    recipientCountries: $recipientCountries
    recipientStates: $recipientStates
    shippingSpeeds: $shippingSpeeds
    query: $query
    packageIds: $packageIds
  ) {
    corporateOrders {
      id
      corporateOrderCompanyName
    }
  }
}

mutation removeProductFromTaxonomy(
  $mdashAccountId: ID!
  $productTaxonomyId: ID!
) {
  removeProductFromTaxonomy(
    input: {
      mdashAccountId: $mdashAccountId
      productTaxonomyId: $productTaxonomyId
    }
  ) {
    errors
    productTaxonomy {
      id
      isEnabled
    }
  }
}

mutation updatePantryItemLimit(
  $mdashAccountId: ID!
  $pantryItemId: ID!
  $toShipOn: String!
  $limit: Int!
) {
  updatePantryItemLimit(
    input: {
      mdashAccountId: $mdashAccountId
      pantryItemId: $pantryItemId
      toShipOn: $toShipOn
      limit: $limit
    }
  ) {
    errors
    pantryItemLimits {
      pantryItemId
      pantryItemName
      toShipOn
      currentQuantity
      limit
    }
  }
}

import { FC, useEffect, useRef, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'
import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import Dropdown from 'src/components/01-atoms/Dropdown'
import ContentEmbed from 'src/components/02-molecules/ContentEmbed'
import DatePicker from 'src/components/01-atoms/DatePicker'
import getMetabaseReport from 'src/graphql/queries/getMetabaseReport.graphql'
import {
  IMetabaseReportQuery,
  IMetabaseReportQueryVariables,
} from 'src/graphql/queries/getMetabaseReport.types'
import getMerchant from 'src/graphql/queries/getMerchants.graphql'
import {
  IGetMerchantsQuery,
  IGetMerchantsQueryVariables,
} from 'src/graphql/queries/getMerchants.types'
import { dateForManifest, stringAsDate } from 'src/utils/helpers/date'
import useAppParams from 'src/utils/hooks/useAppParams'
import { useQuery } from 'urql'
import MDashHead from './elements/MDashHead'

const ReportPage: FC = () => {
  const params = useParams()
  const reportId = useRef( params.reportId )

  const [ startDate, setStartDate ] = useState<Date | null>( new Date())
  const [ endDate, setEndDate ] = useState<Date | null>( new Date())

  const [ isDefaultDates, setIsDefaultDates ] = useState<boolean>( false )
  const [ showDatePicker, setShowDatePicker ] = useState<boolean>( false )
  const [ showMerchantSelector, setShowMerchantSelector ] = useState<boolean>( false )
  const [ selectedMerchant, setSelectedMerchant ] = useState<IGetMerchantsQuery['merchants'][0]>()

  const onStartDateChanged = ( date: Date ) => {
    if ( !date ) return
    setStartDate( date )
    if ( !endDate || date > endDate ) setEndDate( null )
    setIsDefaultDates( false )
  }

  const onEndDateChanged = ( date: Date ) => {
    if ( !date ) return
    setEndDate( date )
    if ( !startDate || date < startDate ) setStartDate( null )
    setIsDefaultDates( false )
  }

  const { mdashAccountId, defaultMerchantId } = useAppParams()

  const [{ fetching, error, data }] = useQuery<IMetabaseReportQuery, IMetabaseReportQueryVariables>(
    {
      query: getMetabaseReport,
      variables: {
        mdashAccountId: mdashAccountId ?? '',
        metabaseEnv: import.meta.env.NODE_ENV,
        id: reportId.current ?? '',
        merchantId: selectedMerchant?.id || defaultMerchantId,
        startDate: startDate ? dateForManifest( startDate ) : null,
        endDate: endDate ? dateForManifest( endDate ) : null,
      },
      requestPolicy: 'cache-and-network',
      pause:
        ( showDatePicker && ( !startDate || !endDate )) ||
        !reportId.current ||
        ( isDefaultDates && !selectedMerchant?.id! ),
    }
  )

  const [{ data: merchantData }] = useQuery<IGetMerchantsQuery, IGetMerchantsQueryVariables>({
    query: getMerchant,
    variables: {
      mdashAccountId: mdashAccountId ?? '',
    },
    requestPolicy: 'cache-and-network',
    pause: !defaultMerchantId || !mdashAccountId || !showMerchantSelector,
  })

  useEffect(() => {
    // if the report id has changed, reset the values to the initial state
    if ( reportId.current !== params.reportId ) {
      reportId.current = params.reportId
      setStartDate( null )
      setEndDate( null )
      setShowDatePicker( false )
      setIsDefaultDates( false )
      setShowMerchantSelector( false )
      setSelectedMerchant( undefined )
    }
  }, [ params.reportId ])

  useEffect(() => {
    const report = data?.metabaseReport
    if ( !report ) return

    const show = report.enableDateFilters!
    setShowDatePicker( show )

    setShowMerchantSelector( report.enableMerchantsFilter! )

    if ( !show || startDate! || endDate! || ( !report.startDate! && !report.endDate! )) return

    setStartDate( stringAsDate( report.startDate ))
    setEndDate( stringAsDate( report.endDate ))
    setIsDefaultDates( true )
  }, [ data ])

  const url = data?.metabaseReport?.url

  return (
    <div className="flex grow flex-col">
      <MDashHead pageTitle={data?.metabaseReport?.name ?? ''} />

      <div className="grow-0 py-2  border-b border-b-gb-gray-400 lg:py-6">
        <Container>
          <h1 className="text-2xl font-semibold mb-2">{data?.metabaseReport?.name}</h1>
          <div className="flex flex-col-reverse md:flex-row items-start md:justify-between">
            {showMerchantSelector &&
              merchantData?.merchants &&
              merchantData?.merchants.length > 1 && (
                <div className="mt-4 md:mt-0 md:mb-4 relative" data-test-id="merchant-select">
                  <div className="mb-1 text-sm pl-1">Merchant</div>
                  <Dropdown
                    size="small"
                    triggerButtonClasses="min-w-32 rounded-r-none z-[500]"
                    selected={
                      selectedMerchant?.effectiveShortName ||
                      merchantData.merchants.filter(( x ) => x.id === defaultMerchantId )[0]
                        .effectiveShortName
                    }
                    handleChange={( merchantName: string ) => {
                      setSelectedMerchant(
                        merchantData.merchants.find(( x ) => x.effectiveShortName === merchantName )
                      )
                    }}
                    options={merchantData.merchants.map(( x ) => x.effectiveShortName )}
                  />
                </div>
              )}
            {showDatePicker && (
              <div className="md:w-72 grid grid-cols-2 gap-4" data-test-id="reports-datepicker">
                <div>
                  <div className="mb-1 text-sm pl-1">Start date</div>
                  <DatePicker
                    name="reports-start-date"
                    selected={startDate}
                    startDate={startDate}
                    minDate={new Date( '2010-01-01 00:00:00 GMT+0000' )}
                    onChange={onStartDateChanged}
                  />
                </div>
                <div>
                  <div className="mb-1 text-sm pl-1">End date</div>
                  <DatePicker
                    name="reports-end-date"
                    selected={endDate}
                    startDate={endDate}
                    minDate={startDate || new Date()}
                    onChange={onEndDateChanged}
                  />
                </div>
              </div>
            )}
          </div>
        </Container>
      </div>

      <div className="bg-white flex flex-col grow">
        <Container className="flex flex-col grow">
          {fetching && <Skeleton className="mt-4.5" height="80vh" />}
          {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}
          {url && (
            <ContentEmbed
              src={url}
              title="Metabase Chart"
              className="min-h-[570px] md:min-h-[1550px]"
            />
          )}
        </Container>
      </div>
    </div>
  )
}
export default ReportPage

import { Configuration, Payload } from 'rollbar'
import packageConfig from './package.config'

export default {
  accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  hostSafeList: [ 'goldbelly.com' ],
  payload: {
    environment: import.meta.env.VITE_ENVIRONMENT,
    client: {
      javascript: {
        code_version: packageConfig.appVersion,
        source_map_enabled: true,
      },
    },
  } as Payload,
} as Configuration

import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation } from 'urql'
import { useNavigate, useSearchParams } from 'react-router-dom'

import useAppParams from 'src/utils/hooks/useAppParams'
import usePrintingParams from 'src/utils/hooks/usePrintingParams'

import MDashHead from 'src/pages/elements/MDashHead'
import Container from 'src/components/01-atoms/Container'
import Stepper from 'src/components/01-atoms/Stepper'
import USLetter from 'src/components/03-organisms/FulfillmentSheet/USLetter'
import PrintConfirmModal from 'src/components/03-organisms/PrintConfirmModal'
import PrintHeader from 'src/components/03-organisms/PrintHeader'

import { generate } from 'src/utils/stepperInput'
import PostPrintModal from 'src/components/03-organisms/PostPrintModal'
import { useCounter } from 'rooks'
import { stringAsDate } from 'src/utils/helpers/date'
import SuccessBlock from 'src/components/01-atoms/SuccessBlock'
import PrintPreviewWrapper from 'src/components/02-molecules/PrintPreviewWrapper'
import batchFulfillmentMutation from 'src/graphql/mutations/batchFulfillment.graphql'
import {
  IBatchFulfillmentMutation,
  IBatchFulfillmentMutationVariables,
} from 'src/graphql/mutations/batchFulfillment.types'
import markEntityAsPrintedMutation from 'src/graphql/mutations/markEntityAsPrinted.graphql'
import {
  IMarkEntityAsPrintedMutation,
  IMarkEntityAsPrintedMutationVariables,
} from 'src/graphql/mutations/markEntityAsPrinted.types'
import { pick } from 'lodash'
import Alert from 'src/components/01-atoms/Alert'

const itemsPerBatch = 100

const FulfillmentSheet: FC = () => {
  const [ searchParams ] = useSearchParams()
  const navigate = useNavigate()

  const {
    mdashAccountId,
    toShipOn,
    toShipOnAsString,
    displaySku,
    facilityId,
    facilityType,
    makeLinkUrls,
    packageSort,
    withFacilityParams,
  } = useAppParams()
  const {
    packageIds,
    labelPrintStatus,
    showPrintConfirmModal,
    setShowPrintConfirmModal,
    printingIsComplete,
    setPrintingIsComplete,
    showPostPrintModal,
    setShowPostPrintModal,
    batchPrintActionId,
    setBatchPrintActionId,
  } = usePrintingParams({
    defaultLabelPrintStatusFilter: 'not_printed',
  })

  const [ totalPackages, setTotalPackages ] = useState( 0 )
  const { value: page, increment: nextPage } = useCounter( 0 )
  const isInitialized = useRef( false ) // hack to make sure initial mutation only run once
  const totalPages = Math.ceil( totalPackages / itemsPerBatch )
  const lastPage = Math.max( 0, totalPages - 1 )

  const currentOrMaxPage = Math.min( lastPage, page )

  const queryVariables = {
    mdashAccountId: String( mdashAccountId ),
    toShipOn: toShipOnAsString,
    packageIds,
    labelPrintStatus,
    limit: itemsPerBatch,
    page: currentOrMaxPage,
    batchPrintActionId,
    facilityId,
    facilityType,
    ...packageSort,
  }
  const [ result, markAsPrinted ] = useMutation<
    IBatchFulfillmentMutation,
    IBatchFulfillmentMutationVariables
  >( batchFulfillmentMutation )
  const [ , markEntityAsPrinted ] = useMutation<
    IMarkEntityAsPrintedMutation,
    IMarkEntityAsPrintedMutationVariables
  >( markEntityAsPrintedMutation )

  const subproductsInPackages = useMemo(
    () => result.data?.batchPrint?.manifestPackages.subproductsInPackages,
    [ result ]
  )
  const collection = result.data?.batchPrint?.manifestPackages.packages?.collection || []
  const nothingToPrint = result.data && !result.data?.batchPrint?.manifestPackages.packages

  const stepperParams = generate({
    entityCount: totalPackages,
    entityPerStep: itemsPerBatch,
    maxStepToDisplay: 5,
    currentStep: page || 0,
  })
  const isBatch = totalPages > 1
  const isLastPage = isBatch ? page >= lastPage : true
  const afterPrintAction = useCallback(() => {
    markEntityAsPrinted({
      ...pick( queryVariables, [ 'mdashAccountId', 'toShipOn', 'batchPrintActionId' ]),
      printedPackingSlipForSuborderIds: collection.map(( x ) => x.id ),
    })
    setShowPrintConfirmModal( true )
  }, [ queryVariables, collection ])

  useEffect(() => {
    if ( result.fetching || result.error || !result.data ) return

    setTotalPackages( Number( result.data.batchPrint?.batchPrintAction.length ))
    setBatchPrintActionId( result.data.batchPrint?.batchPrintAction.id )
  }, [ result ])

  useEffect(() => {
    if ( !isInitialized.current ) {
      const bpaFromUrl = Number( searchParams.get( 'batch_print_action_id' ))
      const bpaId = bpaFromUrl > 0 ? String( bpaFromUrl ) : undefined

      markAsPrinted({
        ...queryVariables,
        batchPrintActionId: bpaId,
      })
    }

    isInitialized.current = true
  }, [ markAsPrinted ])

  useEffect(() => {
    if ( !batchPrintActionId || searchParams.get( 'batch_print_action_id' )) return
    searchParams.append( 'batch_print_action_id', batchPrintActionId )
  }, [ batchPrintActionId ])

  useEffect(() => {
    window.addEventListener( 'afterprint', afterPrintAction )
    return () => window.removeEventListener( 'afterprint', afterPrintAction )
  }, [ afterPrintAction ])

  return (
    <div>
      <MDashHead pageTitle="Print Fulfillment Sheets" />
      <PrintHeader
        backLink={{
          pathname: makeLinkUrls().manifest,
          search: withFacilityParams(),
        }}
        postPrintAction={afterPrintAction}
        pageTitle="Print Fulfillment Sheets"
        shipDate={toShipOn}
        totalOrders={totalPackages}
        printingIsComplete={printingIsComplete}
        fetching={nothingToPrint || result.fetching}
        handleOptionalPrintingActions={() => setShowPostPrintModal( true )}
      />
      <Container className="screen:p-8">
        {isBatch && (
          <div className="mb-8 print:hidden">
            <Stepper {...stepperParams} />
          </div>
        )}
        {printingIsComplete ? (
          <SuccessBlock />
        ) : (
          <div>
            <PrintPreviewWrapper loading={result.fetching && !result.data}>
              {collection.length > 0 &&
                collection.map(( pkg ) => {
                  const products = pkg.lineItems.map(
                    ({ id, quantityOrWeight: quantity, product, specialInstructions }) => ({
                      id,
                      quantity,
                      sku: product?.sku,
                      name: product?.name!,
                      specialInstructions,
                    })
                  )

                  const ungroupedSubproducts =
                    subproductsInPackages?.find(( x ) => x.id === pkg.id )?.subproducts || []

                  return (
                    <USLetter
                      key={pkg.id}
                      packageId={pkg.id}
                      orderId={pkg.orderId}
                      toShipOn={stringAsDate( pkg.toShipOn )}
                      tags={{
                        isCorporateOrder: pkg.isCorporateOrder,
                        isInStoreOrder: pkg.isInStoreOrder,
                        isReshipment: pkg.isReshipment,
                        isSubscription: pkg.isSubscription,
                        isWhitelabel: pkg.isWhitelabel,
                        corporateOrderCompanyName: pkg.corporateOrderCompanyName,
                      }}
                      recipient={{
                        name: [ pkg.deliveryLastName, pkg.deliveryFirstName ].join( ', ' ),
                        city: [ pkg.deliveryCity, pkg.deliveryState ].join( ', ' ),
                        carrier: pkg.currentShippingLabel?.carrier || '',
                        service: pkg.currentShippingLabel?.serviceName || '',
                      }}
                      displaySku={displaySku}
                      products={products}
                      subproducts={ungroupedSubproducts.filter(( x ) => !x.optional )}
                      addons={ungroupedSubproducts.filter(( x ) => x.optional )}
                    />
                  )
                })}
            </PrintPreviewWrapper>
            {!result.fetching && collection.length === 0 && (
              <Alert type="error">
                Oops, fulfillment sheet is not found, please go back to Orders to the see all
                available.
              </Alert>
            )}
          </div>
        )}
      </Container>
      <PrintConfirmModal
        open={showPrintConfirmModal}
        entity={{
          fullName: 'fulfillment sheet',
          shortName: 'sheet',
        }}
        backButtonCallback={() => {
          setShowPrintConfirmModal( false )
          markEntityAsPrinted({
            ...pick( queryVariables, [ 'mdashAccountId', 'toShipOn', 'batchPrintActionId' ]),
            printedPackingSlipForSuborderIds: collection.map(( x ) => x.id ),
            undoMutation: true,
          })
        }}
        continueButtonCallback={() => {
          markAsPrinted({
            ...queryVariables,
            page: page + 1,
            printedPackingSlipForSuborderIds: collection.map(( x ) => x.id ),
          })

          nextPage()
          setShowPrintConfirmModal( false )
          if ( isLastPage ) {
            setPrintingIsComplete( true )
            setShowPostPrintModal( true )
          }
        }}
      />
      <PostPrintModal
        open={showPostPrintModal}
        slipNLabels={{
          isCompleted: result.data?.batchPrint?.batchPrintAction.shippingLabelsPartiallyPrinted!,
          onClick: () => navigate( `${makeLinkUrls().printLabels}?${searchParams}` ),
        }}
        fulfillmentSheets={{
          isCompleted: result.data?.batchPrint?.batchPrintAction.fulfillmentSheetsPartiallyPrinted!,
          onClick: () => navigate( `${makeLinkUrls().printFulfillmentSheets}?${searchParams}` ),
        }}
        pickLists={{
          isCompleted: result.data?.batchPrint?.batchPrintAction.pickListsPartiallyPrinted!,
          onClick: () => navigate( `${makeLinkUrls().printPickList}?${searchParams}` ),
        }}
        handleClose={() => setShowPostPrintModal( false )}
      />
    </div>
  )
}

export default FulfillmentSheet

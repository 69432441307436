import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import { PackageInstructionIcons } from '../Svg/Icons/PackageInstructionIcons'

interface IPackageInstructionsProps {
  /**
   * The heading text that appears next to the icons.
   */
  headingText: string

  /**
   * Extra classes to add to this element's container, which also contains the border and padding.
   */
  className?: string

  /**
   * The size of the package instructions
   */
  size?: 'small' | 'default'

  /**
   * Whether or not to show the icons.
   */
  showIcons?: boolean
}

const PackageInstructions: FC<PropsWithChildren<IPackageInstructionsProps>> = ({
  headingText,
  className,
  size = 'default',
  showIcons = true,
  children,
}) => (
  <div
    className={classNames( className, 'border-black border-solid flex', {
      'border-4 p-4': size === 'default',
      'border-2 p-2 pt-3': size === 'small',
    })}
  >
    <PackageInstructionIcons className="absolute w-0 h-0 overflow-hidden" />
    {showIcons && (
      <div className="flex mr-2 gap-x-1">
        <svg
          role="presentation"
          name="icons_svg__cooking"
          className={classNames( 'text-black', {
            'w-5 h-5': size === 'default',
            'w-3 h-3': size === 'small',
          })}
        >
          <use xlinkHref="#cooking" xmlnsXlink="http://www.w3.org/1999/xlink" />
        </svg>
        <svg
          role="presentation"
          name="icons_svg__fridge"
          className={classNames( 'text-black', {
            'w-5 h-5': size === 'default',
            'w-3 h-3': size === 'small',
          })}
        >
          <use xlinkHref="#fridge" xmlnsXlink="http://www.w3.org/1999/xlink" />
        </svg>
      </div>
    )}
    <div className="flex-grow -mt-1">
      <div
        className={classNames( 'font-bold uppercase leading-tight', {
          'text-base': size === 'default',
          'text-xs': size === 'small',
        })}
      >
        {headingText}
      </div>
      <div
        className={classNames( 'break-word', {
          'text-xs': size === 'default',
          'text-2xs': size === 'small',
        })}
      >
        {children}
      </div>
    </div>
  </div>
)

export default PackageInstructions

import JsSHA from 'jssha'

interface ISigmaUrlGeneratorProps {
  mdashAccountId: string | number
}

export const sigmaUrlGenerator = ({ mdashAccountId }: ISigmaUrlGeneratorProps ) => {
  const nonce = window.crypto.randomUUID()
  const searchParams = [ `?:nonce=${nonce}` ]
  const embedPath = 'https://app.sigmacomputing.com/embed/1-5uAMdwE5lOvkaSjhPaMiAK'
  const embedSecret = import.meta.env.VITE_SIGMA_EMBED_SECRET ?? ''

  if ( !embedSecret ) return false

  searchParams.push( '&:email=embed_viewer1@sigmacomputing.com' )
  searchParams.push( '&:external_user_id=embed_viewer1@sigmacomputing.com' )
  searchParams.push( `&:ua_mdash_id=${mdashAccountId}` )
  searchParams.push( '&:external_user_team=all_clients' )
  searchParams.push( '&:account_type=Viewer' )
  searchParams.push( '&:mode=userbacked' )
  searchParams.push( '&:session_length=600' )
  searchParams.push( `&:time=${Math.floor( new Date().getTime() / 1000 )}` )
  const urlWithParams = embedPath + searchParams.join( '' )

  const signature = new JsSHA( 'SHA-256', 'TEXT', {
    encoding: 'UTF8',
    hmacKey: { value: embedSecret, format: 'TEXT' },
  })
    .update( urlWithParams )
    .getHash( 'HEX' )
  return `${urlWithParams}&:signature=${signature}`
}

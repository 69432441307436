mutation updateShippingInfo(
  $orderId: ID!
  $deliveryFirstName: String!
  $deliveryLastName: String!
  $deliveryCompany: String
  $deliveryStreet1: String!
  $deliveryStreet2: String
  $deliveryCity: String!
  $deliveryState: String!
  $deliveryPhone: String
  $deliveryPostalCode: String!
  $recipientEmails: [String!]
) {
  updateShippingAddress(
    input: {
      orderId: $orderId
      deliveryFirstName: $deliveryFirstName
      deliveryLastName: $deliveryLastName
      deliveryCompany: $deliveryCompany
      deliveryStreet1: $deliveryStreet1
      deliveryStreet2: $deliveryStreet2
      deliveryCity: $deliveryCity
      deliveryState: $deliveryState
      deliveryPhone: $deliveryPhone
      deliveryPostalCode: $deliveryPostalCode
      recipientEmails: $recipientEmails
    }
  ) {
    order {
      __typename
      id
      isValidShippingAddress
      deliveryCity
      deliveryCompany
      deliveryFirstName
      deliveryLastName
      deliveryPhone
      deliveryPostalCode
      deliveryState
      deliveryStreet1
      deliveryStreet2
      recipientEmails
    }
    errors
  }
}

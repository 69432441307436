import { FC, useEffect, useRef } from 'react'
import { useMutation } from 'urql'

import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import useAppParams from 'src/utils/hooks/useAppParams'
import PrintHeader from 'src/components/03-organisms/PrintHeader'
import PrintPreviewWrapper from 'src/components/02-molecules/PrintPreviewWrapper'
import USLetter from 'src/components/03-organisms/PickList/USLetter'
import usePrintingParams from 'src/utils/hooks/usePrintingParams'

import MDashHead from 'src/pages/elements/MDashHead'
import batchPickListMutation from 'src/graphql/mutations/batchPickList.graphql'
import {
  IBatchPickListMutation,
  IBatchPickListMutationVariables,
} from 'src/graphql/mutations/batchPickList.types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PrintConfirmModal from 'src/components/03-organisms/PrintConfirmModal'
import PostPrintModal from 'src/components/03-organisms/PostPrintModal'

const PickList: FC = () => {
  const isInitialized = useRef( false ) // hack to make sure initial mutation only run once
  const navigate = useNavigate()
  const [ searchParams ] = useSearchParams()
  const {
    mdashAccountId,
    toShipOn,
    toShipOnAsString,
    displaySku,
    facilityId,
    facilityType,
    makeLinkUrls,
    withFacilityParams,
  } = useAppParams()
  const {
    packageIds,
    batchPrintActionId,
    setBatchPrintActionId,
    printingIsComplete,
    setPrintingIsComplete,
    showPrintConfirmModal,
    setShowPrintConfirmModal,
    showPostPrintModal,
    setShowPostPrintModal,
  } = usePrintingParams({
    defaultLabelPrintStatusFilter: 'not_printed',
  })

  const [ result, markAsPrinted ] = useMutation<
    IBatchPickListMutation,
    IBatchPickListMutationVariables
  >( batchPickListMutation )

  const summary = result.data?.batchPrint?.subproductSummary
  const products = summary?.products ?? null
  const subproducts = summary?.subproducts ?? null

  useEffect(() => {
    if ( result.fetching || result.error || !result.data ) return

    setBatchPrintActionId( result.data.batchPrint?.batchPrintAction.id )
  })

  useEffect(() => {
    if ( !isInitialized.current ) {
      const bpaFromUrl = Number( searchParams.get( 'batch_print_action_id' ))
      const bpaId = bpaFromUrl > 0 ? String( bpaFromUrl ) : undefined

      markAsPrinted({
        mdashAccountId: String( mdashAccountId ),
        toShipOn: toShipOnAsString,
        packageIds,
        facilityId,
        facilityType,
        batchPrintActionId: bpaId,
      })
    }

    isInitialized.current = true
  }, [ markAsPrinted ])

  useEffect(() => {
    if ( batchPrintActionId ) {
      searchParams.append( 'batch_print_action_id', batchPrintActionId )
    }
  }, [ batchPrintActionId ])

  return (
    <div>
      <MDashHead pageTitle="Print Pick List" />
      <PrintHeader
        backLink={{
          pathname: makeLinkUrls().manifest,
          search: withFacilityParams(),
        }}
        postPrintAction={() => {
          setShowPrintConfirmModal( true )
        }}
        pageTitle="Print Pick List"
        shipDate={toShipOn}
        fetching={result.fetching}
        printingIsComplete={printingIsComplete}
        handleOptionalPrintingActions={() => setShowPostPrintModal( true )}
      />

      <Container className="screen:p-8">
        {!result.fetching && ( !result.data || result.error ) && (
          <Alert type="error">{result.error?.message}</Alert>
        )}
        <PrintPreviewWrapper loading={result.fetching && !products && !subproducts}>
          {products && products.length && subproducts && (
            <USLetter
              products={products}
              subproducts={subproducts}
              totalPackages={summary?.totalPackages}
              selectedPackages={summary?.selectedPackages}
              displaySku={displaySku}
              printedAt={new Date()}
              toShipOn={toShipOn}
            />
          )}
        </PrintPreviewWrapper>
        {!result.fetching && !( products || subproducts ) && (
          <Alert type="error">
            Ooops, Pick List is not found, please go back to Orders to the see all available.
          </Alert>
        )}
      </Container>
      <PrintConfirmModal
        open={showPrintConfirmModal}
        entity={{
          fullName: 'pick list',
          shortName: 'pick list',
        }}
        backButtonCallback={() => setShowPrintConfirmModal( false )}
        continueButtonCallback={() => {
          markAsPrinted({
            mdashAccountId: String( mdashAccountId ),
            toShipOn: toShipOnAsString,
            packageIds,
            batchPrintActionId,
            printedPickList: true,
          })

          setShowPostPrintModal( true )
          setShowPrintConfirmModal( false )
          setPrintingIsComplete( true )
        }}
      />
      <PostPrintModal
        open={showPostPrintModal}
        slipNLabels={{
          isCompleted: result.data?.batchPrint?.batchPrintAction.shippingLabelsPartiallyPrinted!,
          onClick: () => navigate( `${makeLinkUrls().printLabels}?${searchParams}` ),
        }}
        fulfillmentSheets={{
          isCompleted: result.data?.batchPrint?.batchPrintAction.fulfillmentSheetsPartiallyPrinted!,
          onClick: () => navigate( `${makeLinkUrls().printFulfillmentSheets}?${searchParams}` ),
        }}
        pickLists={{
          isCompleted: result.data?.batchPrint?.batchPrintAction.pickListsPartiallyPrinted!,
          onClick: () => navigate( `${makeLinkUrls().printPickList}?${searchParams}` ),
        }}
        handleClose={() => setShowPostPrintModal( false )}
      />
    </div>
  )
}

export default PickList

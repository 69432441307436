import { FC } from 'react'
import { ISvgIconProps } from '../types'

export const WhatsNewActions: FC<ISvgIconProps> = ( props ) => (
  <svg
    width="71"
    height="60"
    viewBox="0 0 71 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5429_89563)">
      <path
        d="M1.81792 59.9989C1.60426 59.9989 1.38606 59.9625 1.17695 59.8807C0.235968 59.5261 -0.236798 58.476 0.117776 57.5396L19.0239 7.44453C19.3785 6.50355 20.4285 6.03078 21.365 6.38535C22.306 6.73993 22.7787 7.79002 22.4242 8.72646L3.51806 58.826C3.24531 59.5534 2.5498 60.0034 1.81792 60.0034V59.9989Z"
        fill="#64748B"
      />
      <path
        d="M28.209 12.0898H19.2537L8.05957 40.299L13.4327 39.4035L18.3581 42.5378L23.7313 48.3587L31.7911 53.7319H44.3285L55.0748 48.3587L60.0002 41.1945H56.8659L49.7016 40.299L48.8061 37.1646L51.9405 34.9258L58.2092 29.1049L60.0002 21.0451L50.5972 24.1795L41.1941 23.2839L34.0299 18.3585L28.209 12.0898Z"
        fill="#F59E0B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.2729 21.2755L26.8232 50.4199L31.7909 53.7317H44.3283L55.0746 48.3585L60 41.1943H56.8657L49.7015 40.2988L48.8059 37.1644L51.9403 34.9256L58.209 29.1047L60 21.0449L50.597 24.1793L41.1939 23.2837L38.2729 21.2755Z"
        fill="#D97706"
      />
      <path
        d="M38.4622 56.3646C33.3254 56.3646 28.5886 54.9008 25.3565 52.1415C23.9746 50.9596 22.6427 49.4367 21.3562 47.9639C16.8649 42.8271 13.8738 39.8405 9.10519 43.0908L4.3457 46.3319L17.8105 10.6563L18.7423 10.429C28.9659 7.91966 32.2935 12.3246 35.2255 16.2067C36.6893 18.1478 38.0758 19.9798 40.326 21.1753C43.2216 22.7118 52.8043 23.0709 59.1366 19.0297L61.8777 17.2795L61.9323 20.5343C62.0459 27.3076 57.841 33.9491 50.8768 38.0949C50.9541 38.3495 51.0814 38.4722 51.1541 38.5404C51.5496 38.9222 53.3361 40.086 59.8457 39.1041L62.746 38.6677L61.8459 41.4588C59.7321 48.0275 54.0544 53.0598 46.2628 55.269C43.6535 56.01 41.0078 56.3691 38.4576 56.3691L38.4622 56.3646ZM12.8146 38.0949C17.5604 38.0949 21.2153 42.277 24.0928 45.5773C25.3474 47.0138 26.5339 48.373 27.7158 49.3822C31.5843 52.6915 38.6394 53.6507 45.2673 51.7687C50.7132 50.2232 54.9181 47.1229 57.1955 43.0589C53.0497 43.3453 50.2313 42.7134 48.6175 41.1497C47.8357 40.3905 46.9583 39.0268 47.2174 36.8357L47.3311 35.9038L48.1584 35.4538C52.3497 33.1808 56.7546 29.0487 57.9683 23.7073C51.3223 26.4712 42.617 26.5167 38.6167 24.3892C35.6574 22.8163 33.8845 20.4707 32.3207 18.4023C29.6387 14.8521 27.6704 12.2427 20.5289 13.752L11.2872 38.2358C11.81 38.1403 12.3145 38.0949 12.8146 38.0949Z"
        fill="#B45309"
      />
      <path
        d="M36.8077 37.5879L37.1623 46.9296C37.1895 47.2796 36.7531 47.4615 36.5258 47.1887L31.58 37.9107L22.3792 38.5517C22.0474 38.5426 21.9064 38.1244 22.161 37.9107L29.0343 32.2148L26.8341 26.0234C26.7159 25.6915 27.0978 25.4051 27.3842 25.6097L32.5619 29.2918L35.7485 26.0688C35.9758 25.8415 36.3667 26.0006 36.3667 26.3234V31.9966L41.7626 35.8333C42.0172 36.0151 41.9399 36.4106 41.6353 36.4788L36.8122 37.5834L36.8077 37.5879Z"
        fill="#FEF3C7"
      />
      <circle cx="21.94" cy="4.02988" r="4.02988" fill="#CBD5E1" />
    </g>
    <defs>
      <clipPath id="clip0_5429_89563">
        <rect width="70.066" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

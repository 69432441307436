query getProductTaxonomies($mdashAccountId: ID) {
  mdashAccount(id: $mdashAccountId) {
    id
    products {
      id
      name
    }
    taxonomies {
      id
      name
    }
    productTaxonomies {
      id
      productId
      taxonomyId
      isEnabled
    }
  }
}

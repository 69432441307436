query getDailyPrintActivity(
  $toShipOn: String
) {
  dailyPrintActivity(toShipOn: $toShipOn) {
    name
    children {
      id
      name
      value
    }
  }
}

query getInvoiceReshipmentsReport(
    $mdashAccountId: ID!,
    $invoiceId: ID!) {
  invoiceReshipmentsReport(mdashAccountId: $mdashAccountId, invoiceId: $invoiceId) {
    comment
    id
    lineItems {
      name
      quantityOrWeight
      customerChoices {
        name
      }
    }
    newShipOn
    originalShipOn
    productAdjustment
    orderId
    reason
    reasonId
    packageId
    shippingAdjustment
    totalAdjustment
  }
}

import { isEmpty } from 'lodash'
import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import MDashHead from 'src/pages/elements/MDashHead'
import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import Grid from 'src/components/01-atoms/Grid'
import { setNotificationSettings } from 'src/graphql/mutations/setNotificationSettings.graphql'
import {
  ISetNotificationSettingsMutation,
  ISetNotificationSettingsMutationVariables,
} from 'src/graphql/mutations/setNotificationSettings.types'
import getMdashAccount from 'src/graphql/queries/getMdashAccount.graphql'
import {
  IGetMdashAccountQuery,
  IGetMdashAccountQueryVariables,
} from 'src/graphql/queries/getMdashAccount.types'
import { useMutation, useQuery } from 'urql'
import useAppParams from 'src/utils/hooks/useAppParams'
import NotificationsSettingsCard, {
  INotificationSettingsOutput,
} from './elements/NotificationSettingsCard'

const Settings: FC = () => {
  const { mdashAccountId } = useAppParams()

  const [{ error: accountError, fetching: queryFetching, data: account }] = useQuery<
    IGetMdashAccountQuery,
    IGetMdashAccountQueryVariables
  >({
    query: getMdashAccount,
    variables: {
      id: mdashAccountId,
    },
    requestPolicy: 'network-only',
    pause: !mdashAccountId,
  })

  const [
    { error: mutationErrors, fetching: mutationFetching, data: mutationData },
    executeMutation,
  ] = useMutation<ISetNotificationSettingsMutation, ISetNotificationSettingsMutationVariables>(
    setNotificationSettings
  )

  const submitHandler = ( input: INotificationSettingsOutput ) => {
    executeMutation({ input })
  }

  return (
    <>
      <MDashHead pageTitle="Settings" />
      <Container className="pt-6 pb-9">
        <h1 className="text-2xl font-semibold mb-6">Account Settings</h1>
        <Grid>
          {accountError && (
            <div className="col-span-12">
              <Alert type="error">{accountError.message}</Alert>
            </div>
          )}
          {( mutationErrors || !isEmpty( mutationData?.merchantNotificationSettings?.errors )) && (
            <div className="col-span-12">
              <Alert type="error">
                {mutationErrors
                  ? mutationErrors.message
                  : mutationData?.merchantNotificationSettings?.errors}
              </Alert>
            </div>
          )}
          {account?.mdashAccount ? (
            <NotificationsSettingsCard
              onSubmit={submitHandler}
              disabled={mutationFetching || queryFetching}
              data={account?.mdashAccount}
              mutationData={mutationData}
            />
          ) : (
            <div className="col-span-12 md:col-span-8">
              <Skeleton height="350px" width="100%" />
            </div>
          )}
        </Grid>
      </Container>
    </>
  )
}

export default Settings

import { FC, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from 'src/components/01-atoms/Button'
import TextInput from 'src/components/01-atoms/TextInput'
import updateTaxonomy from 'src/graphql/mutations/updateTaxonomy.graphql'
import {
  IUpdateTaxonomyMutation,
  IUpdateTaxonomyMutationVariables,
} from 'src/graphql/mutations/updateTaxonomy.types'
import useAppParams from 'src/utils/hooks/useAppParams'
import { useMutation } from 'urql'

interface IProps {
  taxonomyId?: string
  taxonomyName?: string
  onCancel: () => void
}

const EditTaxonomy: FC<IProps> = ({ taxonomyId, taxonomyName, onCancel }) => {
  const { mdashAccountId } = useAppParams()
  const [ successMessage, setSuccessMessage ] = useState<string | null>( null )
  const [ errorMessage, setErrorMessage ] = useState<string | null>( null )
  const {
    register,
    reset,
    setFocus,
    watch,
    formState: { isDirty, isValid },
  } = useForm({ defaultValues: { name: taxonomyName }})
  const { name } = watch()

  const [ updateResult, update ] = useMutation<
    IUpdateTaxonomyMutation,
    IUpdateTaxonomyMutationVariables
  >( updateTaxonomy )

  const handleUpdate = useCallback(() => {
    setSuccessMessage( null )
    setErrorMessage( null )

    update({
      mdashAccountId: String( mdashAccountId ),
      taxonomyId: String( taxonomyId ),
      name,
    }).then(( res ) => {
      if ( res.data?.updateTaxonomy?.errors.length === 0 ) {
        setSuccessMessage( `Successfully updated taxonomy ${name}` )
      } else {
        setErrorMessage(
          `Failed to update taxonomy ${name}: ${res.data?.updateTaxonomy?.errors.join( ', ' )}`
        )
      }

      setFocus( 'name' )
    })
  }, [ name ])

  useEffect(() => {
    setSuccessMessage( null )
    setErrorMessage( null )
    setFocus( 'name' )
  }, [ taxonomyId ])

  useEffect(() => {
    reset({ name: taxonomyName })
  }, [ taxonomyName ])

  if ( !taxonomyId || !taxonomyName ) return null

  return (
    <div className="border-t">
      <div className="flex justify-center items-center py-4 gap-4">
        <div>Edit Tag {taxonomyName}</div>
        <TextInput {...register( 'name', { required: true })} />
        <Button disabled={!isDirty || !isValid || updateResult.fetching} onClick={handleUpdate}>
          {updateResult.fetching ? 'Updating...' : 'Update Tag'}
        </Button>
        <Button onClick={onCancel} variant="clear">
          Close
        </Button>
      </div>
      {successMessage && <div className="text-center text-gb-blue-600">{successMessage}</div>}
      {errorMessage && <div className="text-center text-red-600">{errorMessage}</div>}
    </div>
  )
}

export default EditTaxonomy

mutation placeOrder(
  $orderId: ID!
  $nameOnCard: String
  $phone: String
  $stripeToken: String
  $giftCardPayment: Boolean
  $inStorePayment: Boolean
) {
  placeOrder(
    input: {
      orderId: $orderId
      nameOnCard: $nameOnCard
      phone: $phone
      stripeToken: $stripeToken
      giftCardPayment: $giftCardPayment
      inStorePayment: $inStorePayment
    }
  ) {
    errors
    order {
      id
      isCompleted
    }
  }
}

query getOrderStatus($id: ID!, $mdashAccountId: ID) {
  order(id: $id, mdashAccountId: $mdashAccountId) {
    __typename
    id
    appliedGiftCards {
      id
      amountInCents
      code
      description
    }
    appliedPromotion {
      amountInCents
      code
    }
    completedByCustomerAt
    discountInCents
    isCompleted
    isGuacOrder
    isReadyForCheckout
    isValidShippingAddress
    processedAt
    productsSubtotalInCents
    shippingSubtotalInCents
    taxInCents
    totalInCents
  }
}

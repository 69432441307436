query getMegaphoneModal(
  $mdashAccountId: ID
) {
  megaphoneModal( mdashAccountId: $mdashAccountId ) {
    actionButtonText
    activeUntil
    megaphoneType
    messageBody
    title
  }
}

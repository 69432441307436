query getMdashAccountUserAdoptions (
  $mdashAccountId: ID!
) {
  mdashAccount (
    id: $mdashAccountId
  ) {
    id
    name
    merchantUserAccessLogs {
      user {
        id
        name
      }
      data {
        day
        value
      }
    }
  }
}
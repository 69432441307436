import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import ContentEmbed from 'src/components/02-molecules/ContentEmbed'
import getInvoiceSalesMetabaseReport from 'src/graphql/queries/getInvoiceSalesMetabaseReport.graphql'
import {
  IGetInvoiceSalesMetabaseReportQuery,
  IGetInvoiceSalesMetabaseReportQueryVariables,
} from 'src/graphql/queries/getInvoiceSalesMetabaseReport.types'
import useAppParams from 'src/utils/hooks/useAppParams'
import { useQuery } from 'urql'
import MDashHead from '../elements/MDashHead'

const SalesReport: FC = () => {
  const { mdashAccountId } = useAppParams()
  const { statementId } = useParams()

  const [{ fetching, error, data }] = useQuery<
    IGetInvoiceSalesMetabaseReportQuery,
    IGetInvoiceSalesMetabaseReportQueryVariables
  >({
    query: getInvoiceSalesMetabaseReport,
    variables: {
      mdashAccountId: mdashAccountId ?? '',
      invoiceId: statementId ?? '',
      metabaseEnv: import.meta.env.NODE_ENV,
    },
    requestPolicy: 'cache-and-network',
  })

  const url = data?.invoiceSalesMetabaseReport?.url

  return (
    <div className="flex grow flex-col">
      <MDashHead pageTitle="Statements: Invoice Sales Report" />

      <div className="grow-0 py-2  border-b border-b-gb-gray-400 lg:py-6">
        <Container>
          <Link
            className="text-gb-gray-800 font-semibold text-xs"
            to={`/${mdashAccountId}/statements/${statementId}`}
          >
            <FontAwesomeIcon icon={faAngleLeft} /> Back to Statement #{statementId}
          </Link>
          <h1 className="text-2xl font-semibold mb-6">Invoice Sales Report</h1>
        </Container>
      </div>

      <div className="bg-white flex flex-col grow">
        <Container className="flex flex-col grow">
          {fetching && <Skeleton className="mt-4.5" height="80vh" />}
          {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}
          {url && (
            <ContentEmbed
              src={url}
              title="Invoice Sales Report"
              className="min-h-[570px] md:min-h-[2000px]"
            />
          )}
        </Container>
      </div>
    </div>
  )
}
export default SalesReport

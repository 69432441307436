query getShipperInvoices(
    $mdashAccountId: ID!
  ) {
  shipperInvoices(
    mdashAccountId: $mdashAccountId,
  ) {
    id
    statementStart
    statementEnd
    invoiceableTotal
  }
}
query getPackageInfo($mdashAccountId: ID, $id: ID!) {
  package(id: $id, mdashAccountId: $mdashAccountId) {
    id
    orderId
    order {
      purchaserAccountId
    }
    purchaserName
    deliveryFirstName
    deliveryLastName
    deliveryCompany
    deliveryStreet1
    deliveryStreet2
    deliveryCity
    deliveryState
    deliveryPostalCode
    deliveryCountry
    deliveryPhone
    toShipOn
    requestedDeliveryOn
    completedAt
    gbmcState
    packingSheetPrintedAt
    order {
      id
      completedByCustomerAt
    }
    giftMessage {
      body
      from
    }
    currentShippingLabel {
      id
      serviceName
      trackingCode
      trackingLink
      printedAt
      discardedAt
    }
    lineItems {
      id
      name
      product {
        id
        name
      }
      quantityOrWeight
      specialInstructions
    }
    isCancelable
    isDeliveryDateEditable
    isGiftMessageEditable
    isReshipment
    isCorporateOrder
    isInStoreOrder
    isWhitelabel
    isSubscription
    corporateOrderCompanyName
    pickUpLocation {
      id
      name
    }
    shippingFacility {
      id
      name
    }
  }
}

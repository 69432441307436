mutation createAutoAssignmentRule(
  $shipperId: ID!, 
  $shipperFacilityId: ID!,
  $productId: ID,
  $deliveryState: String,
  $mode: FacilityAutoAssignmentRuleMode
) {
  createAutoAssignmentRule(
    input: {
      shipperId: $shipperId,
      shipperFacilityId: $shipperFacilityId,
      productId: $productId,
      deliveryState: $deliveryState,
      mode: $mode
    }
  ) {
    errors
    shipper {
      id
      facilityAutoAssignmentRules {
        id
        shipperFacilityId
        productId
        deliveryState
        mode
      }
    }
  }
}

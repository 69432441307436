query getManifestIssuesCalendar(
  $toShipOn: String!, 
  $mdashAccountId: ID, 
  $interval: ManifestCalendarInterval
) {
  manifestIssuesCalendar(
    toShipOn: $toShipOn, 
    mdashAccountId: $mdashAccountId, 
    interval: $interval
  ) {
    toShipOn
    unassignedPackagesCount
    labelIssuesCount
  }
}

/* eslint-disable no-console */
import { useRollbar } from '@rollbar/react'
import { Level } from 'rollbar'

type LogData = ( msg: string, info: Record<string, unknown>, level: Level ) => void

const useLogging = () => {
  const rollbar = useRollbar()

  const consoleLogFn = ( level: Level ): CallableFunction => {
    switch ( level ) {
      case 'critical':
      case 'error':
        return console.error
      case 'warning':
        return console.warn
      case 'info':
        return console.info
      case 'debug':
        return console.debug
      default:
        return console.log
    }
  }

  /**
   * Log the error message and info to Rollbar if available, otherwise log to console
   * @param {string} errorMsg The message to log.
   * @param {Record<string, unknown>} info Relevant information to be logged.
   */
  const logError: LogData = ( msg, info, level = 'error' ) => {
    if ( import.meta.env.VITE_ENVIRONMENT !== 'development' && rollbar ) {
      rollbar[level]( msg, info )
    } else {
      consoleLogFn( level )( msg, info )
    }
  }

  return logError
}

export default useLogging

import { FC } from 'react'
import { useQuery } from 'urql'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'

import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import Table from 'src/components/02-molecules/Table'
import StatementPageHeader from 'src/components/02-molecules/StatementPageHeader'
import SectionCard from 'src/components/01-atoms/SectionCard'
import USDCellValue from 'src/components/01-atoms/USDCellValue'

import useAppParams from 'src/utils/hooks/useAppParams'
import MDashHead from 'src/pages/elements/MDashHead'
import { gbmcOrderLink } from 'src/utils/gbmcLink'
import useMakeCSVDownloadButtonProps, {
  CSVData,
} from 'src/utils/hooks/useMakeCSVDownloadButtonProps'

import getInvoicePromotionsReport from 'src/graphql/queries/getInvoicePromotionsReport.graphql'
import {
  IGetInvoicePromotionsReportQuery,
  IGetInvoicePromotionsReportQueryVariables,
} from 'src/graphql/queries/getInvoicePromotionsReport.types'

import '../../components/02-molecules/StatementsTable/styles.css'

const Promotions: FC = () => {
  const { isAdmin, mdashAccountId } = useAppParams()
  const { statementId } = useParams()

  const columnWidths = [{}, {}, {}, { className: 'lg:w-64' }, {}]

  const tableCellClasses = 'flex gap-x-4 first-of-type:pt-4 pb-4 pl-4 pr-4 lg:p-4 lg:table-cell '
  const [{ fetching, error, data }] = useQuery<
    IGetInvoicePromotionsReportQuery,
    IGetInvoicePromotionsReportQueryVariables
  >({
    query: getInvoicePromotionsReport,
    variables: {
      mdashAccountId: mdashAccountId ?? '',
      invoiceId: statementId ?? '',
    },
    requestPolicy: 'cache-and-network',
  })

  const promotions = data?.invoicePromotionsReport

  const columnHeaders = [
    { key: 'packageId', displayLabel: 'Package ID' },
    { key: 'orderId', displayLabel: 'Customer Order' },
    { key: 'promoCode', displayLabel: 'Promo Code' },
    { key: 'description', displayLabel: 'Description' },
    { key: 'totalAdjustment', displayLabel: 'Total Adjustment' },
  ]
  const downloadButtonProps = useMakeCSVDownloadButtonProps({
    filename: `statement-${statementId}-promotions`,
    columnHeaders,
    csvData: promotions as CSVData,
    fetching,
  })

  const tableHeaders = columnHeaders.map(( header ) => ({ name: header.displayLabel }))

  return (
    <div className="flex grow flex-col">
      <MDashHead pageTitle="Statments: Promotions" />

      <Container className="pt-6">
        <StatementPageHeader
          title="Promotions"
          mdashAccountId={mdashAccountId}
          total={promotions?.length}
          statementId={statementId}
          downloadButtonProps={downloadButtonProps}
        />

        {fetching && <Skeleton className="mt-4.5" height="80vh" />}
        {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}
        {promotions && (
          <SectionCard className="col-span-12 mb-8">
            <Table
              tableClasses="statements"
              headers={tableHeaders}
              columnWidths={columnWidths}
              borderRule="headers-only"
            >
              {promotions.map(
                ({ id, packageId, orderId, description, totalAdjustment, promoCode }, index ) => (
                  <tr
                    key={`missed-shipment-${id}`}
                    className="flex flex-col even:bg-gb-gray-50 odd:bg-white lg:table-row"
                  >
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[0].name}</div>
                      {packageId}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[1].name}</div>
                      {orderId}
                      {isAdmin && (
                        <a
                          className="block text-2xs text-gb-blue-600 hover:text-gb-blue-700 transition-colors"
                          href={gbmcOrderLink( orderId ?? '' )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          VIEW IN GBMC
                        </a>
                      )}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
                      {promoCode}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[3].name}</div>
                      {description}
                    </td>
                    <td className={tableCellClasses} data-testid={`total-adjustment-${index}`}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[4].name}</div>
                      <USDCellValue amount={totalAdjustment} />
                    </td>
                  </tr>
                )
              )}
            </Table>
          </SectionCard>
        )}
      </Container>
    </div>
  )
}
export default Promotions

query getManifestPackages(
  $page: Int
  $limit: Int
  $toShipOn: String!
  $mdashAccountId: ID
  $facilityId: ID
  $facilityType: FacilityType
  $labelPrintStatus: ShippingLabelStatus
  $productIds: [ID!]
  $productExclusion: Boolean
  $orderTypes: [OrderType!]
  $productCountInPackages: [Int!]
  $recipientCountries: [String!]
  $recipientStates: [String!]
  $shippingSpeeds: [String!]
  $corporateOrderCompanyNames: [String!]
  $query: String
  $packageIds: [ID!]
  $sortField: ManifestPackageSort
  $sortAscending: Boolean
) {
  manifestPackages(
    toShipOn: $toShipOn
    mdashAccountId: $mdashAccountId
    facilityId: $facilityId
    facilityType: $facilityType
    labelPrintStatus: $labelPrintStatus
    productIds: $productIds
    productExclusion: $productExclusion
    orderTypes: $orderTypes
    productCountInPackages: $productCountInPackages
    recipientCountries: $recipientCountries
    recipientStates: $recipientStates
    shippingSpeeds: $shippingSpeeds
    corporateOrderCompanyNames: $corporateOrderCompanyNames
    query: $query
    packageIds: $packageIds
    sortField: $sortField
    sortAscending: $sortAscending
  ) {
    metadata {
      sorter {
        sortAscending
        sortField
      }
    }
    packages(page: $page, limit: $limit) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        id
        orderId
        deliveryCompany
        deliveryCountry
        deliveryFirstName
        deliveryLastName
        deliveryCity
        deliveryState
        labelNeedsDiscard
        labelNeedsReprint
        gbmcState
        packingSheetPrintedAt
        lineItems {
          id
          name
          quantityOrWeight
          product {
            id
            name
          }
          specialInstructions
        }
        currentShippingLabel {
          id
          printedAt
          discardedAt
          carrierResponse
          serviceName
          trackingCode
          trackingLink
        }
      }
    }
  }
}

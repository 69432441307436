mutation createTaxonomy($mdashAccountId: ID!, $name: String!) {
  createTaxonomy(input: { mdashAccountId: $mdashAccountId, name: $name }) {
    errors
    mdashAccount {
      id
      products {
        id
        name
      }
      taxonomies {
        id
        name
      }
      productTaxonomies {
        id
        productId
        taxonomyId
        isEnabled
      }
    }
  }
}
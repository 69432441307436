import { OperationResult, useMutation } from 'urql'
import { useState } from 'react'

import EditGiftMessageModalComponent, {
  IEditGiftMessageModalProps as IEditGiftMessageModalComponentProps,
} from 'src/components/03-organisms/EditGiftMessageModal'

import editGiftMessageOnCompletedSuborder from 'src/graphql/mutations/editGiftMessageOnCompletedSuborder.graphql'
import {
  IEditGiftMessageOnCompletedSuborderMutation,
  IEditGiftMessageOnCompletedSuborderMutationVariables,
} from 'src/graphql/mutations/editGiftMessageOnCompletedSuborder.types'
import useAppParams from 'src/utils/hooks/useAppParams'

interface IEditGiftMessageModalProps extends IEditGiftMessageModalComponentProps {
  packageId: string
}

const EditGiftMessageModal = ({
  packageId,
  ...giftMessageModalProps
}: IEditGiftMessageModalProps ) => {
  const { mdashAccountId } = useAppParams()
  const [ saving, setSaving ] = useState( false )
  const [ savedLastResponse, setSavedLastResponse ] =
    useState<OperationResult<IEditGiftMessageOnCompletedSuborderMutation> | null>( null )
  const [ , editGiftMessage ] = useMutation<
    IEditGiftMessageOnCompletedSuborderMutation,
    IEditGiftMessageOnCompletedSuborderMutationVariables
  >( editGiftMessageOnCompletedSuborder )

  const handleSave = ({ body, from }: { body: string; from: string }) => {
    setSaving( true )
    editGiftMessage({
      packageId,
      mdashAccountId,
      body: body.trim(),
      from: from.trim(),
    }).then(( response ) => {
      setSavedLastResponse( response )
      setSaving( false )
      giftMessageModalProps.handleClose?.()
    })
  }

  const errors =
    savedLastResponse?.error?.message || savedLastResponse?.data?.editGiftMessage?.errors.join( ' ' )

  return (
    <EditGiftMessageModalComponent
      {...giftMessageModalProps}
      saving={saving}
      handleSave={handleSave}
      error={errors}
    />
  )
}

export default EditGiftMessageModal

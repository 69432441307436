mutation removeAutoAssignmentRule(
  $shipperId: ID!,
  $ruleId: ID!
) {
  removeAutoAssignmentRule(
    input: {
      shipperId: $shipperId,
      ruleId: $ruleId
    }
  ) {
    errors
    shipper {
      id
      facilityAutoAssignmentRules {
        id
        shipperFacilityId
        productId
        deliveryState
        mode
      }
    }
  }
}

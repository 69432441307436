import { FC } from 'react'
import { ISvgIconProps } from '../types'

export const WhatsNewMobileFriendly: FC<ISvgIconProps> = ( props ) => (
  <svg
    width="45"
    height="60"
    viewBox="0 0 45 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M4.5 1L1.5 4L0.5 55L5 58.5L39.5 59L43 55V5L39.5 1H4.5Z" fill="#BECAF8" />
    <path
      d="M4.95294 46L1.98824 46.6724L1.5 56L5.44706 58.8879L39.5412 59L43 58.1034V46.8966L39.5412 46H4.95294Z"
      fill="#5F79D6"
    />
    <path d="M12.5 12V15.5L16.5 16L13.5 19.5L9.5 18.5L8.5 15L12.5 12Z" fill="#FF98DC" />
    <path d="M33 12.5L32.5 15.5L29 16L32 19.5L36 18V14L33 12.5Z" fill="#FF98DC" />
    <path
      d="M26.5 26.5C27 24 25.5 19 22.5 19C18.9 19.4 19 26.5 19.5 30L20.5 36L16.5 39.5L19 41L24.5 42L28.5 40C27.8333 39.1667 26.3 37.3 25.5 36.5C27 35 26 29 26.5 26.5Z"
      fill="#FF98DC"
    />
    <g clipPath="url(#clip0_5881_90481)">
      <path
        d="M38.4504 60H5.75321C2.57957 60 0 57.4204 0 54.2468V5.75321C0 2.57957 2.57957 0 5.75321 0H38.4504C41.624 0 44.2036 2.57957 44.2036 5.75321V54.2508C44.2036 57.4244 41.624 60.004 38.4504 60.004V60ZM5.75321 2.39219C3.89926 2.39219 2.39219 3.89926 2.39219 5.75321V54.2508C2.39219 56.1047 3.89926 57.6118 5.75321 57.6118H38.4504C40.3043 57.6118 41.8114 56.1047 41.8114 54.2508V5.75321C41.8114 3.89926 40.3043 2.39219 38.4504 2.39219H5.75321Z"
        fill="#243880"
      />
      <path
        d="M38.5861 59.9993H5.88895C2.71532 59.9993 0.135742 57.4197 0.135742 54.2461V45.6621H44.3393V54.2461C44.3393 57.4197 41.7598 59.9993 38.5861 59.9993ZM2.52793 48.0583V54.2501C2.52793 56.104 4.035 57.6111 5.88895 57.6111H38.5861C40.4401 57.6111 41.9472 56.104 41.9472 54.2501V48.0583H2.52793Z"
        fill="#243880"
      />
      <path
        d="M22.8369 32.4579C22.3584 32.4579 21.9039 32.3981 21.4813 32.2785C20.5763 32.0313 19.8068 31.513 19.2047 30.7475C18.8778 30.3368 18.5987 29.8464 18.3794 29.2922C18.3675 29.2683 18.3595 29.2444 18.3515 29.2165C17.6299 27.3626 17.6299 25.1059 17.6299 23.8859C17.6299 20.9754 19.9543 17.4629 22.8409 17.4629C25.7274 17.4629 28.0518 20.9754 28.0518 23.8859C28.0518 25.1059 28.0518 27.3665 27.3262 29.2165C27.3182 29.2404 27.3103 29.2683 27.2983 29.2922C26.5049 31.2698 25.1693 32.0074 24.1885 32.2785C23.7738 32.3941 23.3193 32.4539 22.8409 32.4539L22.8369 32.4579ZM20.8075 28.3952C20.9271 28.6902 21.0746 28.9414 21.2341 29.1447C21.4933 29.4756 21.8003 29.683 22.175 29.7866C22.5777 29.9023 23.104 29.8983 23.4907 29.7866C23.722 29.7228 24.3918 29.5394 24.8702 28.3912C24.8782 28.3673 24.8862 28.3473 24.8942 28.3234C25.4603 26.924 25.4603 25.0222 25.4603 23.8859C25.4603 22.3031 24.1047 20.0504 22.8409 20.0504C21.577 20.0504 20.2174 22.3031 20.2174 23.8859C20.2174 25.0222 20.2174 26.92 20.7836 28.3234C20.7916 28.3473 20.7995 28.3713 20.8075 28.3952Z"
        fill="#9F377C"
      />
      <path
        d="M12.447 21.1028C9.60826 21.1028 7.2998 18.7943 7.2998 15.9556C7.2998 13.1169 9.60826 10.8164 12.447 10.8164C13.1607 10.8164 13.7428 11.3945 13.7428 12.1122V14.4884H16.2864C16.9244 14.4884 17.4706 14.9549 17.5663 15.5848C17.5822 15.7005 17.5902 15.828 17.5902 15.9556C17.5902 18.7943 15.2857 21.1028 12.451 21.1028H12.447ZM11.1512 13.7548C10.3937 14.1974 9.88735 15.0187 9.88735 15.9556C9.88735 17.367 11.0356 18.5153 12.447 18.5153C13.4557 18.5153 14.3288 17.9252 14.7435 17.072H12.447C11.7333 17.072 11.1512 16.4939 11.1512 15.7762V13.7508V13.7548Z"
        fill="#9F377C"
      />
      <path
        d="M32.641 20.8036C29.9737 20.8036 27.8008 18.6307 27.8008 15.9554C27.8008 15.8358 27.8048 15.7162 27.8207 15.6125C27.9124 14.9746 28.4586 14.5002 29.1005 14.5002H31.3452V12.411C31.3452 11.6973 31.9233 11.1152 32.641 11.1152C35.3122 11.1152 37.4891 13.2841 37.4891 15.9554C37.4891 18.6267 35.3162 20.8036 32.641 20.8036ZM30.6913 17.0877C31.0821 17.7615 31.8077 18.216 32.641 18.216C33.8849 18.216 34.9016 17.2033 34.9016 15.9554C34.9016 15.1939 34.5188 14.5161 33.9367 14.1095V15.792C33.9367 16.5056 33.3586 17.0877 32.641 17.0877H30.6913Z"
        fill="#9F377C"
      />
      <path
        d="M22.8372 39.1993C21.2065 39.1993 19.7672 37.9753 18.8901 35.8463C18.603 35.1406 18.3877 34.3671 18.2482 33.5458C18.2482 33.5259 18.2442 33.5059 18.2402 33.486C18.1126 32.7604 18.0488 31.9989 18.0488 31.2214C18.0488 30.3243 18.1365 29.4392 18.308 28.59C18.4156 27.984 18.9459 27.5215 19.5798 27.5215C20.2935 27.5215 20.8756 28.0956 20.8756 28.8093C20.8756 28.897 20.8676 28.9967 20.8477 29.0804C20.7081 29.7662 20.6364 30.4878 20.6364 31.2214C20.6364 31.8753 20.6882 32.4853 20.7959 33.0873C20.7998 33.1152 20.8038 33.1431 20.8078 33.167C20.9155 33.781 21.0749 34.3512 21.2823 34.8575C21.7129 35.9021 22.3388 36.6078 22.8372 36.6078C23.3356 36.6078 23.9615 35.9021 24.3921 34.8535C24.5994 34.3472 24.7589 33.777 24.8666 33.1631C24.8666 33.1351 24.8745 33.1112 24.8785 33.0873C24.9862 32.4853 25.038 31.8753 25.038 31.2214C25.038 30.4878 24.9662 29.7701 24.8267 29.0804C24.8107 28.9927 24.7988 28.909 24.7988 28.8212C24.7988 28.1076 25.3769 27.5215 26.0945 27.5215C26.7484 27.5215 27.2906 28.0039 27.3784 28.6339C27.5458 29.4711 27.6295 30.3403 27.6295 31.2254C27.6295 32.0028 27.5657 32.7644 27.4382 33.494C27.4382 33.5139 27.4342 33.5338 27.4302 33.5498C27.2906 34.3711 27.0753 35.1446 26.7883 35.8463C25.9072 37.9833 24.4679 39.2033 22.8372 39.2033V39.1993Z"
        fill="#9F377C"
      />
      <path
        d="M22.8368 43.445C21.3377 43.445 19.9582 42.7632 19.0452 41.5751C18.6106 41.0089 18.7183 40.1956 19.2844 39.761C19.8506 39.3264 20.6639 39.4341 21.0985 40.0002C21.9318 41.0887 23.7379 41.0887 24.5712 40.0002C25.0057 39.4341 25.8191 39.3264 26.3852 39.761C26.9514 40.1956 27.059 41.0089 26.6244 41.5751C25.7154 42.7632 24.3319 43.445 22.8328 43.445H22.8368Z"
        fill="#9F377C"
      />
      <path
        d="M20.0743 42.0814H18.332C17.4269 42.0814 16.6215 41.5751 16.2308 40.7577C15.8401 39.9404 15.9517 38.9955 16.5179 38.2898L18.4994 35.8378C18.7466 35.5348 19.1134 35.3594 19.5041 35.3594H20.1859C20.8996 35.3594 21.4817 35.9375 21.4817 36.6551C21.4817 37.3728 20.9036 37.9509 20.1859 37.9509H20.1221L18.8702 39.5018H20.0703C20.7839 39.5018 21.366 40.08 21.366 40.7976C21.366 41.5153 20.7879 42.0934 20.0703 42.0934L20.0743 42.0814Z"
        fill="#9F377C"
      />
      <path
        d="M27.3422 42.0817H25.5999C24.8862 42.0817 24.3041 41.5035 24.3041 40.7859C24.3041 40.0682 24.8822 39.4901 25.5999 39.4901H26.7999L25.4842 37.9392C24.7706 37.9392 24.1885 37.3611 24.1885 36.6434C24.1885 35.9258 24.7666 35.3477 25.4842 35.3477H26.166C26.5567 35.3477 26.9275 35.5231 27.1707 35.8261L29.1523 38.2781C29.7224 38.9838 29.83 39.9287 29.4393 40.746C29.0486 41.5633 28.2432 42.0697 27.3382 42.0697L27.3422 42.0817Z"
        fill="#9F377C"
      />
      <path
        d="M27.6133 23.9449C27.1149 23.9449 26.5846 23.8611 26.0224 23.6738C25.3447 23.4505 24.9779 22.7169 25.2011 22.0391C25.4244 21.3613 26.154 20.9945 26.8358 21.2178C28.913 21.9075 29.9775 19.5433 30.0214 19.4396C30.3084 18.7857 31.0739 18.4907 31.7278 18.7778C32.3817 19.0648 32.6767 19.8303 32.3896 20.4842C31.7996 21.8198 30.141 23.9409 27.6133 23.9409V23.9449Z"
        fill="#9F377C"
      />
      <path
        d="M17.6657 23.858C17.5301 23.858 17.3986 23.85 17.263 23.8381C15.0183 23.5988 13.3677 21.4818 13.1843 21.2425C12.7537 20.6724 12.8694 19.8591 13.4395 19.4285C14.0096 18.9979 14.819 19.1135 15.2496 19.6836C15.5406 20.0664 16.5812 21.1668 17.5381 21.2665C17.7694 21.2944 18.1202 21.2784 18.5667 20.8877C19.105 20.4212 19.9223 20.477 20.3928 21.0193C20.8632 21.5615 20.8034 22.3748 20.2652 22.8453C19.4917 23.5151 18.5986 23.862 17.6657 23.862V23.858Z"
        fill="#9F377C"
      />
      <path
        d="M20.7677 20.1615C20.5125 20.1615 20.2573 20.0897 20.0341 19.9422C19.2925 19.4518 18.1642 17.9726 19.2207 15.8874C19.7111 14.9226 19.145 13.7624 19.137 13.7504C18.8021 13.1205 19.0453 12.335 19.6792 12.0041C20.3132 11.6732 21.0946 11.9124 21.4255 12.5464C21.4734 12.642 22.6296 14.8827 21.5292 17.0596C21.3458 17.4224 21.3378 17.6776 21.5053 17.8171C22.1033 18.2118 22.2429 18.9973 21.8481 19.5953C21.601 19.9661 21.1903 20.1655 20.7677 20.1655V20.1615Z"
        fill="#9F377C"
      />
      <path
        d="M24.683 20.1099C24.2644 20.1099 23.8497 19.9066 23.6025 19.5278C23.2158 18.9457 23.3633 18.1643 23.9294 17.7616C24.0929 17.6221 24.081 17.3709 23.9015 17.0081C22.8011 14.8312 23.9574 12.5905 24.0052 12.4948C24.3401 11.8649 25.1216 11.6217 25.7515 11.9526C26.3854 12.2875 26.6246 13.0689 26.2937 13.6989C26.2937 13.6989 25.7156 14.8631 26.21 15.8359C27.2665 17.9171 26.1382 19.3963 25.3967 19.8906C25.1774 20.0342 24.9302 20.1059 24.683 20.1059V20.1099Z"
        fill="#9F377C"
      />
      <path
        d="M16.0992 28.2475C15.1024 28.2475 13.994 27.9205 12.8817 27.0035C12.3315 26.549 12.2517 25.7317 12.7062 25.1815C13.1608 24.6313 13.9781 24.5515 14.5283 25.0061C16.1032 26.3018 17.3989 25.2772 17.6421 25.0539C18.1684 24.5755 18.9857 24.6074 19.4642 25.1296C19.9466 25.6519 19.9267 26.4533 19.4084 26.9437C18.7784 27.5418 17.5584 28.2435 16.0952 28.2435L16.0992 28.2475Z"
        fill="#9F377C"
      />
      <path
        d="M16.0991 32.2455C15.1023 32.2455 13.994 31.9186 12.8856 31.0016C12.3354 30.5471 12.2556 29.7297 12.7102 29.1795C13.1647 28.6293 13.982 28.5496 14.5322 29.0041C16.111 30.2999 17.4028 29.2752 17.646 29.0519C18.1723 28.5775 18.9857 28.6094 19.4681 29.1277C19.9505 29.65 19.9266 30.4514 19.4123 30.9418C18.7823 31.5398 17.5623 32.2415 16.0991 32.2415V32.2455Z"
        fill="#9F377C"
      />
      <path
        d="M29.8345 28.2475C28.8378 28.2475 27.7294 27.9205 26.617 27.0035C26.0668 26.549 25.9871 25.7317 26.4416 25.1815C26.8961 24.6313 27.7134 24.5515 28.2636 25.0061C29.8385 26.3018 31.1303 25.2772 31.3775 25.0539C31.9038 24.5755 32.7211 24.6074 33.1995 25.1296C33.6819 25.6519 33.662 26.4533 33.1437 26.9437C32.5138 27.5418 31.2937 28.2435 29.8305 28.2435L29.8345 28.2475Z"
        fill="#9F377C"
      />
      <path
        d="M29.8344 32.2455C28.8377 32.2455 27.7293 31.9186 26.6209 31.0016C26.0707 30.5471 25.991 29.7297 26.4455 29.1795C26.9 28.6293 27.7174 28.5496 28.2676 29.0041C29.8464 30.2999 31.1382 29.2752 31.3814 29.0519C31.9077 28.5775 32.725 28.6094 33.2034 29.1277C33.6819 29.646 33.6619 30.4514 33.1476 30.9418C32.5177 31.5398 31.2977 32.2415 29.8344 32.2415V32.2455Z"
        fill="#9F377C"
      />
      <path
        d="M28.0363 51.4922H17.399C16.45 51.4922 15.6807 52.2615 15.6807 53.2106C15.6807 54.1596 16.45 54.929 17.399 54.929H28.0363C28.9853 54.929 29.7547 54.1596 29.7547 53.2106C29.7547 52.2615 28.9853 51.4922 28.0363 51.4922Z"
        fill="#BECAF8"
      />
    </g>
    <defs>
      <clipPath id="clip0_5881_90481">
        <rect width="44.3392" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

query getCustomerChoices($mdashAccountId: ID, $toShipOn: String, $packageIds: [ID!]) {
  lineItemsCustomerChoices(
    mdashAccountId: $mdashAccountId
    toShipOn: $toShipOn
    packageIds: $packageIds
  ) {
    id
    suborderId
    customerChoices {
      name
      position
    }
    merchantSkus {
      name
      quantity
    }
  }
}

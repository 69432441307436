query getShipperInvoices(
    $id: ID!,
    $mdashAccountId: ID!
  ) {
  shipperInvoices(
    id: $id
    mdashAccountId: $mdashAccountId,
  ) {
    adjustmentsTotal
    cancellationsTotal
    giftCardRedemptionsTotal
    id
    invoiceableTotal
    merchantName
    missedShipmentsTotal
    pdfDocumentUrl
    promotionsTotal
    refundsTotal
    reshipmentsTotal
    salesTotal
    statementStart
    statementEnd
    totalInCents
  }
}

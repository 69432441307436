mutation logUserIn($email: String!, $password: String!) {
  login(input: { email: $email, password: $password }) {
    errors
    identity {
      merchantUser {
        id
        name
        mdashAccount {
          id
          name
        }
      }
    }
  }
}

query getInvoiceCancellationsReport(
    $mdashAccountId: ID!,
    $invoiceId: ID!) {
  invoiceCancellationsReport(mdashAccountId: $mdashAccountId, invoiceId: $invoiceId) {
    cancelledAt
    id
    orderId
    packageId
    totalAdjustment
    shippedOn
  }
}

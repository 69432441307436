import { FC } from 'react'
import { DryIce } from '../Svg/DryIce'

interface IDryIceStickerProps {
  weightInKg: number
}

const DryIceSticker: FC<IDryIceStickerProps> = ({ weightInKg }) => (
  <svg
    viewBox="0 0 812 812"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    data-testid="dry-ice-sticker"
  >
    <rect width="812" height="812" fill="url(#pattern)" />
    <text x="50" y="643" fill="black" className="text-4xl font-bold" data-testid="dry-ice-weight">
      {weightInKg}
    </text>
    <DryIce id="image" height="812" width="812" />
  </svg>
)

export default DryIceSticker

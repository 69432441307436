import { useQuery, UseQueryResponse } from 'urql'
import { startOfDay } from 'date-fns'
import getManifestIssuesCalendar from 'src/graphql/queries/getManifestIssuesCalendar.graphql'
import {
  IGetManifestIssuesCalendarQuery,
  IGetManifestIssuesCalendarQueryVariables,
} from 'src/graphql/queries/getManifestIssuesCalendar.types'
import useAppParams from './useAppParams'
import { IUseGetManifestVariables } from '../types/GetManifestHookQueryTypes'
import { stringAsDate } from '../helpers/date'

interface IGetManifestIssuesCalendarQueryWithDates extends IGetManifestIssuesCalendarQuery {
  manifestIssuesCalendar: {
    toShipOn: Date
    unassignedPackagesCount: number
    labelIssuesCount: number
  }[]
}

const useGetManifestIssuesCalendar = ({
  variables = {},
  ...useQueryOptionOverrides
}: IUseGetManifestVariables<
  IGetManifestIssuesCalendarQuery,
  IGetManifestIssuesCalendarQueryVariables
> ): UseQueryResponse<
  IGetManifestIssuesCalendarQueryWithDates,
  IGetManifestIssuesCalendarQueryVariables
> => {
  const { mdashAccountId, toShipOnAsString: toShipOn } = useAppParams()
  const [ response, refetch ] = useQuery<
    IGetManifestIssuesCalendarQuery,
    IGetManifestIssuesCalendarQueryVariables
  >({
    query: getManifestIssuesCalendar,
    variables: {
      mdashAccountId,
      toShipOn,
      ...variables,
    },
    requestPolicy: 'cache-and-network',
    ...useQueryOptionOverrides,
  })

  const calendarWithDateObjects = response.data
    ? response.data.manifestIssuesCalendar.map(
        ({ labelIssuesCount, unassignedPackagesCount, toShipOn }) => ({
          labelIssuesCount,
          unassignedPackagesCount,
          toShipOn: startOfDay( stringAsDate( toShipOn )),
        })
      )
    : []

  return [
    {
      ...response,
      data: { ...response.data, manifestIssuesCalendar: calendarWithDateObjects },
    },
    refetch,
  ]
}

export default useGetManifestIssuesCalendar

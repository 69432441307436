import { useQuery } from 'urql'
import getProductTaxonomies from 'src/graphql/queries/getProductTaxonomies.graphql'
import {
  IGetProductTaxonomiesQuery,
  IGetProductTaxonomiesQueryVariables,
} from 'src/graphql/queries/getProductTaxonomies.types'
import useAppParams from './useAppParams'

const useProductTaxonomies = () => {
  const { mdashAccountId } = useAppParams()

  const [{ data, fetching }] = useQuery<
    IGetProductTaxonomiesQuery,
    IGetProductTaxonomiesQueryVariables
  >({
    query: getProductTaxonomies,
    variables: { mdashAccountId },
    pause: Number( mdashAccountId || 0 ) < 1,
  })

  return {
    data,
    fetching,
  }
}

export default useProductTaxonomies

import { CombinedError, createClient, defaultExchanges, Exchange } from 'urql'
import { retryExchange, RetryExchangeOptions } from '@urql/exchange-retry'
import { devtoolsExchange } from '@urql/devtools'

/**
 * defaultExchanges = [ dedupExchange, cacheExchange, fetchExchange ]
 */

const retryOptions: RetryExchangeOptions = {
  initialDelayMs: 1000,
  maxDelayMs: 15000,
  randomDelay: true,
  maxNumberAttempts: 2,
  retryIf: ( err: CombinedError ): boolean => !!( err && err.networkError ),
}
const exchanges = [ retryExchange( retryOptions ) as Exchange, ...defaultExchanges ]

if ( import.meta.env.VITE_ENVIRONMENT === 'development' ) {
  exchanges.push( devtoolsExchange )
}

export default createClient({
  url: import.meta.env.VITE_GRAPHQL_ENDPOINT || '//www.goldbelly.com/mdashx/graphql',
  fetchOptions: { credentials: 'include' },
  exchanges,
})

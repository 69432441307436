query getManifestAuxiliaryAggregates(
  $toShipOn: String!
  $mdashAccountId: ID
  $facilityId: ID
  $facilityType: FacilityType
  $labelPrintStatus: ShippingLabelStatus
  $productIds: [ID!]
  $orderTypes: [OrderType!]
  $productCountInPackages: [Int!]
  $recipientCountries: [String!]
  $recipientStates: [String!]
  $shippingSpeeds: [String!]
  $corporateOrderCompanyNames: [String!]
  $query: String
  $packageIds: [ID!]
) {
  manifestPackages(
    toShipOn: $toShipOn
    mdashAccountId: $mdashAccountId
    facilityId: $facilityId
    facilityType: $facilityType
    labelPrintStatus: $labelPrintStatus
    productIds: $productIds
    orderTypes: $orderTypes
    productCountInPackages: $productCountInPackages
    recipientCountries: $recipientCountries
    recipientStates: $recipientStates
    shippingSpeeds: $shippingSpeeds
    corporateOrderCompanyNames: $corporateOrderCompanyNames
    query: $query
    packageIds: $packageIds
  ) {
    metadata {
      aggregates(auxiliaries: true) {
        category
        indent
        query
        label
        value
      }
    }
  }
}

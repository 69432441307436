query getSubProductSummary(
  $toShipOn: String
  $mdashAccountId: ID
  $packageIds: [ID!]
  $facilityId: ID
  $facilityType: FacilityType
) {
  subproductSummary(
    toShipOn: $toShipOn
    mdashAccountId: $mdashAccountId
    packageIds: $packageIds
    facilityId: $facilityId
    facilityType: $facilityType
  ) {
    products {
      id
      name
      quantity
      subproducts {
        id
        name
        optional
        quantity
      }
    }
    subproducts {
      id
      name
      sku
      optional
      quantity
    }
    totalPackages
    selectedPackages
  }
}

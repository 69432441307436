mutation updateCartItem(
  $cartItemId: ID!
  $productId: ID
  $variances: [VarianceInput!]
  $quantityOrWeight: Float
  $specialInstructions: String
) {
  updateCartItem(
    input: {
      cartItemId: $cartItemId
      productId: $productId
      variances: $variances
      quantityOrWeight: $quantityOrWeight
      specialInstructions: $specialInstructions
    }
  ) {
    errors
    cartItem {
      __typename
      id
      product {
        __typename
        id
        name
        priceInCents
        variances {
          id
          name
          options {
            id
            name
            priceAdjInCents
          }
        }
      }
      quantity
      selectedProductOptions {
        productOptionId
        varianceId
      }
      specialInstructions
      subtotalInCents
      unitPriceInCents
      weight
    }
  }
}

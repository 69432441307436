mutation batchPrint(
  $mdashAccountId: ID!
  $toShipOn: String!
  $labelPrintStatus: ShippingLabelStatus
  $productIds: [ID!]
  $orderTypes: [OrderType!]
  $productCountInPackages: [Int!]
  $recipientCountries: [String!]
  $recipientStates: [String!]
  $shippingSpeeds: [String!]
  $corporateOrderCompanyNames: [String!]
  $query: String
  $packageIds: [ID!]
  $facilityType: FacilityType
  $facilityId: ID
  $page: Int
  $limit: Int
  $batchPrintActionId: ID
  $printedShippingLabelIds: [ID!]
  $printedPackingSlipForSuborderIds: [ID!]
  $undoMutation: Boolean
  $sortField: ManifestPackageSort
  $sortAscending: Boolean
) {
  batchPrint(
    input: {
      mdashAccountId: $mdashAccountId
      toShipOn: $toShipOn
      labelPrintStatus: $labelPrintStatus
      productIds: $productIds
      orderTypes: $orderTypes
      productCountInPackages: $productCountInPackages
      recipientCountries: $recipientCountries
      recipientStates: $recipientStates
      shippingSpeeds: $shippingSpeeds
      corporateOrderCompanyNames: $corporateOrderCompanyNames
      query: $query
      packageIds: $packageIds
      facilityType: $facilityType
      facilityId: $facilityId
      batchPrintActionId: $batchPrintActionId
      printedShippingLabelIds: $printedShippingLabelIds
      printedPackingSlipForSuborderIds: $printedPackingSlipForSuborderIds
      undoMutation: $undoMutation
      sortField: $sortField
      sortAscending: $sortAscending
    }
  ) {
    manifestPackages {
      packages(page: $page, limit: $limit) {
        metadata {
          totalCount
        }
        collection {
          id
          orderId
          deliveryFirstName
          deliveryLastName
          deliveryCountry
          qrCodeImageUrl
          requiredDryIceWeightInPounds
          giftMessage {
            body
            from
          }
          lineItems {
            id
            name
            merchant {
              id
              packingSlipLogoImageUrl
              effectiveShortName
              name
            }
            quantityOrWeight
            product {
              id
              name
            }
            specialInstructions
          }
          currentShippingLabel {
            id
            displayWhitelabelLogoInPackingSlip
            labelImageUrl
          }
        }
      }
    }
    batchPrintAction {
      id
      length
      fulfillmentSheetsPartiallyPrinted
      shippingLabelsPartiallyPrinted
      pickListsPartiallyPrinted
    }
    markedShippingLabels {
      id
    }
    markedPackingSlipForPackages {
      id
    }
    errors
  }
}

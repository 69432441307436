import { FC, useState } from 'react'
import { To } from 'react-router-dom'
import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'

import ChoiceInput from 'src/components/01-atoms/ChoiceInput'
import PackageTagList from 'src/components/02-molecules/PackageTagList'
import InlineLink from 'src/components/01-atoms/InlineLink'
import PackageListTableCell from 'src/components/01-atoms/PackageListTableCell'
import PackageTag, { PackageType } from 'src/components/01-atoms/PackageTag'
import PackageStatus from 'src/components/02-molecules/PackageStatus'
import ManifestPackageContents from 'src/components/02-molecules/ManifestPackageContents'

import { gbmcSuborderLink } from 'src/utils/gbmcLink'
import { PackageListPackage } from 'src/utils/types/PackageListPackage'
import { stringAsDate } from 'src/utils/helpers/date'
import { ILineItemCustomerChoice } from 'src/graphql/types'
import { packageIsSelectable } from 'src/utils/helpers/package'
import mergeClassNames from 'src/utils/helpers/mergeClassNames'

export interface IOrderType {
  isCorporateOrder: boolean
  isInStoreOrder: boolean
  isReshipment?: boolean
  isSubscription: boolean
  isWhitelabel: boolean
  isGift?: boolean
}

export interface ICorporateOrder {
  corporateOrderCompanyName?: string | null
}
export interface IPurchaserInfo {
  id: string
  purchaserName?: string | null
  purchaserEmail?: string | null
  purchaserPhone?: string | null
}

export interface IFacility {
  id: string
  shippingFacility?: {
    id: string
    name?: string | null
  } | null
  pickUpLocation?: {
    id: string
    name?: string | null
  } | null
}

interface IPackageListTableRowProps {
  package: PackageListPackage & IOrderType & ICorporateOrder & IPurchaserInfo & IFacility
  packageLink: To
  printLink: To
  reprintLink: To
  discardLink: To
  productOptions?: ILineItemCustomerChoice[]
  fetchingOrderTypes: boolean
  fetchingCorporateOrders: boolean
  fetchingProductOptions: boolean
  fetchingPurchaserInfo: boolean
  fetchingFacilities: boolean
  selected?: boolean
  selectPackageChange?: CallableFunction
  isSelectable?: boolean
  isAbstractFacility?: boolean
  canAssignLocation?: boolean
  cellClasses?: string
  serviceName?: String
  showActionLink?: boolean
  showLinkToGbmc?: boolean
  showPurchaserInfo?: boolean
  showFacilityName?: boolean
  trackingLink?: string | null
}

const PackageListTableRow: FC<IPackageListTableRowProps> = ({
  package: pkg,
  packageLink,
  printLink,
  reprintLink,
  discardLink,
  selected = false,
  selectPackageChange = () => {},
  productOptions,
  fetchingOrderTypes,
  fetchingCorporateOrders,
  fetchingProductOptions,
  fetchingPurchaserInfo,
  fetchingFacilities,
  isSelectable,
  isAbstractFacility,
  canAssignLocation,
  cellClasses,
  showActionLink = true,
  showPurchaserInfo,
  showLinkToGbmc,
  showFacilityName,
  trackingLink,
}) => {
  const [ isOpen, setIsOpen ] = useState( false )

  const isCanceled = pkg?.gbmcState === 'canceled'
  const isEligible = packageIsSelectable( pkg, isAbstractFacility, canAssignLocation )

  const pkgContentsCellClasses = classNames({ hidden: !isOpen, block: isOpen }, 'px-4 pt-2' )

  const serviceName =
    pkg?.currentShippingLabel?.serviceName && pkg?.currentShippingLabel?.serviceName.split( ' ' )
  const prettyCarrier = serviceName && {
    carrier: serviceName.shift(),
    service: serviceName.join( ' ' ),
  }

  const facilityName = pkg.shippingFacility?.name || pkg.pickUpLocation?.name || 'Unassigned'

  return (
    <tr
      data-testid={`package-${pkg?.id}`}
      className={classNames(
        {
          canceled: isCanceled,
        },
        'flex flex-col relative md:table-row'
      )}
    >
      {isSelectable && (
        <PackageListTableCell
          className={classNames( cellClasses, 'absolute z-20 h-11 center-v bg-none md:static' )}
        >
          <ChoiceInput
            labelText={`Select package #${pkg?.id}`}
            checked={selected}
            disabled={!isEligible}
            onChange={( e ) => selectPackageChange( e )}
            value={pkg?.id}
            hideLabel
          />
        </PackageListTableCell>
      )}
      <PackageListTableCell
        className={classNames(
          cellClasses,
          { 'pl-12': isSelectable },
          'relative z-10 border-b-2 bg-white flex md:border-b-0 md:align-top md:p-4'
        )}
      >
        <div className="grow flex justify-between items-center md:align-top md:block">
          <div>
            <div className="font-semibold text-2xs">
              Package:{' '}
              <InlineLink to={packageLink} className="mr-4" data-testid="package-detail-link">
                {pkg?.id}
              </InlineLink>
            </div>
            <div className="text-2xs">Customer Order: {pkg?.orderId}</div>
            {showFacilityName && !fetchingFacilities && (
              <div className="text-2xs">
                <PackageTag
                  packageType={
                    facilityName === 'Unassigned'
                      ? PackageType.GENERIC_LIGHT_GRAY
                      : PackageType.GENERIC_WHITE_ON_BLACK
                  }
                  label={facilityName}
                />
              </div>
            )}
            {showFacilityName && fetchingFacilities && <Skeleton />}
            {showLinkToGbmc && (
              <div className="pt-1">
                <a
                  className="font-semibold text-gb-blue-600 hover:text-gb-blue-800"
                  data-testid="gbmc-link"
                  href={`${gbmcSuborderLink( pkg.id )}`}
                >
                  View in GBMC
                </a>
              </div>
            )}
          </div>
          <div className="self-start">
            {fetchingOrderTypes || fetchingCorporateOrders ? (
              <Skeleton />
            ) : (
              <PackageTagList
                packageId={pkg?.id}
                isInline
                isCorporateOrder={pkg.isCorporateOrder}
                isInStoreOrder={pkg.isInStoreOrder}
                isReshipment={pkg.isReshipment}
                isSubscription={pkg.isSubscription}
                isWhitelabel={pkg.isWhitelabel}
                shipsToCountry={pkg.deliveryCountry}
                corporateOrderCompanyName={pkg.corporateOrderCompanyName}
                useTooltipOnMobile
              />
            )}
          </div>
          <div className="h-8 self-start">
            <button
              type="button"
              className="h-full w-8 flex items-center justify-center"
              onClick={() => {
                setIsOpen( !isOpen )
              }}
            >
              <span className="sr-only">Toggle package details</span>
              <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} className="md:hidden" />
            </button>
          </div>
        </div>
      </PackageListTableCell>
      <PackageListTableCell className="px-4 pt-2 pb-0">
        <ManifestPackageContents
          lineItemsClasses="border-inherit border-b-2 pb-2 text-xs md:border-b-0"
          productOptionsClasses="text-2xs"
          lineItems={pkg?.lineItems.map(( li ) => ({
            ...li,
            quantity: li.quantityOrWeight,
            name: li.product?.name || li.name,
            productOptions: productOptions
              ?.find(( po ) => po.id === li.id )
              ?.customerChoices.map(( v ) => v.name ),
          }))}
          fetchingProductOptions={fetchingProductOptions}
        />
      </PackageListTableCell>
      <PackageListTableCell className={classNames( cellClasses, 'px-4 pt-2 pb-0' )}>
        <div className="border-inherit border-b-2 pb-2 md:border-b-0 md:max-w-[100px] xl:max-w-[150px]">
          {pkg?.deliveryCompany && (
            <span className="pr-1 mr-1 border-r border-solid border-black leading-none md:block md:border-0 font-semibold text-gb-gray-800 mb-1">
              {pkg?.deliveryCompany}
            </span>
          )}
          <span className="pr-1 mr-1 border-r border-solid border-black leading-none md:block md:border-0">
            {pkg?.deliveryFirstName} {pkg?.deliveryLastName}
          </span>
          {pkg?.deliveryCity}, {pkg?.deliveryState}
          <div className={mergeClassNames( pkgContentsCellClasses, 'px-0 pt-0 md:block' )}>
            {showPurchaserInfo &&
              ( fetchingPurchaserInfo ? (
                <Skeleton />
              ) : (
                ( pkg.purchaserName || pkg.purchaserEmail || pkg.purchaserPhone ) && (
                  <div className="mt-4" data-testid={`purchaser-${pkg.id}`}>
                    <div className="break-words">
                      <div className="font-semibold text-xs">Purchaser:</div>
                      <div>{pkg.purchaserName}</div>
                      <div>{pkg.purchaserEmail}</div>
                      <div>{pkg.purchaserPhone}</div>
                    </div>
                  </div>
                )
              ))}
          </div>
        </div>
      </PackageListTableCell>

      <PackageListTableCell
        className={classNames({ 'pt-0': !prettyCarrier }, pkgContentsCellClasses, 'pb-0' )}
      >
        {prettyCarrier && (
          <div className="border-inherit border-b-2 pb-2 md:border-b-0">
            <span className="font-semibold pr-1 mr-1 border-r border-solid border-black leading-none md:block md:border-0">
              {prettyCarrier?.carrier}
            </span>
            {trackingLink ? (
              <InlineLink
                href={trackingLink}
                target="_blank"
                rel="noreferrer"
                data-testid="package-list-row-tracking-link"
                className="pr-1 mr-1 mt-0.5 border-r border-solid leading-none md:block md:border-0"
              >
                {prettyCarrier?.service}
              </InlineLink>
            ) : (
              <span>{prettyCarrier?.service}</span>
            )}
          </div>
        )}
      </PackageListTableCell>
      <PackageListTableCell className="px-4 py-2 relative">
        <div className="flex flex-col gap-y-2" data-testid={`package-${pkg.id}-order-status`}>
          <PackageStatus
            labelHasBeenGenerated={
              !!pkg?.currentShippingLabel && !!pkg?.currentShippingLabel.trackingCode!
            }
            isCanceled={isCanceled}
            printLink={printLink}
            labelIsPending={!pkg?.currentShippingLabel}
            labelPrintedAt={
              pkg.currentShippingLabel?.printedAt &&
              stringAsDate( pkg.currentShippingLabel?.printedAt )
            }
            labelDiscardedAt={
              pkg.currentShippingLabel?.discardedAt &&
              stringAsDate( pkg.currentShippingLabel?.discardedAt )
            }
            fulfillmentSheetPrintedAt={
              pkg.packingSheetPrintedAt && stringAsDate( pkg.packingSheetPrintedAt )
            }
            reprintLink={pkg?.labelNeedsReprint ? reprintLink : undefined}
            discardLink={pkg.labelNeedsDiscard ? discardLink : undefined}
            showActionLink={showActionLink}
          />
        </div>
      </PackageListTableCell>
    </tr>
  )
}

export default PackageListTableRow

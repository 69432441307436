import { FC, PropsWithChildren } from 'react'
import mergeClassNames from 'src/utils/helpers/mergeClassNames'

interface ICard {
  /**
   * Color for background of card
   */
  color?: 'green' | 'blue' | 'white'

  /**
   * Extra classes to add to this element's container, which also contains the border and padding.
   */
  className?: string
}

const Card: FC<PropsWithChildren<ICard>> = ({ color = 'white', children, className }) => (
  <div
    className={mergeClassNames(
      {
        'bg-gradient-to-bl from-green-200 to-white border-green-200': color === 'green',
        'bg-gradient-to-bl from-blue-200 to-white border-blue-200': color === 'blue',
        'bg-white border-gb-gray-400': color === 'white',
      },
      'border-2 p-4 rounded shadow-lg',
      className
    )}
  >
    {children}
  </div>
)

export default Card

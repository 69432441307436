query getMdashAccountProducts($id: ID, $forGuac: Boolean) {
  mdashAccount(id: $id) {
    __typename
    id
    products(forGuac: $forGuac) {
      __typename
      id
      name
      priceInCents
      isAvailable
      isSoldOut
      isWhitelabel
      sku
      variances {
        __typename
        id
        name
        options {
          __typename
          id
          name
          priceAdjInCents
        }
      }
    }
  }
}

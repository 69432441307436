import getManifestOrderTypeAggregates from 'src/graphql/queries/getManifestOrderTypeAggregates.graphql'
import {
  IGetManifestOrderTypeAggregatesQuery,
  IGetManifestOrderTypeAggregatesQueryVariables,
} from 'src/graphql/queries/getManifestOrderTypeAggregates.types'
import { useQuery } from 'urql'
import { IGetAggregateInput } from '../types/IGetAggregateInput'
import useAppParams from './useAppParams'

export const useGetManifestOrderTypeAggregate = ({ variables }: IGetAggregateInput ) => {
  const { mdashAccountId, pauseForFacility } = useAppParams()
  const [{ error, data, fetching }, refetch ] = useQuery<
    IGetManifestOrderTypeAggregatesQuery,
    IGetManifestOrderTypeAggregatesQueryVariables
  >({
    query: getManifestOrderTypeAggregates,
    variables,
    requestPolicy: 'cache-and-network',
    pause: Number( mdashAccountId || 0 ) < 1 || pauseForFacility,
  })

  return { error, data, fetching, refetch }
}

query getCarrierInfo($mdashAccountId: ID, $toShipOn: String) {
  manifestCarrierInfo(mdashAccountId: $mdashAccountId, toShipOn: $toShipOn) {
    airPickupHours
    airReferenceCode
    carrierName
    groundPickupHours
    groundReferenceCode
    airPhoneNumbers
    groundPhoneNumbers
  }
}
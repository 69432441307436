query getManifestCalendar(
  $toShipOn: String!, 
  $mdashAccountId: ID, 
  $interval: ManifestCalendarInterval
) {
  manifestCalendar(
    toShipOn: $toShipOn, 
    mdashAccountId: $mdashAccountId, 
    interval: $interval
  ) {
    toShipOn
    toShipCount
  }
}

import { FC, useState } from 'react'
import { useMutation } from 'urql'

import Fieldset from 'src/components/01-atoms/Fieldset'
import Hideable from 'src/components/01-atoms/Hideable'
import PromoCodeEntryForm from 'src/components/02-molecules/PromoCodeEntryForm'
import addPromoCodeMutation from 'src/graphql/mutations/addPromotionalCodeToOrder.graphql'
import {
  IAddPromotionalCodeToOrderMutation,
  IAddPromotionalCodeToOrderMutationVariables,
} from 'src/graphql/mutations/addPromotionalCodeToOrder.types'
import removePromoCodeMutation from 'src/graphql/mutations/removePromotionalCodeFromOrder.graphql'
import {
  IRemovePromotionalCodeFromOrderMutation,
  IRemovePromotionalCodeFromOrderMutationVariables,
} from 'src/graphql/mutations/removePromotionalCodeFromOrder.types'
import useCheckoutParams from 'src/utils/hooks/useCheckoutParams'
import Alert from 'src/components/01-atoms/Alert'
import useSet from 'src/utils/hooks/useSet'

interface IPromoCodeForm {
  /**
   * The promo code applied to the order
   */
  appliedPromoCode?: string

  /**
   * A list of gift card codes applied to the order.
   */
  appliedGiftCardCodes?: string[]
}

const PromoCodeGiftCardEntry: FC<IPromoCodeForm> = ({
  appliedPromoCode = '',
  appliedGiftCardCodes = [],
}) => {
  const { orderId } = useCheckoutParams()
  const appliedCodes = [ ...appliedGiftCardCodes ]
  if ( appliedPromoCode ) appliedCodes.push( appliedPromoCode )

  const { set: codes, add, remove } = useSet( new Set( appliedCodes ))
  const [ error, setError ] = useState<string>()

  const [ addResponse, addPromoCodeGiftCard ] = useMutation<
    IAddPromotionalCodeToOrderMutation,
    IAddPromotionalCodeToOrderMutationVariables
  >( addPromoCodeMutation )
  const [ removeResponse, removePromoCodeGiftCard ] = useMutation<
    IRemovePromotionalCodeFromOrderMutation,
    IRemovePromotionalCodeFromOrderMutationVariables
  >( removePromoCodeMutation )

  const addCodeToOrder = ( code: string ) => {
    addPromoCodeGiftCard({ orderId, code })
      .then(( response ) => {
        if (
          response.data?.addPromotionalCodeToOrder?.errors &&
          response.data.addPromotionalCodeToOrder.errors.length > 0
        ) {
          return
        }
        add( code )
      })
      .catch(( error ) => setError( error.message ))
  }

  const removeCodeFromOrder = ( code: string ) => {
    removePromoCodeGiftCard({ orderId, code })
      .then(( response ) => {
        if (
          response.data?.removePromotionalCodeFromOrder?.errors &&
          response.data.removePromotionalCodeFromOrder.errors.length > 0
        ) {
          return
        }
        remove( code )
      })
      .catch(( error ) => setError( error.message ))
  }

  const responseErrors = [
    ...( addResponse.data?.addPromotionalCodeToOrder?.errors ?? []),
    ...( removeResponse.data?.removePromotionalCodeFromOrder?.errors ?? []),
  ]

  return (
    <Hideable
      defaultOpen={!!appliedPromoCode || appliedGiftCardCodes.length > 0}
      toggleText="+ Add Promo Code or Gift Card"
    >
      <Fieldset disabled={addResponse.fetching || removeResponse.fetching}>
        {error && (
          <Alert type="error" className="mb-4">
            {error}
          </Alert>
        )}
        <PromoCodeEntryForm
          inputError={responseErrors.join( ' ' )}
          appliedCodes={Array.from( codes )}
          handleAdd={addCodeToOrder}
          handleRemove={removeCodeFromOrder}
        />
      </Fieldset>
    </Hideable>
  )
}

export default PromoCodeGiftCardEntry

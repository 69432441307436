/* eslint-disable @typescript-eslint/no-unused-vars */
import { addWeeks, parseISO } from 'date-fns'
import { keyBy } from 'lodash'
import { FC, useCallback, useState } from 'react'
import Container from 'src/components/01-atoms/Container'
import TextInput from 'src/components/01-atoms/TextInput'
import CalendarNav from 'src/components/02-molecules/CalendarNav'
import { IPantryItemLimitUpdatePayload } from 'src/components/02-molecules/PantryItemLimitCell'
import PantryItemLimitEditor, {
  TPantryItemCell,
} from 'src/components/03-organisms/PantryItemLimitEditor'
import updatePantryItemLimitMutation from 'src/graphql/mutations/updatePantryItemLimit.graphql'
import {
  IUpdatePantryItemLimitMutation,
  IUpdatePantryItemLimitMutationVariables,
} from 'src/graphql/mutations/updatePantryItemLimit.types'
import { dateForManifest } from 'src/utils/helpers/date'
import useAppParams from 'src/utils/hooks/useAppParams'
import { useGetPantryItemLimits } from 'src/utils/hooks/useGetPantryItemLimits'
import { useMutation } from 'urql'

const PantryItemLimit: FC = () => {
  const { toShipOn, makeLinkUrls, mdashAccountId } = useAppParams()
  const prevDate = addWeeks( toShipOn, -1 )
  const nextDate = addWeeks( toShipOn, 1 )
  const [ filterQuery, setFilterQuery ] = useState( '' )
  const [ affectedCell, setAffectedCell ] = useState<TPantryItemCell | null>( null )

  const { pantryItemLimits, fetching } = useGetPantryItemLimits({
    toShipOn: dateForManifest( toShipOn ),
  })

  const filteredPantryItemLimits = pantryItemLimits
    .filter(( x ) =>
      filterQuery.trim().length === 0
        ? x
        : x.pantryItemName.toLowerCase().includes( filterQuery.trim().toLowerCase())
    )
    .map(( x ) => ({ ...x, toShipOn: parseISO( x.toShipOn ) }))

  const uniqueFileteredPantryItems = Object.keys(
    keyBy( filteredPantryItemLimits, ( x ) => x.pantryItemId )
  )
  const uniquePantryItems = Object.keys( keyBy( pantryItemLimits, ( x ) => x.pantryItemId ))

  const [ updatePantryItemLimitResult, updatePantryItemLimit ] = useMutation<
    IUpdatePantryItemLimitMutation,
    IUpdatePantryItemLimitMutationVariables
  >( updatePantryItemLimitMutation )

  const handleChange = useCallback(
    ( payload: IPantryItemLimitUpdatePayload ) => {
      if ( !mdashAccountId ) return

      const { limit, toShipOn, pantryItemId } = payload
      setAffectedCell({
        pantryItemId,
        toShipOn,
      })

      // NOTE: Until we switch to Normalized Caching, this mutation
      // will trigger aggressive invalidation of the entire PantryItemLimit cache
      updatePantryItemLimit({ limit, toShipOn, pantryItemId, mdashAccountId })
    },
    [ mdashAccountId, updatePantryItemLimit, setAffectedCell ]
  )

  return (
    <Container className="py-6">
      <div className="flex w-full justify-between items-center mb-6">
        <div className="flex items-center">
          <TextInput
            outline
            placeholder="Filter Subproducts..."
            onChange={( x ) => setFilterQuery( x.target.value )}
          />
          <div className="pl-2 font-semibold">
            {filterQuery.trim().length > 0 && pantryItemLimits.length > 0
              ? `Showing ${uniqueFileteredPantryItems.length} out of ${uniquePantryItems.length} Pantry Items`
              : `${uniquePantryItems.length} Subproducts Found`}
          </div>
        </div>

        <CalendarNav
          prevLink={{
            pathname: makeLinkUrls( dateForManifest( prevDate )).subproducts,
          }}
          todayLink={{
            pathname: makeLinkUrls( dateForManifest( new Date())).subproducts,
          }}
          nextLink={{
            pathname: makeLinkUrls( dateForManifest( nextDate )).subproducts,
          }}
          className="justify-end"
        />
      </div>
      <PantryItemLimitEditor
        anchorDate={toShipOn}
        pantryItemLimits={filteredPantryItemLimits}
        affectedCell={affectedCell}
        error={updatePantryItemLimitResult.data?.updatePantryItemLimit?.errors.join( ', ' )}
        isUpdating={updatePantryItemLimitResult.fetching}
        isUpdateSuccessful={
          updatePantryItemLimitResult.data?.updatePantryItemLimit?.errors.length === 0
        }
        isFetching={fetching}
        onChange={handleChange}
      />
    </Container>
  )
}

export default PantryItemLimit

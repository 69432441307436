query getManifestFacilities($toShipOn: String!, $mdashAccountId: ID, $packageIds: [ID!]) {
  manifestPackages(toShipOn: $toShipOn, mdashAccountId: $mdashAccountId, packageIds: $packageIds) {
    facilities {
      id
      pickUpLocation {
        id
        name
      }
      shippingFacility {
        id
        name
      }
    }
  }
}

const host = () => {
  switch ( import.meta.env.VITE_ENVIRONMENT ) {
    case 'production':
      return '//goldbelly.com'
    case 'development':
      return '//goldbelly.localhost'
    default:
      return '//goldbelly-staging.com'
  }
}

export const gbmcLink = ( url: string ) => [ host(), url ].join( '/' )

export const gbmcSuborderLink = ( suborderId: string ) => gbmcLink( `x/gbmc/suborders/${suborderId}` )

export const gbmcOrderLink = ( orderId: string ) => gbmcLink( `x/gbmc/orders/${orderId}` )

export const gbmcCustomerLink = ( customerId: string ) => gbmcLink( `x/gbmc/customers/${customerId}` )

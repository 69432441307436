import { FC } from 'react'
import classNames from 'classnames'

import MotionSafeImage from 'src/components/01-atoms/MotionSafeImage'
import { LogoAdmin } from '../Svg/Icons/LogoAdmin'
import { LogoBeta } from '../Svg/Icons/LogoBeta'
import { LogoShipFoodLove } from '../Svg/Icons/LogoShipFoodLove'
import { LogoStacked } from '../Svg/Icons/LogoStacked'
import logoHalloweenGif from './logo-halloween.gif'
import logoHalloweenPng from './logo-halloween.png'
import logoGiftingGif from './logo-gifting.gif'
import logoGiftingPng from './logo-gifting.png'
import logoTurkeyGif from './logo-turkey.gif'
import logoTurkeyPng from './logo-turkey.png'

export enum LogoType {
  ADMIN = 'admin',
  BETA = 'beta',
  MERCHANT = 'merchant',
  STACKED = 'stacked',
  HALLOWEEN = 'halloween',
  THANKSGIVING = 'thanksgiving',
  GIFTING = 'gifting',
}
interface ILogoProps {
  className?: string
  type?: LogoType
  variant?: 'dark' | 'light'
}

const Logo: FC<ILogoProps> = ({ className, type = LogoType.STACKED, variant = 'dark' }) => {
  const logoTypeMap = {
    dark: 'text-gb-gray-900',
    light: 'text-white',
  }

  const classes = classNames( className, logoTypeMap[variant])

  switch ( type ) {
    case LogoType.ADMIN:
      return <LogoAdmin className={classes} data-testid="goldbelly-admin-logo" />
    case LogoType.BETA:
      return <LogoBeta className={classes} data-testid="goldbelly-beta-logo" />
    case LogoType.MERCHANT:
      return <LogoShipFoodLove className={classes} data-testid="goldbelly-merchant-logo" />
    case LogoType.HALLOWEEN:
      return (
        <MotionSafeImage
          movingImageUrl={logoHalloweenGif}
          stillImageUrl={logoHalloweenPng}
          className={classes}
          alt="Boo! Happy Halloween from the Goldbelly Product Team!"
          data-testid="goldbelly-halloween-logo"
        />
      )
    case LogoType.THANKSGIVING:
      return (
        <MotionSafeImage
          movingImageUrl={logoTurkeyGif}
          stillImageUrl={logoTurkeyPng}
          className={classes}
          alt="Happy Thanksgiving from the Goldbelly Product Team!"
          data-testid="goldbelly-thanksgiving-logo"
        />
      )
    case LogoType.GIFTING:
      return (
        <MotionSafeImage
          movingImageUrl={logoGiftingGif}
          stillImageUrl={logoGiftingPng}
          className={classes}
          alt="Happy Holidays from the Goldbelly Product Team!"
          data-testid="goldbelly-gifting-logo"
        />
      )
    case LogoType.STACKED:
    default:
      return <LogoStacked className={classes} data-testid="goldbelly-default-logo" />
  }
}

export default Logo

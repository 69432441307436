query getInvoicePromotionsReport(
    $mdashAccountId: ID!,
    $invoiceId: ID!) {
  invoicePromotionsReport(mdashAccountId: $mdashAccountId, invoiceId: $invoiceId) {
    description
    id
    orderId
    packageId
    promoCode
    totalAdjustment
  }
}

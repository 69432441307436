import { FC, PropsWithChildren } from 'react'
import mergeClassNames from 'src/utils/helpers/mergeClassNames'

interface IGridProps {
  className?: string
}

const Grid: FC<PropsWithChildren<IGridProps>> = ({ className, children }) => (
  <div className={mergeClassNames( 'grid grid-cols-12 auto-rows-auto gap-4', className )}>
    {children}
  </div>
)

export default Grid

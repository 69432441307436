import { FC, useMemo } from 'react'
import { useQuery } from 'urql'
import Skeleton from 'react-loading-skeleton'
import { useParams } from 'react-router'

import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import Table from 'src/components/02-molecules/Table'
import StatementPageHeader from 'src/components/02-molecules/StatementPageHeader'
import SectionCard from 'src/components/01-atoms/SectionCard'
import USDCellValue from 'src/components/01-atoms/USDCellValue'

import MDashHead from 'src/pages/elements/MDashHead'
import useAppParams from 'src/utils/hooks/useAppParams'
import { gbmcOrderLink } from 'src/utils/gbmcLink'
import { dateForManifest, stringAsDate } from 'src/utils/helpers/date'
import useMakeCSVDownloadButtonProps from 'src/utils/hooks/useMakeCSVDownloadButtonProps'

import getInvoiceRefundsReport from 'src/graphql/queries/getInvoiceRefundsReport.graphql'
import {
  IGetInvoiceRefundsReportQuery,
  IGetInvoiceRefundsReportQueryVariables,
} from 'src/graphql/queries/getInvoiceRefundsReport.types'

import '../../components/02-molecules/StatementsTable/styles.css'

const Refunds: FC = () => {
  const { isAdmin, mdashAccountId } = useAppParams()
  const { statementId } = useParams()
  const columnWidths = [
    {},
    {},
    { className: 'min-w-32' },
    { className: 'min-w-32' },
    {},
    {},
    {},
    {},
    {},
  ]

  const tableCellClasses = 'flex gap-x-4 first-of-type:pt-4 pb-4 pl-4 pr-4 lg:p-4 lg:table-cell '
  const [{ fetching, error, data }] = useQuery<
    IGetInvoiceRefundsReportQuery,
    IGetInvoiceRefundsReportQueryVariables
  >({
    query: getInvoiceRefundsReport,
    variables: {
      mdashAccountId: mdashAccountId ?? '',
      invoiceId: statementId ?? '',
    },
    requestPolicy: 'cache-and-network',
  })

  const refunds = data?.invoiceRefundsReport?.map(({ createdAt, ...rest }) => ({
    refundDate: dateForManifest( stringAsDate( createdAt )),
    ...rest,
  }))

  const csvData = useMemo(
    () =>
      refunds?.map(( refund ) => {
        const { lineItems, refundsShipping, ...rest } = refund
        const productsArray = lineItems?.map(( lineItem ) => lineItem.name )
        if ( refundsShipping ) productsArray?.push( 'Shipping Fee' )
        const products = productsArray?.join( '\n' )
        return { products, ...rest }
      }),
    [ refunds ]
  )
  const columnHeaders = [
    { key: 'packageId', displayLabel: 'Package ID' },
    { key: 'orderId', displayLabel: 'Customer Order' },
    { key: 'refundDate', displayLabel: 'Refund Date' },
    { key: 'shippedOn', displayLabel: 'Ship Date' },
    { key: 'responsibleParty', displayLabel: 'Culprit' },
    { key: 'reason', displayLabel: 'Reason' },
    { key: 'comment', displayLabel: 'Comment' },
    { key: 'products', displayLabel: 'Products' },
    { key: 'totalAdjustment', displayLabel: 'Total Adjustment' },
  ]
  const downloadButtonProps = useMakeCSVDownloadButtonProps({
    filename: `statement-${statementId}-refunds`,
    columnHeaders,
    csvData,
    fetching,
  })

  const tableHeaders = columnHeaders.map(( header ) => ({ name: header.displayLabel }))

  return (
    <div className="flex grow flex-col">
      <MDashHead pageTitle="Statements: Refunds" />

      <Container className="pt-6">
        <StatementPageHeader
          title="Refunds"
          mdashAccountId={mdashAccountId}
          total={refunds?.length}
          statementId={statementId}
          downloadButtonProps={downloadButtonProps}
        />

        {fetching && <Skeleton className="mt-4.5" height="80vh" />}
        {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}
        {refunds && (
          <SectionCard className="col-span-12 mb-8">
            <Table
              columnWidths={columnWidths}
              tableClasses="statements"
              headers={tableHeaders}
              borderRule="headers-only"
            >
              {refunds.map(
                (
                  {
                    comment,
                    id,
                    refundDate,
                    packageId,
                    orderId,
                    shippedOn,
                    reason,
                    responsibleParty,
                    refundsShipping,
                    totalAdjustment,
                    lineItems,
                  },
                  index
                ) => (
                  <tr
                    key={`missed-shipment-${id}`}
                    className="flex flex-col even:bg-gb-gray-50 odd:bg-white lg:table-row"
                  >
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[0].name}</div>
                      {packageId}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[1].name}</div>
                      {orderId}
                      {isAdmin && (
                        <a
                          className="block text-2xs text-gb-blue-600 hover:text-gb-blue-700 transition-colors"
                          href={gbmcOrderLink( orderId ?? '' )}
                          target="_blank"
                          rel="noreferrer"
                        >
                          VIEW IN GBMC
                        </a>
                      )}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[2].name}</div>
                      {refundDate}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[3].name}</div>
                      {shippedOn}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[4].name}</div>
                      {responsibleParty}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[5].name}</div>
                      {reason}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[6].name}</div>
                      {comment}
                    </td>
                    <td className={tableCellClasses}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[7].name}</div>
                      <ul className="text-xs list-disc flex flex-col gap-2">
                        {lineItems &&
                          lineItems.map(( item ) => <li key={`lineitem-${item}`}>{item.name}</li> )}
                        {refundsShipping && <li key={`${id}-shipping`}>Shipping Fee</li>}
                      </ul>
                    </td>
                    <td className={tableCellClasses} data-testid={`total-adjustment-${index}`}>
                      <div className="font-bold w-1/3 lg:hidden">{tableHeaders[8].name}</div>
                      <USDCellValue amount={totalAdjustment} />
                    </td>
                  </tr>
                )
              )}
            </Table>
          </SectionCard>
        )}
      </Container>
    </div>
  )
}
export default Refunds

query metabaseReport(
  $id: ID!
  $mdashAccountId: ID!
  $metabaseEnv: String!
  $merchantId: ID
  $startDate: String
  $endDate: String
) {
  metabaseReport(
    id: $id
    mdashAccountId: $mdashAccountId
    metabaseEnv: $metabaseEnv
    startDate: $startDate
    endDate: $endDate
    merchantId: $merchantId
  ) {
    id
    name
    url
    enableDateFilters
    enableMerchantsFilter
    startDate
    endDate
  }
}

mutation updateDeliveryDate($orderId: ID!, $packageId: ID!, $deliveryDate: String!) {
  updateDeliveryDate(
    input: { orderId: $orderId, packageId: $packageId, deliveryDate: $deliveryDate }
  ) {
    errors
    order {
      __typename
      id
      packages {
        __typename
        id
        cartItems {
          __typename
          id
          product {
            __typename
            id
            name
            sku
            priceInCents
          }
          selectedProductOptions {
            productOptionId
            productOption {
              id
              name
            }
            varianceId
            variance {
              id
              name
            }
          }
        }
        requestedDeliveryOn
        shippingCalendarData {
          requestedDeliveryOn
          rateInCents
          isSoldOut
        }
      }
    }
  }
}

mutation removeCartItem($cartItemId: ID!) {
  removeCartItem(input: { cartItemId: $cartItemId }) {
    errors
    cartItem {
      __typename
      id
      product {
        __typename
        id
        name
        priceInCents
        variances {
          id
          name
          options {
            id
            name
            priceAdjInCents
          }
        }
      }
      quantity
      selectedProductOptions {
        productOptionId
        varianceId
      }
      specialInstructions
      subtotalInCents
      unitPriceInCents
      weight
    }
  }
}

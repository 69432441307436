query getPurchaserAccount($id: ID!, $mdashAccountId: ID) {
  purchaserAccount(id: $id, mdashAccountId: $mdashAccountId) {
    id
    firstName
    lastName
    isGuestAccount
    email
    phone
    numberOfTotalOrders
    numberOfGiftOrders
    totalSpentInCents
    aovInCents
    totalRewardPoints
    createdAt
    firstPurchaseAt
    lastPurchaseAt
    addresses {
      id
      firstName
      lastName
      company
      street1
      street2
      city
      state
      postalCode
      country
      email
      phone
      isDefault
    }
  }
}
import classNames from 'classnames'
import { FC } from 'react'
import styles from './styles.module.css'
import { DryIcePlaceholder } from '../Svg/DryIcePlaceholder'

interface IPlaceTextProps {
  className?: string
}

const PlaceText: FC<IPlaceTextProps> = ({ className }) => (
  <div className={classNames( 'absolute font-bold left-0 w-full text-center', className )}>
    <div className="inline-block bg-white px-2 text-2xs">Place Sticker Here</div>
  </div>
)

interface IDryIceStickerProps {
  /**
   * The package ID.
   */
  packageId: string

  /**
   * The weight of the dry ice in kilograms.
   */
  weightInKg: number

  /**
   * Whether or not to use all black colors (usually for thermal printing where everything is either black or white).
   */
  variant?: 'black' | 'grayscale'
}

const DryIceStickerPlaceholder: FC<IDryIceStickerProps> = ({
  packageId,
  weightInKg,
  variant = 'grayscale',
}) => {
  const placeTextColor = {
    'text-black': variant === 'black',
    'text-gb-gray-700': variant === 'grayscale',
  }

  return (
    <div className="bg-white py-3" data-testid="dry-ice-sticker-placeholder">
      <div
        className={classNames(
          'bg-white relative aspect-square text-black',
          variant === 'black' ? styles.prettyDashedBorderBlack : styles.prettyDashedBorder
        )}
      >
        <PlaceText className={classNames( '-top-3 mt-px', placeTextColor )} />
        <div className="h-full w-2/3 mx-auto center text-center flex-col">
          <DryIcePlaceholder
            className={classNames( 'mx-auto', placeTextColor, {
              'w-1/2 -mb-8 text-black': variant === 'black',
              'w-2/3 -mb-12 text-gb-gray-400': variant === 'grayscale',
            })}
          />
          <div
            className={classNames( 'flex flex-col gap-5 pt-1', {
              'bg-white': variant === 'black',
              'bg-white/50': variant === 'grayscale',
            })}
          >
            <p className="font-bold leading-tight">STICK INTERNATIONAL UPS DRY ICE LABEL HERE!</p>
            <p className="leading-tight text-sm">
              Package <span className="font-bold">#{packageId}</span>
            </p>
            <p className="font-bold leading-tight text-sm">
              Remember to hand write &ldquo;<span data-testid="dry-ice-weight">{weightInKg}</span>
              &rdquo; kg in the weight section at the bottom right of the label.
            </p>
          </div>
        </div>
        <PlaceText className={classNames( '-bottom-3 mb-px', placeTextColor )} />
      </div>
    </div>
  )
}

export default DryIceStickerPlaceholder

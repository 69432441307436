query getOrderShippingInfo($orderId: ID!, $mdashAccountId: ID) {
  order(id: $orderId, mdashAccountId: $mdashAccountId) {
    __typename
    id
    isValidShippingAddress
    deliveryCity
    deliveryCompany
    deliveryFirstName
    deliveryLastName
    deliveryPhone
    deliveryPostalCode
    deliveryState
    deliveryStreet1
    deliveryStreet2
    recipientEmails
  }
}

query searchPackages (
  $query: String!
  $mdashAccountId: ID
) {
  searchPackages (
    query: $query
    mdashAccountId: $mdashAccountId
  ) {
    id
    orderId
    mdashAccountId
    deliveryFullName
    deliveryCompany
    purchaserName
    toShipOn
    gbmcState
  }
}

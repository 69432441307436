import { useQuery } from 'urql'
import getDeliveryStatesQuery from 'src/graphql/queries/getDeliveryStates.graphql'
import {
  IGetDeliveryStatesQuery,
  IGetDeliveryStatesQueryVariables,
} from 'src/graphql/queries/getDeliveryStates.types'

const useGetDeliveryStates = () => {
  const [{ error, data, fetching }] = useQuery<
    IGetDeliveryStatesQuery,
    IGetDeliveryStatesQueryVariables
  >({
    query: getDeliveryStatesQuery,
  })

  return { fetching, error, data }
}

export default useGetDeliveryStates

mutation updateGiftMessage($orderId: ID!, $giftMessage: String, $giftMessageFrom: String) {
  updateGiftMessage(input: { orderId: $orderId, body: $giftMessage, from: $giftMessageFrom }) {
    order {
      __typename
      id
      giftMessage
      giftMessageFrom
    }
    errors
  }
}

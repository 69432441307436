import { useEffect, useState } from 'react'
import { FieldValues, useFormContext } from 'react-hook-form'

import Fieldset from 'src/components/01-atoms/Fieldset'
import SectionCard from 'src/components/01-atoms/SectionCard'
import PurchaserInfoForm from 'src/components/02-molecules/PurchaserInfoForm'

import useManageOrderPartial from 'src/utils/hooks/useManageOrderPartial'

import updatePurchaserInfoMutation from 'src/graphql/mutations/updatePurchaserInfo.graphql'
import {
  IUpdatePurchaserInfoMutation,
  IUpdatePurchaserInfoMutationVariables,
} from 'src/graphql/mutations/updatePurchaserInfo.types'
import AlertList from 'src/components/02-molecules/AlertList'
import { getFieldErrors } from 'src/utils/helpers/forms'
import getPurchaserInfoQuery from 'src/graphql/queries/getOrderPurchaserInfo.graphql'
import { IGetOrderPurchaserInfoQuery } from 'src/graphql/queries/getOrderPurchaserInfo.types'

export type PurchaserInfoOnly = Omit<IUpdatePurchaserInfoMutationVariables, 'orderId'>

const PurchaserInfo = () => {
  const [ fetched, setFetched ] = useState<boolean>( false )
  const { data, fetching, errors, update } = useManageOrderPartial<
    IGetOrderPurchaserInfoQuery,
    IUpdatePurchaserInfoMutation,
    PurchaserInfoOnly
  >( getPurchaserInfoQuery, updatePurchaserInfoMutation, fetched )
  const fieldNames = [ 'purchaserFirstName', 'purchaserLastName', 'purchaserPhone', 'purchaserEmail' ]
  const { register, getValues, setValue, formState } = useFormContext()

  useEffect(() => {
    if ( !data || fetching ) return
    setValue( 'purchaserFirstName', data.order.purchaserFirstName )
    setValue( 'purchaserLastName', data.order.purchaserLastName )
    setValue( 'purchaserPhone', data.order.purchaserPhone )
    setValue( 'purchaserEmail', data.order.purchaserEmail )
    setFetched( true )
  }, [ data ])

  const onSubmit = () => {
    const values: FieldValues = getValues()
    update({
      purchaserFirstName: values.purchaserFirstName,
      purchaserLastName: values.purchaserLastName,
      purchaserEmail: values.purchaserEmail,
      purchaserPhone: values.purchaserPhone,
    } as PurchaserInfoOnly )
  }

  const validationErrors = getFieldErrors( formState, fieldNames )
  const hasValidationErrors = Object.values( validationErrors ).length > 0

  return (
    <Fieldset onBlur={onSubmit}>
      <SectionCard
        size="medium"
        title="Purchaser Information"
        error={errors.length > 0 || hasValidationErrors}
      >
        {errors.length > 0 && <AlertList alerts={errors} type="error" className="mb-4" />}
        <PurchaserInfoForm
          fieldProps={{
            firstName: {
              error: validationErrors.purchaserFirstName,
              ...register( 'purchaserFirstName', { required: true }),
            },
            lastName: {
              error: validationErrors.purchaserLastName,
              ...register( 'purchaserLastName', { required: true }),
            },
            phone: {
              error: validationErrors.purchaserPhone,
              ...register( 'purchaserPhone', { required: true }),
            },
            email: {
              error: validationErrors.purchaserEmail,
              ...register( 'purchaserEmail', { required: true }),
            },
          }}
        />
      </SectionCard>
    </Fieldset>
  )
}

export default PurchaserInfo

import { FC, useCallback, useState, useEffect } from 'react'
import classNames from 'classnames'
import { CalendarTooltipProps, ResponsiveCalendar } from '@nivo/calendar'
import { useQuery } from 'urql'
import userAdoptions from 'src/graphql/queries/getMdashAccountUserAdoptions.graphql'
import {
  IGetMdashAccountUserAdoptionsQuery,
  IGetMdashAccountUserAdoptionsQueryVariables,
} from 'src/graphql/queries/getMdashAccountUserAdoptions.types'
import Skeleton from 'react-loading-skeleton'
import { eachYearOfInterval, format, parseISO } from 'date-fns'
import MerchantSearch from 'src/pages/elements/MerchantSearch'
import Legend from './Legend'
import Header from './Header'

const accessType = ( value: number ) => {
  switch ( value ) {
    case 1:
      return 'MD2'
    case 2:
      return 'MDX'
    default:
      return 'Both'
  }
}

const UserAdoption: FC = () => {
  const intervalIterator = eachYearOfInterval({ start: new Date( 2022, 0, 1 ), end: new Date() })
  const [ calendarYear, setCalendarYear ] = useState<number | 'all'>( 'all' )
  const [ mdashAccountId, setMdashAccountId ] = useState( 0 )
  const [{ data: adoptionData, fetching: isFetching }, executeQuery ] = useQuery<
    IGetMdashAccountUserAdoptionsQuery,
    IGetMdashAccountUserAdoptionsQueryVariables
  >({
    query: userAdoptions,
    variables: { mdashAccountId: String( mdashAccountId ) },
    requestPolicy: 'cache-and-network',
    pause: Number( mdashAccountId || 0 ) < 1,
  })

  const tooltip = useCallback(
    ( data: CalendarTooltipProps ) => (
      <div className="bg-white p-2 border border-gb-gray-300">
        {format( parseISO( data.day ), 'E, M/d' )}: {accessType( Number( data.value ))}
      </div>
    ),
    []
  )

  useEffect(() => {
    window.addEventListener( 'focus', executeQuery )
  }, [])

  return (
    <div className="flex justify-center py-4">
      <div>
        <MerchantSearch setMdashAccountId={setMdashAccountId} />
        <div className="flex justify-center pt-4">
          {intervalIterator.map(( year ) => (
            <button
              type="button"
              disabled={calendarYear === year.getFullYear()}
              className={classNames(
                'px-2 border-b-4 hover:border-gb-gray-600',
                calendarYear === year.getFullYear()
                  ? 'border-gb-blue-600 hover:border-gb-blue-600 text-gb-gray-900'
                  : 'border-gb-gray-200 text-gb-gray-600'
              )}
              onClick={() => setCalendarYear( year.getFullYear())}
            >
              {year.getFullYear()}
            </button>
          ))}
          <button
            type="button"
            disabled={calendarYear === 'all'}
            className={classNames(
              'px-4 border-b-4 hover:border-gb-gray-600',
              calendarYear === 'all'
                ? 'border-gb-blue-600 hover:border-gb-blue-600 text-gb-gray-900'
                : 'border-gb-gray-200 text-gb-gray-600'
            )}
            onClick={() => setCalendarYear( 'all' )}
          >
            All
          </button>
        </div>
        <div className="mt-6 w-175">
          {isFetching ? (
            <Skeleton className="h-64" />
          ) : (
            <div className="w-full text-center">
              <Header adoptionData={adoptionData} />
              <Legend adoptionData={adoptionData} />
              {adoptionData?.mdashAccount?.merchantUserAccessLogs.map(( mual ) => (
                <div
                  key={mual.user?.id}
                  className="flex items-center border-b border-gb-gray-700 my-2"
                >
                  <div className="w-24 text-left">{mual.user?.name}</div>
                  <div className="w-full">
                    {intervalIterator
                      .filter(( x ) =>
                        calendarYear === 'all' ? x : x.getFullYear() === calendarYear
                      )
                      .map(( x ) => (
                        <div className="h-40 w-full">
                          {x.getFullYear()}
                          <ResponsiveCalendar
                            data={mual.data}
                            from={new Date( x.getFullYear(), 0, 1 )}
                            to={new Date( x.getFullYear(), 11, 31 )}
                            minValue={0}
                            maxValue={3}
                            emptyColor="#eee"
                            colors={[ '#eee', '#FC8D62', '#a6d854', '#8da0cb' ]}
                            margin={{ top: 16, right: 8, bottom: 16, left: 32 }}
                            monthBorderColor="#bbb"
                            monthBorderWidth={1}
                            dayBorderWidth={1}
                            dayBorderColor="rgb(250, 250, 250)"
                            tooltip={( data ) => tooltip( data )}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default UserAdoption

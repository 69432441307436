import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import { endOfDay, getMonth, isAfter } from 'date-fns'
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import Container from 'src/components/01-atoms/Container'
import Logo, { LogoType } from 'src/components/01-atoms/Logo'
import UserDropdown, { IUserDropdownProps } from 'src/components/02-molecules/UserDropdown'
import { IMainNavProps } from 'src/components/03-organisms/MainNav/interface'
import GlobalSearch from 'src/pages/elements/GlobalSearch'
import MobileSearch from 'src/pages/elements/MobileSearch'

import { getToday, getUSThanksgiving } from 'src/utils/helpers/date'

const GlobalHeader: FC<PropsWithChildren<IUserDropdownProps & IMainNavProps>> = ({
  children,
  isAdmin,
  linkPrefix,
  ...props
}) => {
  const [ isMobileSearchOpen, setIsMobileSearchOpen ] = useState( false )
  const location = useLocation()
  const thisMonth = getMonth( getToday())

  const merchantLogo = useMemo(() => {
    const thanksgivingEOD = endOfDay( getUSThanksgiving())
    const now = new Date()

    switch ( thisMonth ) {
      case 9:
        return LogoType.HALLOWEEN
      case 10:
        if ( !isAfter( now, thanksgivingEOD )) {
          return LogoType.THANKSGIVING
        }
      // Yes, I meant to not put a break or return here.
      // eslint-disable-next-line no-fallthrough
      case 11:
        return LogoType.GIFTING
      default:
        return LogoType.MERCHANT
    }
  }, [ thisMonth ])

  useEffect(() => {
    setIsMobileSearchOpen( false )
  }, [ location ])

  return (
    <header>
      <div
        data-testid="header-bg"
        className={classNames(
          { 'bg-gb-electric-blue-600': !isAdmin, 'bg-gb-yellow-500': isAdmin },
          'text-white py-4 md:py-0'
        )}
      >
        <Container>
          <div className="spread-h">
            <Link to={isAdmin ? '/admin/issues' : `/${linkPrefix}/manifest`}>
              <Logo
                variant={isAdmin ? 'dark' : 'light'}
                type={isAdmin ? LogoType.ADMIN : merchantLogo}
                className="h-9 md:h-12 cursor-pointer hover:opacity-90 transition-opacity"
              />
            </Link>
            <GlobalSearch />
            <div className="flex items-center">
              <div className="md:hidden">
                {isMobileSearchOpen && <MobileSearch setMobileSearchOpen={setIsMobileSearchOpen} />}
              </div>
              <FontAwesomeIcon
                onClick={() => setIsMobileSearchOpen( true )}
                icon={faMagnifyingGlass}
                className={classNames(
                  { 'text-gray-900': isAdmin, 'text-white': !isAdmin },
                  'relative h-6 hover:opacity-80 cursor-pointer mr-2 md:hidden'
                )}
              />
              <UserDropdown {...props} isAdmin={isAdmin} />
            </div>
          </div>
        </Container>
      </div>

      {children}
    </header>
  )
}

export default GlobalHeader

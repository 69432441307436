query getFacilityAutoAssignment($mdashAccountId: ID) {
  shippers(mdashAccountId: $mdashAccountId) {
    id
    name
    usesAutoAssignmentV2
    products {
      id
      name
    }
    shipperFacilities {
      id
      name
    }
    facilityAutoAssignmentRules {
      id
      productId
      deliveryState
      shipperFacilityId
      mode
    }
  }
}

query getMissedShipmentIssues($mdashAccountId: ID, $startDate: String, $endDate: String) {
  missedShipmentIssues( mdashAccountId: $mdashAccountId, startDate: $startDate, endDate: $endDate){
    adjustmentsInCents
    category
    label
    numberOfIssues
    numberOfPackages
    percentOfPackagesWithIssues
    missedShipments {
      id
      adjustmentCostInCents
      comment
      newShipOn
      orderId
      packageId
      missedShipOn
      newShipSpeed
      originalShipSpeed
      packageId
      productNames
    }
  }
}
query getInvoiceMissedShipmentsReport(
    $mdashAccountId: ID!,
    $invoiceId: ID!) {
  invoiceMissedShipmentsReport(mdashAccountId: $mdashAccountId, invoiceId: $invoiceId) {
    comment
    id
    missedShipOn
    newShipOn
    newShipSpeed
    orderId
    originalShipSpeed
    packageId
    totalAdjustment
  }
}

query getSystemAdoption (
  $aggregateBy: AccessLogEntity
) {
  accessLogs(
    aggregateBy: $aggregateBy
  ) {
    id
    data {
      x
      y
    }
  }
}
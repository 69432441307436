import { useQuery, UseQueryResponse } from 'urql'
import { add, startOfDay } from 'date-fns'

import useAppParams from 'src/utils/hooks/useAppParams'

import getManifestCalendar from 'src/graphql/queries/getManifestCalendar.graphql'
import {
  IGetManifestCalendarQuery,
  IGetManifestCalendarQueryVariables,
} from 'src/graphql/queries/getManifestCalendar.types'

import { IUseGetManifestVariables } from 'src/utils/types/GetManifestHookQueryTypes'
import { stringAsDate } from '../helpers/date'

interface IGetManifestCalendarQueryWithDates extends IGetManifestCalendarQuery {
  manifestCalendar: {
    toShipOn: Date
    toShipCount: number
  }[]
}

const useGetManifestCalendar = ({
  variables = {},
  ...useQueryOptionOverrides
}: IUseGetManifestVariables<
  IGetManifestCalendarQuery,
  IGetManifestCalendarQueryVariables
> ): UseQueryResponse<IGetManifestCalendarQueryWithDates, IGetManifestCalendarQueryVariables> => {
  const { mdashAccountId, toShipOnAsString: toShipOn, allowSaturdayShipping } = useAppParams()

  const [ response, refetch ] = useQuery<
    IGetManifestCalendarQuery,
    IGetManifestCalendarQueryVariables
  >({
    query: getManifestCalendar,
    variables: {
      mdashAccountId,
      toShipOn,
      ...variables,
    },
    requestPolicy: 'cache-and-network',
    ...useQueryOptionOverrides,
  })

  const calendarWithDateObjects = response.data
    ? response.data.manifestCalendar.map(({ toShipCount, toShipOn }) => ({
        toShipCount,
        toShipOn: startOfDay( stringAsDate( toShipOn )),
      }))
    : []

  // Backfill this until we support it from the backend.
  if ( allowSaturdayShipping ) {
    const numOfDays = calendarWithDateObjects.length
    if ( numOfDays === 5 ) {
      calendarWithDateObjects.push({
        toShipCount: 0,
        toShipOn: startOfDay( add( calendarWithDateObjects[numOfDays - 1].toShipOn, { days: 1 })),
      })
    }
  }

  return [
    { ...response, data: { ...response.data, manifestCalendar: calendarWithDateObjects }},
    refetch,
  ]
}

export default useGetManifestCalendar

query getOrderCartItems($orderId: ID!, $mdashAccountId: ID) {
  order(id: $orderId, mdashAccountId: $mdashAccountId) {
    __typename
    id
    cartItems {
      __typename
      id
      product {
        __typename
        id
        name
        priceInCents
        variances {
          id
          name
          options {
            id
            name
            priceAdjInCents
          }
        }
      }
      quantity
      selectedProductOptions {
        productOptionId
        varianceId
      }
      specialInstructions
      subtotalInCents
      unitPriceInCents
      weight
    }
  }
}

mutation markEntityAsPrinted(
  $mdashAccountId: ID!
  $toShipOn: String!
  $batchPrintActionId: ID
  $printedShippingLabelIds: [ID!]
  $printedPackingSlipForSuborderIds: [ID!]
  $undoMutation: Boolean

) {
  batchPrint(
    input: {
      mdashAccountId: $mdashAccountId
      toShipOn: $toShipOn
      batchPrintActionId: $batchPrintActionId
      printedShippingLabelIds: $printedShippingLabelIds
      printedPackingSlipForSuborderIds: $printedPackingSlipForSuborderIds
      undoMutation: $undoMutation
    }
  ) {
    batchPrintAction {
      id
      length
      fulfillmentSheetsPartiallyPrinted
      shippingLabelsPartiallyPrinted
      pickListsPartiallyPrinted
    }
    markedShippingLabels {
      id
    }
    markedPackingSlipForPackages {
      id
    }
    errors
  }
}

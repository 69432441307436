query getPurchaserOrders($id: ID!, $mdashAccountId: ID, $page: Int, $limit: Int) {
  purchaserAccount(id: $id, mdashAccountId: $mdashAccountId) {
    orders(page: $page, limit: $limit) {
      metadata {
        currentPage
        limitValue
        totalCount
        totalPages
      }
      collection {
        id
        completedByCustomerAt
        totalInCents
        giftMessage
        giftMessageFrom
      }
    }
  }
}
import { FC, useState, useEffect, useRef, useCallback, useMemo } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { pick } from 'lodash'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { useMutation } from 'urql'
import { useCounter } from 'rooks'

import MDashHead from 'src/pages/elements/MDashHead'
import Container from 'src/components/01-atoms/Container'
import LoadingBar from 'src/components/01-atoms/LoadingBar'
import Alert from 'src/components/01-atoms/Alert'
import Stepper from 'src/components/01-atoms/Stepper'
import PrintHeader from 'src/components/03-organisms/PrintHeader'
import PostPrintModal from 'src/components/03-organisms/PostPrintModal'
import PrintConfirmModal from 'src/components/03-organisms/PrintConfirmModal'
import USLetter from 'src/components/03-organisms/SlipNLabel/USLetter'

import usePrintingParams from 'src/utils/hooks/usePrintingParams'
import useAppParams from 'src/utils/hooks/useAppParams'
import useSet from 'src/utils/hooks/useSet'
import { generate } from 'src/utils/stepperInput'

import SuccessBlock from 'src/components/01-atoms/SuccessBlock'
import PrintPreviewWrapper from 'src/components/02-molecules/PrintPreviewWrapper'
import batchPrintMutation from 'src/graphql/mutations/batchPrint.graphql'
import {
  IBatchPrintMutation,
  IBatchPrintMutationVariables,
} from 'src/graphql/mutations/batchPrint.types'
import markEntityAsPrintedMutation from 'src/graphql/mutations/markEntityAsPrinted.graphql'
import {
  IMarkEntityAsPrintedMutation,
  IMarkEntityAsPrintedMutationVariables,
} from 'src/graphql/mutations/markEntityAsPrinted.types'
import { useGetCustomerChoices } from 'src/utils/hooks/useGetCustomerChoices'
import Thermal4x6 from 'src/components/03-organisms/SlipNLabel/Thermal4x6'
import { PageSize } from 'src/components/01-atoms/PrintablePage'

const itemsPerBatch = 100

const SlipNLabels: FC = () => {
  const [ searchParams ] = useSearchParams()
  const navigate = useNavigate()
  const {
    mdashAccountId,
    toShipOn,
    toShipOnAsString,
    facilityId,
    facilityType,
    makeLinkUrls,
    packageSort,
    withFacilityParams,
    displayPackageBarcode,
    displaySku,
    useDryIcePlaceholder,
    mdashAccountPermissions,
  } = useAppParams()
  const {
    packageIds,
    labelPrintStatus,
    showPrintConfirmModal,
    setShowPrintConfirmModal,
    printingIsComplete,
    setPrintingIsComplete,
    showPostPrintModal,
    setShowPostPrintModal,
    batchPrintActionId,
    setBatchPrintActionId,
    showBigStepper,
    isValidReprint,
  } = usePrintingParams({
    defaultLabelPrintStatusFilter: 'not_printed',
  })
  const { value: page, increment: nextPage } = useCounter( 0 )
  const [ modalButtonsAreDisabled, setModalButtonsAreDisabled ] = useState( false )
  const [ totalPackages, setTotalPackages ] = useState( 0 )

  const canUseThermalPrinting = !!mdashAccountPermissions?.canPrintThermalShippingLabels
  const numPackagesFromURL = packageIds.length
  const {
    set: printableLabelIds,
    remove: removeLabelId,
    addMultiple: addMultipleLabelIds,
  } = useSet<string>()
  const totalPages = Math.ceil( totalPackages / itemsPerBatch )
  const { value: numFailed, increment: incrementNumFailed, reset: resetNumFailed } = useCounter( 0 )
  const { value: numLoaded, increment: incrementNumLoaded, reset: resetNumLoaded } = useCounter( 0 )
  const [ numExpected, setNumExpected ] = useState( numPackagesFromURL )
  const [ allLoaded, setAllLoaded ] = useState( false )
  const numFailedText = pluralize( 'label', numFailed )
  const [ modalErrors, setModalErrors ] = useState<string[]>([])
  const isInitialized = useRef( false ) // hack to make sure initial mutation only run once
  const lastPage = Math.max( 0, totalPages - 1 )
  const currentOrMaxPage = Math.min( lastPage, page )

  const queryVariables = {
    mdashAccountId: String( mdashAccountId ),
    toShipOn: toShipOnAsString,
    packageIds,
    labelPrintStatus,
    limit: itemsPerBatch,
    page: currentOrMaxPage,
    batchPrintActionId,
    facilityId,
    facilityType,
    ...packageSort,
  }
  const [ result, markAsPrinted ] = useMutation<IBatchPrintMutation, IBatchPrintMutationVariables>(
    batchPrintMutation
  )

  const [ , markEntityAsPrinted ] = useMutation<
    IMarkEntityAsPrintedMutation,
    IMarkEntityAsPrintedMutationVariables
  >( markEntityAsPrintedMutation )

  const itemsThisBatch = Number(
    result.data?.batchPrint?.manifestPackages.packages?.metadata.totalCount ?? 0
  )
  const nothingToPrint = result && numFailed === itemsThisBatch

  useEffect(() => {
    setAllLoaded( false )
    resetNumLoaded()
    resetNumFailed()
    setNumExpected( numPackagesFromURL )
  }, [ page ])

  useEffect(() => {
    setAllLoaded( numExpected < 1 || numLoaded >= numExpected )
  }, [ result, numLoaded, numFailed, numExpected ])

  useEffect(() => {
    setNumExpected(
      ( numExpected ??
        result.data?.batchPrint?.manifestPackages.packages?.collection.length ??
        numPackagesFromURL ??
        0 ) - numFailed
    )
  }, [ result, numFailed ])

  useEffect(() => {
    if (
      result.fetching ||
      result.error ||
      !result.data?.batchPrint?.manifestPackages.packages?.collection
    )
      return
    setNumExpected( result.data?.batchPrint?.manifestPackages.packages?.collection.length )
  }, [ result ])

  const stepperProps = generate({
    entityCount: totalPackages,
    entityPerStep: itemsPerBatch,
    maxStepToDisplay: 5,
    currentStep: page,
  })

  const isBatch = totalPages > 1
  const isLastPage = isBatch ? page >= lastPage : true
  const labelIds = useMemo(() => {
    if ( result.fetching || result.error || !result.data ) return null

    return result.data.batchPrint?.manifestPackages.packages?.collection
      .filter(( pkg ) => pkg.currentShippingLabel && pkg.currentShippingLabel.labelImageUrl )
      .map(( pkg ) => pkg.currentShippingLabel?.id ) as string[]
  }, [ result ])
  const packageIdsFromBatchPrint = useMemo(() => {
    if ( result.fetching || result.error || !result.data ) return null

    return result.data.batchPrint?.manifestPackages.packages?.collection.map(( x ) => x.id )
  }, [ result ])

  const { data: customerChoicesData, fetching: isFetchingCustomerChoices } = useGetCustomerChoices({
    packageIds: packageIdsFromBatchPrint,
    requestPolicy: 'network-only', // don't use cache - block printing until ready
  })

  const afterPrintAction = useCallback(() => {
    markEntityAsPrinted({
      ...pick( queryVariables, [ 'mdashAccountId', 'toShipOn', 'batchPrintActionId' ]),
      printedShippingLabelIds: labelIds,
    })
    setShowPrintConfirmModal( true )
  }, [ queryVariables, printableLabelIds ])

  const alerts = []
  if ( numFailed > 0 ) {
    alerts.push( `There was an error loading ${numFailed} shipping ${numFailedText}. The ${numFailedText}${' '}
    will remain in the Not Printed queue so you can try again later.` )
  }

  useEffect(() => {
    if ( labelIds && labelIds.length > 0 ) {
      addMultipleLabelIds( labelIds )
    }
  }, [ labelIds, addMultipleLabelIds ])

  useEffect(() => {
    if ( result.fetching || result.error || !result.data ) return

    setTotalPackages( Number( result.data.batchPrint?.batchPrintAction.length ))
    setBatchPrintActionId( result.data.batchPrint?.batchPrintAction.id )
  }, [ result ])

  useEffect(() => {
    resetNumFailed()
  }, [ resetNumFailed, result ])

  useEffect(() => {
    setModalErrors([])
  }, [ showPrintConfirmModal ])

  useEffect(() => {
    if ( !isInitialized.current ) markAsPrinted( queryVariables )
    isInitialized.current = true
  }, [ markAsPrinted ])

  useEffect(() => {
    if ( !batchPrintActionId || searchParams.get( 'batch_print_action_id' )) return
    searchParams.append( 'batch_print_action_id', batchPrintActionId )
  }, [ batchPrintActionId ])

  useEffect(() => {
    window.addEventListener( 'afterprint', afterPrintAction )
    return () => window.removeEventListener( 'afterprint', afterPrintAction )
  }, [ afterPrintAction ])

  const packingSlipVersion = 2

  return (
    <>
      <MDashHead pageTitle="Print Slip & Labels" />
      <PrintHeader
        backLink={{
          pathname: makeLinkUrls().manifest,
          search: withFacilityParams(),
        }}
        postPrintAction={afterPrintAction}
        pageTitle="Print Slip & Labels"
        shipDate={toShipOn}
        totalOrders={totalPackages}
        alerts={alerts}
        printingIsComplete={printingIsComplete}
        fetching={nothingToPrint || result.fetching || !allLoaded || isFetchingCustomerChoices}
        handleOptionalPrintingActions={() => setShowPostPrintModal( true )}
      />
      <Container className="screen:p-8 screen:pt-4 print:m-0 print:p-0">
        <div className="print:hidden">
          {showBigStepper && (
            <div data-testid="discard-review-stepper">
              <Stepper
                size="large"
                className="mb-4"
                currentStep={printingIsComplete ? 2 : 1}
                steps={[
                  {
                    text: !isValidReprint ? 'Discard' : 'Review',
                  },
                  { text: 'Print' },
                ]}
              />
            </div>
          )}
          {isBatch && (
            <div className="print:hidden mb-6" data-testid="print-stepper">
              <Stepper {...stepperProps} />
            </div>
          )}
          {itemsThisBatch === 0 && !result.fetching && !printingIsComplete && (
            <Alert type="info">No printable shipping labels available for given package IDs.</Alert>
          )}
          <LoadingBar
            current={numLoaded}
            total={numExpected}
            isComplete={allLoaded}
            titleText="Labels are loading; depending on your connection speed, this may take
            a few minutes."
            className="mb-4"
            showProgress
          />
        </div>
        {printingIsComplete ? (
          <SuccessBlock className="lg:mx-36 print:hidden" />
        ) : (
          <div>
            {result && !printingIsComplete && (
              <PrintPreviewWrapper
                loading={( result.fetching && !result.data ) || !allLoaded}
                pageSize={canUseThermalPrinting ? PageSize.THERMAL_4x6 : PageSize.US_LETTER_STRICT}
                className={classNames({ 'print:hidden opacity-50': result && !result })}
              >
                {result.data?.batchPrint?.manifestPackages.packages?.collection.map(( pkg ) => {
                  if ( !pkg ) return null

                  const { merchant } = pkg.lineItems[0]
                  const isWhiteLabel =
                    pkg.currentShippingLabel &&
                    pkg.currentShippingLabel.displayWhitelabelLogoInPackingSlip &&
                    !!merchant?.packingSlipLogoImageUrl
                  const packageContents = pkg.lineItems?.map(
                    ({ id, quantityOrWeight: quantity, product, specialInstructions }) => {
                      const lineItemSubproduct = customerChoicesData?.lineItemsCustomerChoices.find(
                        ( x ) => x.id === id
                      )
                      return {
                        id,
                        quantity,
                        name: product?.name!,
                        productOptions: lineItemSubproduct?.customerChoices.map(( y ) => y.name ),
                        specialInstructions,
                        sku: displaySku
                          ? lineItemSubproduct?.merchantSkus.map(
                              ( x ) => `${Number( x.quantity ).toFixed( 1 )} × ${x.name}`
                            )
                          : null,
                      }
                    }
                  )

                  /**
                   * This is hard-coded to 2.25kg for now, because all packages on the back end currently have dry ice weight in pounds at 5.
                   * We can change this when that isn't the case anymore.
                   */
                  // const dryIceWeightInKg = Math.round((( Number( pkg.requiredDryIceWeightInPounds ?? 0 ) * 0.453592 ) + Number.EPSILON ) * 100 ) / 100
                  const dryIceWeightInKg =
                    Number( pkg.requiredDryIceWeightInPounds ?? 0 ) > 0 ? 2.25 : 0

                  const packingSlipProps = {
                    orderId: pkg.orderId,
                    packageId: pkg.id,
                    toShipOn,
                    merchantName: merchant.name,
                    whiteLabelLogoPath: isWhiteLabel ? merchant?.packingSlipLogoImageUrl : null,
                    qrCodeImgPath: pkg.qrCodeImageUrl,
                    recipientsName: `${pkg.deliveryFirstName} ${pkg.deliveryLastName}`,
                    giftMessage: pkg.giftMessage?.body,
                    sendersName: pkg.giftMessage?.from,
                    deliveryCountry: pkg.deliveryCountry,
                    dryIceWeightInKg,
                    showDryIceSticker: pkg.deliveryCountry === 'CA',
                    showDryIcePlaceholder: useDryIcePlaceholder,
                    showPackageBarcode: displayPackageBarcode,
                    packageContents,
                    // TODO - This is a quick and dirty solution for Hancock until we can set something up on the back end.
                    customInstructions:
                      isWhiteLabel && merchant.id === '559' ? (
                        <>
                          For support, please call (866)-266-1700 or email
                          solutions@hancockgourmetlobster.com
                        </>
                      ) : undefined,
                  }

                  const shippingLabelProps = {
                    labelImgPath: pkg.currentShippingLabel?.labelImageUrl,
                    handleLabelImageError: () => {
                      if ( pkg.currentShippingLabel?.id ) {
                        removeLabelId( pkg.currentShippingLabel?.id )
                      }
                      incrementNumFailed()
                    },
                    handleLabelImageLoad: incrementNumLoaded,
                  }

                  return (
                    pkg &&
                    pkg.currentShippingLabel &&
                    ( canUseThermalPrinting ? (
                      <Thermal4x6
                        key={`package-${pkg.id}-thermal-slipnlabel`}
                        {...packingSlipProps}
                        {...shippingLabelProps}
                        version={packingSlipVersion}
                      />
                    ) : (
                      <USLetter
                        key={`package-${pkg.id}-usletter-slipnlabel`}
                        {...packingSlipProps}
                        {...shippingLabelProps}
                        version={packingSlipVersion}
                      />
                    ))
                  )
                })}
              </PrintPreviewWrapper>
            )}
          </div>
        )}
      </Container>
      <PrintConfirmModal
        open={showPrintConfirmModal}
        errors={modalErrors}
        disableButtons={modalButtonsAreDisabled}
        entity={{
          fullName: 'shipping label',
          shortName: 'label',
        }}
        backButtonCallback={() => {
          setShowPrintConfirmModal( false )
          markEntityAsPrinted({
            ...pick( queryVariables, [ 'mdashAccountId', 'toShipOn', 'batchPrintActionId' ]),
            printedShippingLabelIds: labelIds,
            undoMutation: true,
          })
        }}
        continueButtonCallback={() => {
          setModalButtonsAreDisabled( true )
          markAsPrinted({
            ...queryVariables,
            page: page + 1,
          }).then(({ data }) => {
            setModalButtonsAreDisabled( false )
            if ( data?.batchPrint?.errors && data.batchPrint.errors.length > 0 ) {
              setModalErrors( data.batchPrint.errors )
            } else {
              setShowPrintConfirmModal( false )
              if ( isBatch ) nextPage()
              if ( isLastPage ) {
                setPrintingIsComplete( true )
                setShowPostPrintModal( true )
              }
            }
          })
        }}
      />
      <PostPrintModal
        open={showPostPrintModal}
        slipNLabels={{
          isCompleted: result.data?.batchPrint?.batchPrintAction.shippingLabelsPartiallyPrinted!,
          onClick: () => navigate( `${makeLinkUrls().printLabels}?${searchParams}` ),
        }}
        fulfillmentSheets={{
          isCompleted: result.data?.batchPrint?.batchPrintAction.fulfillmentSheetsPartiallyPrinted!,
          onClick: () => navigate( `${makeLinkUrls().printFulfillmentSheets}?${searchParams}` ),
        }}
        pickLists={{
          isCompleted: result.data?.batchPrint?.batchPrintAction.pickListsPartiallyPrinted!,
          onClick: () => navigate( `${makeLinkUrls().printPickList}?${searchParams}` ),
        }}
        handleClose={() => setShowPostPrintModal( false )}
      />
    </>
  )
}

export default SlipNLabels

import { FC } from 'react'
import { ISvgIconProps } from '../../types'

export const FlagCanada: FC<ISvgIconProps> = ( props ) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.1998 0.921536C8.2798 0.340568 7.1798 0 5.9998 0C4.8198 0 3.7198 0.340568 2.7998 0.921536V11.0785C3.7198 11.6594 4.8198 12 5.9998 12C7.1798 12 8.2798 11.6594 9.1998 11.0785V0.921536Z"
      fill="#F9F9F9"
    />
    <path
      d="M9.19995 0.921875V11.0788C10.88 10.0371 12 8.11386 12 5.99032C12 3.86678 10.88 1.98364 9.19995 0.921875Z"
      fill="#D90026"
    />
    <path
      d="M2.8 0.921875C1.12 1.98364 0 3.90685 0 6.01036C0 8.11386 1.12 10.0371 2.8 11.0988V0.921875Z"
      fill="#D90026"
    />
    <path
      d="M8.1801 5.93046C8.1001 5.89039 8.0801 5.81026 8.1001 5.77019L8.3001 5.04899L7.6001 5.18922C7.5801 5.18922 7.5001 5.18922 7.4801 5.04899L7.4201 4.80859L6.9401 5.36952C6.9401 5.36952 6.6201 5.71009 6.7201 5.18922L6.9201 4.08739L6.5401 4.28772C6.5201 4.28772 6.4401 4.30775 6.3401 4.10742L6.0001 3.40625L5.6401 4.06735C5.5401 4.26769 5.4601 4.24765 5.4401 4.24765L5.0601 4.04732L5.2601 5.14916C5.3601 5.67002 5.0401 5.32946 5.0401 5.32946L4.5601 4.76852L4.5001 5.00892C4.4601 5.14915 4.4001 5.14916 4.3801 5.14916L3.6801 5.00892L3.8801 5.73012C3.8801 5.79022 3.8801 5.85032 3.8001 5.89039L3.6001 6.01059C3.6001 6.01059 4.4001 6.65166 4.6601 6.87203C4.7201 6.91209 4.8401 7.03229 4.8001 7.17253L4.7001 7.45299L5.8001 7.29273C5.8601 7.29273 5.9801 7.29273 5.9601 7.47303L5.9001 8.61493H6.1001L6.0401 7.47303C6.0401 7.29273 6.1601 7.29273 6.2001 7.29273L7.3001 7.45299L7.2001 7.17253C7.1601 7.03229 7.2801 6.91209 7.3401 6.87203C7.6001 6.65166 8.4001 6.01059 8.4001 6.01059L8.1801 5.93046Z"
      fill="#D90026"
    />
  </svg>
)

mutation batchPickList(
  $mdashAccountId: ID!
  $toShipOn: String!
  $packageIds: [ID!]
  $facilityType: FacilityType
  $facilityId: ID
  $batchPrintActionId: ID
  $printedShippingLabelIds: [ID!]
  $printedPackingSlipForSuborderIds: [ID!]
  $printedPickList: Boolean
) {
  batchPrint(
    input: {
      mdashAccountId: $mdashAccountId
      toShipOn: $toShipOn
      packageIds: $packageIds
      facilityType: $facilityType
      facilityId: $facilityId
      batchPrintActionId: $batchPrintActionId
      printedShippingLabelIds: $printedShippingLabelIds
      printedPackingSlipForSuborderIds: $printedPackingSlipForSuborderIds
      printedPickList: $printedPickList
    }
  ) {
    subproductSummary {
      products {
        id
        name
        quantity
        subproducts {
          id
          name
          optional
          quantity
        }
      }
      subproducts {
        id
        name
        sku
        optional
        quantity
      }
      totalPackages
      selectedPackages
    }
    batchPrintAction {
      id
      length
      fulfillmentSheetsPartiallyPrinted
      shippingLabelsPartiallyPrinted
      pickListsPartiallyPrinted
    }
    markedShippingLabels {
      id
    }
    markedPackingSlipForPackages {
      id
    }
    errors
  }
}

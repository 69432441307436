mutation updateShipper(
  $shipperId: ID!,
  $usesAutoAssignmentV2: Boolean
) {
  updateShipper(
    input: {
      shipperId: $shipperId,
      usesAutoAssignmentV2: $usesAutoAssignmentV2,
    }
  ) {
    errors 
    shipper {
      id
      usesAutoAssignmentV2
    }
  }
}

import { FC, useEffect, useMemo, useState } from 'react'
import { useQuery } from 'urql'
import { format, parseISO } from 'date-fns'
import { IAccessLogEntity } from 'src/graphql/types'
import { ResponsiveLine } from '@nivo/line'
import getSystemAdoption from 'src/graphql/queries/getSystemAdoption.graphql'
import {
  IGetSystemAdoptionQuery,
  IGetSystemAdoptionQueryVariables,
} from 'src/graphql/queries/getSystemAdoption.types'
import Skeleton from 'react-loading-skeleton'
import Slider from 'rc-slider'
import { throttle } from 'lodash'

const sortOrder: { [key: string]: number } = {
  MD2: 3,
  MDX: 4,
  Both: 2,
}

const SystemAdoption: FC = () => {
  const [ aggregateBy, setAggregateBy ] = useState<IAccessLogEntity>( IAccessLogEntity.MERCHANT_USER )
  const [ sliceRange, setSliceRange ] = useState([ 0, 0 ])
  const [ rtSliceRange, setRtSliceRange ] = useState([ 0, 0 ])
  const throttledRangeUpdater = useMemo(() => throttle(( x ) => setSliceRange( x ), 500 ), [])

  const [{ data }, executeQuery ] = useQuery<
    IGetSystemAdoptionQuery,
    IGetSystemAdoptionQueryVariables
  >({
    query: getSystemAdoption,
    variables: {
      aggregateBy,
    },
    requestPolicy: 'cache-and-network',
  })
  const dataLength = data?.accessLogs[0].data.length || 0

  useEffect(() => {
    if ( sliceRange[1] === 0 ) {
      setRtSliceRange([ dataLength - 42, dataLength ])
      throttledRangeUpdater([ dataLength - 42, dataLength ])
    }
  }, [ dataLength ])

  useEffect(() => {
    window.addEventListener( 'focus', executeQuery )
  }, [])

  return (
    <div>
      <div className="flex justify-center py-4">
        Aggregate by:
        <label htmlFor="aggregate-by-merchant-user" className="pl-4">
          <input
            id="aggregate-by-merchant-user"
            type="radio"
            checked={aggregateBy === IAccessLogEntity.MERCHANT_USER}
            onChange={( e ) => e.target.checked && setAggregateBy( IAccessLogEntity.MERCHANT_USER )}
          />
          <span className="pl-2">Merchant User</span>
        </label>
        <label htmlFor="aggregate-by-mdash-account" className="pl-4">
          <input
            id="aggregate-by-mdash-account"
            type="radio"
            checked={aggregateBy === IAccessLogEntity.MDASH_ACCOUNT}
            onChange={( e ) => e.target.checked && setAggregateBy( IAccessLogEntity.MDASH_ACCOUNT )}
          />
          <span className="pl-2">MDash Account</span>
        </label>
      </div>

      <div className="px-4">
        <Slider
          range
          pushable
          min={0}
          max={dataLength}
          value={rtSliceRange}
          onChange={( x ) => {
            setRtSliceRange( x as number[])
            throttledRangeUpdater( x )
          }}
        />
      </div>

      <div className="flex justify-center">
        <div className="h-125 w-175">
          {data ? (
            <ResponsiveLine
              data={data.accessLogs
                .sort(( a, b ) => sortOrder[b.id] - sortOrder[a.id])
                .map(( x ) => ({
                  id: x.id,
                  data: x.data.slice( sliceRange[0], sliceRange[1]),
                }))}
              margin={{ top: 32, right: 32, bottom: 96, left: 64 }}
              animate
              enableArea
              isInteractive
              enablePointLabel
              pointLabelYOffset={-8}
              axisBottom={{
                tickRotation: -90,
                format: ( value ) => format( parseISO( value ), 'EEEEEE, M/d' ),
              }}
              axisLeft={{
                legend: 'Daily Access',
                legendPosition: 'middle',
                legendOffset: -48,
              }}
              colors={{ scheme: 'set2' }}
              legends={[
                {
                  anchor: 'top-left',
                  direction: 'column',
                  itemWidth: 32,
                  itemHeight: 24,
                  translateX: 16,
                  translateY: 8,
                  symbolShape: 'circle',
                },
              ]}
            />
          ) : (
            <Skeleton className="h-full" />
          )}
        </div>
      </div>
    </div>
  )
}

export default SystemAdoption

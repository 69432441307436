query getInvoiceRefundsReport(
    $mdashAccountId: ID!,
    $invoiceId: ID!) {
  invoiceRefundsReport(mdashAccountId: $mdashAccountId, invoiceId: $invoiceId) {
    comment
    createdAt
    id
    lineItems {
      name
      quantityOrWeight
      customerChoices {
        name
      }
    }
    responsibleParty
    orderId
    reason
    packageId
    shippedOn
    refundsShipping
    totalAdjustment
  }
}

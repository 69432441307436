import { ResponsiveTreeMap } from '@nivo/treemap'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import Slider from 'rc-slider'
import DatePicker from 'src/components/01-atoms/DatePicker'
import getDailyPrintActivity from 'src/graphql/queries/getDailyPrintActivity.graphql'
import {
  IGetDailyPrintActivityQuery,
  IGetDailyPrintActivityQueryVariables,
} from 'src/graphql/queries/getDailyPrintActivity.types'
import { useQuery } from 'urql'
import { dateForManifest } from 'src/utils/helpers/date'

import { useSearchParams } from 'react-router-dom'
import {
  addDays,
  isFriday,
  isMonday,
  isThursday,
  isTuesday,
  isWednesday,
  isWeekend,
  nextMonday,
  parseISO,
} from 'date-fns'
import { patternDotsDef } from '@nivo/core'
import { throttle } from 'lodash'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faArrowLeftToLine,
  faArrowRight,
  faArrowRightToLine,
} from '@fortawesome/pro-solid-svg-icons'
import classNames from 'classnames'
import AdminHead from 'src/pages/elements/AdminHead'
import { colorize } from './utils/colorize'
import 'rc-slider/assets/index.css'
import './styles.css'

const maxAsInfinity = 100

const DailyPrintActivity: FC = () => {
  const [ searchParams, setSearchParams ] = useSearchParams()
  const toShipFromURI = searchParams.get( 'to_ship_on' )
  const [ toShipOn, setToShipOn ] = useState(
    toShipFromURI || dateForManifest( isWeekend( new Date()) ? nextMonday( new Date()) : new Date())
  )
  const [ topNAccounts, setTopNAccounts ] = useState( 25 )
  const [ rtTopNAccounts, setRtTopNAccounts ] = useState( 25 )
  const [{ data, fetching }] = useQuery<
    IGetDailyPrintActivityQuery,
    IGetDailyPrintActivityQueryVariables
  >({
    query: getDailyPrintActivity,
    variables: {
      toShipOn,
    },
    requestPolicy: 'cache-and-network',
  })

  const selectedDate = parseISO( toShipOn )
  const nextDay = () =>
    !fetching && setToShipOn( dateForManifest( addDays( selectedDate, isFriday( selectedDate ) ? 3 : 1 )))
  const prevDay = () =>
    !fetching &&
    setToShipOn( dateForManifest( addDays( selectedDate, isMonday( selectedDate ) ? -3 : -1 )))

  const throttledTopNAccountsUpdater = useMemo(() => throttle(( v ) => setTopNAccounts( v ), 500 ), [])
  const showAllAccounts = useMemo(() => topNAccounts === maxAsInfinity, [ topNAccounts ])
  const formattedData = useMemo(() => {
    if ( !data ) return { id: 'root', name: 'Loading...', children: []}

    const activities = ( name: string ) => {
      const allSorted = ( data.dailyPrintActivity.find(( x ) => x.name === name )?.children || []).sort(
        ( a, b ) => b.value - a.value
      )

      if ( showAllAccounts ) return allSorted

      const restOfMerchants = allSorted.slice( topNAccounts )
      const restOfMerchantsLength = allSorted.length - topNAccounts

      return allSorted.slice( 0, topNAccounts ).concat({
        id: `${name}-rest-of-accounts`,
        name: `${restOfMerchantsLength} Other`,
        value: restOfMerchants.map(( x ) => x.value ).reduce(( a, b ) => a + b, 0 ),
      })
    }

    return [
      {
        id: 'migrated',
        name: 'Migrated',
        children: [
          {
            id: 'migrated-printed-mdx',
            name: 'Printed via MDX',
            children: activities( 'Migrated - Printed via MDX' ),
          },
          {
            id: 'migrated-printed-md2',
            name: 'Printed via MD2',
            children: activities( 'Migrated - Printed via MD2' ),
          },
          {
            id: 'migrated-not-printed',
            name: 'Migrated - Not Printed',
            children: activities( 'Migrated - Not Printed' ),
          },
        ].filter(( x ) => x.children.length > 0 ),
      },
      {
        id: 'not-migrated',
        name: 'Not Migrated',
        children: [
          {
            id: 'not-migrated-printed',
            name: 'Not Migrated - Printed',
            children: activities( 'Not Migrated - Printed' ),
          },
          {
            id: 'not-migrated-not-printed',
            name: 'Not Migrated - Not Printed',
            children: activities( 'Not Migrated - Not Printed' ),
          },
        ].filter(( x ) => x.children.length > 0 ),
      },
    ].filter(( x ) => x.children.length > 0 )
  }, [ data, topNAccounts, showAllAccounts ])

  const wrapper = useMemo(
    () => ({
      id: 'root',
      name: `Total Packages To Ship On ${toShipOn}`,
      children: formattedData,
    }),
    [ formattedData, toShipOn ]
  )

  const tooltip = useCallback(
    ( name: string, value: number ) => (
      <div className="bg-gb-gray-300 py-2 px-4 rounded opacity-90">
        <span>{name}: </span>
        <span>{value}</span>
      </div>
    ),
    []
  )

  useEffect(() => {
    setSearchParams({ to_ship_on: toShipOn })
  }, [ toShipOn ])

  return (
    <>
      <AdminHead pageTitle="Print Map" />
      <div className="mt-6 w-full px-4 pb-4 self-center lg:w-4/5 lg:px-0">
        <div className="flex justify-center items-center pb-4">
          <div className="text-xl text-right pr-4">PrintMaps</div>
          <FontAwesomeIcon
            icon={isMonday( selectedDate ) ? faArrowLeftToLine : faArrowLeft}
            className={classNames(
              'text-2xl pr-2 transition-colors',
              fetching ? 'text-gb-gray-600' : 'cursor-pointer text-gb-blue-600'
            )}
            onClick={prevDay}
          />
          <div className="w-32">
            <div>
              <DatePicker
                value={toShipOn}
                filterDate={( x ) => !isWeekend( x )}
                onChange={( x ) => setToShipOn( dateForManifest( x || new Date()))}
              />
            </div>
            <div className="grid grid-cols-5 gap-1 pt-0.5">
              <div
                className={classNames(
                  'h-0.5',
                  isMonday( selectedDate ) ? 'bg-gb-blue-500' : 'bg-gb-gray-300'
                )}
              />
              <div
                className={classNames(
                  'h-0.5',
                  isTuesday( selectedDate ) ? 'bg-gb-blue-500' : 'bg-gb-gray-300'
                )}
              />
              <div
                className={classNames(
                  'h-0.5',
                  isWednesday( selectedDate ) ? 'bg-gb-blue-500' : 'bg-gb-gray-300'
                )}
              />
              <div
                className={classNames(
                  'h-0.5',
                  isThursday( selectedDate ) ? 'bg-gb-blue-500' : 'bg-gb-gray-300'
                )}
              />
              <div
                className={classNames(
                  'h-0.5',
                  isFriday( selectedDate ) ? 'bg-gb-blue-500' : 'bg-gb-gray-300'
                )}
              />
            </div>
          </div>
          <FontAwesomeIcon
            icon={isFriday( selectedDate ) ? faArrowRightToLine : faArrowRight}
            className={classNames(
              'text-2xl pl-2 transition-colors',
              fetching ? 'text-gb-gray-600' : 'cursor-pointer text-gb-blue-600'
            )}
            onClick={nextDay}
          />
        </div>
        <div className="flex justify-center pb-2">
          <div className="w-64 text-sm text-center">
            {showAllAccounts ? 'Showing All Accounts' : `Showing Top ${rtTopNAccounts} Accounts`}
            <Slider
              min={10}
              max={100}
              value={rtTopNAccounts}
              onChange={( x ) => {
                setRtTopNAccounts( Number( x ))
                throttledTopNAccountsUpdater( x )
              }}
            />
          </div>
        </div>
        <div className="text-xs text-gb-gray-700 text-center pb-2">
          System 4 excluding SmartWarehouse & SelfShipper
        </div>
        <div className="h-[calc(100vh-252px)]">
          <ResponsiveTreeMap
            data={wrapper}
            tile="binary"
            label={( n ) => n.data.name.slice( 0, ( n.labelRotation !== 0 ? n.height : n.width ) / 8 )}
            labelTextColor={{ from: 'color', modifiers: [[ 'darker', 1.5 ]]}}
            labelSkipSize={16}
            outerPadding={4}
            innerPadding={4}
            colors={( x ) => colorize({ id: x.isLeaf ? x.pathComponents[2] : x.id, fetching })}
            parentLabel={( x ) => `${x.data.name}: ${x.value}`}
            parentLabelTextColor={{
              from: 'color',
              modifiers: [[ 'darker', 1.5 ]],
            }}
            parentLabelSize={24}
            motionConfig="stiff"
            borderColor={{ from: 'color', modifiers: [[ 'darker', 0.5 ]]}}
            tooltip={({ node }) => tooltip( String( node.data.name ), node.value )}
            defs={[
              patternDotsDef( 'pattern', {
                size: 2,
                padding: 1,
                stagger: true,
                background: 'transparent',
                color: 'rgba(160,160,160,0.75)',
              }),
            ]}
            fill={[
              {
                match: ( node ) => !!node.data.name.match( /\d+ Other/ ),
                id: 'pattern',
              },
            ]}
            onClick={( n ) =>
              n.isLeaf &&
              !n.id.match( /rest-of-accounts/ ) &&
              window.open( `/${n.data.id}/manifest/${toShipOn}` )
            }
          />
        </div>
      </div>
    </>
  )
}

export default DailyPrintActivity

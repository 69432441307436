query getUserIdentity {
  identity {
    adminUser {
      id
      name
      email
    }
    merchantUser {
      id
      mdashAccount {
        id
        name
        smsNotificationEnabled
        displaySku
        isMultiPickUpLocation
        isMultiShippingFacility
        isPickUpEnabled
      }
      name
      email
      permissions {
        canUseGuac
        canEditDeliveryDate
        canCancelSuborders
        canEditGiftMessages
      }
    }
  }
}

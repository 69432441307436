mutation assignProductToTaxonomy(
  $mdashAccountId: ID!
  $productId: ID!
  $taxonomyId: ID!
) {
  assignProductToTaxonomy(
    input: {
      mdashAccountId: $mdashAccountId
      productId: $productId
      taxonomyId: $taxonomyId
    }
  ) {
    errors
    productTaxonomy {
      id
      isEnabled
    }
  }
}
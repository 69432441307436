query getInvoiceGiftCardsReport(
    $mdashAccountId: ID!,
    $invoiceId: ID!) {
  invoiceGiftCardRedemptionsReport(mdashAccountId: $mdashAccountId, invoiceId: $invoiceId) {
    chargedVia
    description
    giftCardCode
    id
    orderId
    packageId
    redeemedAt
    totalAdjustment
  }
}

import { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from 'urql'

import Container from 'src/components/01-atoms/Container'
import Alert from 'src/components/01-atoms/Alert'
import CustomerProfile from 'src/components/05-pages/CustomerProfile'

import useAppParams from 'src/utils/hooks/useAppParams'
import { gbmcCustomerLink } from 'src/utils/gbmcLink'
import { IAddress } from 'src/utils/types/IAddress'
import { stringAsDate } from 'src/utils/helpers/date'

import getPurchaserAccount from 'src/graphql/queries/getPurchaserAccount.graphql'
import {
  IGetPurchaserAccountQuery,
  IGetPurchaserAccountQueryVariables,
} from 'src/graphql/queries/getPurchaserAccount.types'
import getPurchaserOrders from 'src/graphql/queries/getPurchaserOrders.graphql'
import {
  IGetPurchaserOrdersQuery,
  IGetPurchaserOrdersQueryVariables,
} from 'src/graphql/queries/getPurchaserOrders.types'
import getPurchaserOrderDetails from 'src/graphql/queries/getPurchaserOrderDetails.graphql'
import {
  IGetPurchaserOrderDetailsQuery,
  IGetPurchaserOrderDetailsQueryVariables,
} from 'src/graphql/queries/getPurchaserOrderDetails.types'

import MDashHead from './elements/MDashHead'

type OrderOverview = {
  orderId: string
  placedOn: Date
  totalAmountInCents: number
  isGift: boolean
}

const PurchaserProfile: FC = () => {
  const { purchaserId } = useParams()
  const { makeLinkUrls, mdashAccountId, isAdmin } = useAppParams()
  const [ orders, setOrders ] = useState<OrderOverview[]>([])
  const [ page, setPage ] = useState( 0 )
  const pageLimit = 5
  const [ orderIdToFetch, setOrderIdToFetch ] = useState<string>()

  const [{ fetching: accountFetching, error: accountError, data: accountData }] = useQuery<
    IGetPurchaserAccountQuery,
    IGetPurchaserAccountQueryVariables
  >({
    query: getPurchaserAccount,
    variables: {
      id: purchaserId!,
      mdashAccountId,
    },
    pause: Number( mdashAccountId || 0 ) < 1 || !purchaserId,
  })

  const [{ fetching: ordersFetching, error: ordersError, data: orderData }] = useQuery<
    IGetPurchaserOrdersQuery,
    IGetPurchaserOrdersQueryVariables
  >({
    query: getPurchaserOrders,
    variables: {
      id: purchaserId!,
      mdashAccountId,
      limit: pageLimit,
      page,
    },
    pause: Number( mdashAccountId || 0 ) < 1 || !purchaserId,
  })

  const [{ fetching: orderDetailFetching, error: orderDetailError, data: orderDetailData }] =
    useQuery<IGetPurchaserOrderDetailsQuery, IGetPurchaserOrderDetailsQueryVariables>({
      query: getPurchaserOrderDetails,
      variables: {
        id: orderIdToFetch!,
        mdashAccountId,
      },
      pause: Number( mdashAccountId || 0 ) < 1 || !orderIdToFetch,
    })

  useEffect(() => {
    if (
      ordersFetching ||
      !orderData ||
      !orderData?.purchaserAccount?.orders ||
      !orderData?.purchaserAccount?.orders?.collection ||
      orderData?.purchaserAccount?.orders?.collection.length === 0
    )
      return

    const appendingOrders = orderData?.purchaserAccount?.orders?.collection.map(( o ) => ({
      orderId: o.id,
      placedOn: stringAsDate( o.completedByCustomerAt ),
      totalAmountInCents: o.totalInCents,
      isGift: !!o.giftMessage || !!o.giftMessageFrom,
    })) as OrderOverview[]

    setOrders(( prevOrders ) =>
      [ ...prevOrders, ...appendingOrders ].filter(
        ( value, index, self ) => self.findIndex(( o ) => o.orderId === value.orderId ) === index
      )
    )
  }, [ ordersFetching, orderData ])

  useEffect(() => {
    if ( orderDetailFetching || !orderDetailData ) return

    setOrders(( prevOrders ) =>
      prevOrders.map(( o ) => {
        if ( o.orderId !== orderDetailData.order.id ) return o
        const { order } = orderDetailData
        return {
          ...o,
          orderId: order.id,
          orderUrl: makeLinkUrls().orderDetail( order.id ),
          shipTo: {
            firstName: order.deliveryFirstName,
            lastName: order.deliveryLastName,
            company: order.deliveryCompany,
            street1: order.deliveryStreet1,
            street2: order.deliveryStreet2,
            city: order.deliveryCity,
            state: order.deliveryState,
            postalCode: order.deliveryPostalCode,
            countryCode: order.deliveryCountry,
          },
          products: order.cartItems.map(( li ) => ({
            id: li.id,
            quantity: li.quantity,
            name: li.product.name,
            productOptions: li.selectedProductOptions.map(
              ( po ) => `${po.variance?.name}: ${po.productOption?.name}`
            ),
            specialInstructions: li.specialInstructions,
          })),
          toShipOn: stringAsDate( order.packages?.[0]?.toShipOn ),
          requestedDeliveryOn: stringAsDate( order.packages?.[0]?.requestedDeliveryOn ),
        }
      })
    )
  }, [ orderDetailFetching, orderDetailData ])

  const { purchaserAccount } = accountData || {}

  return (
    <>
      <MDashHead pageTitle={`Purchaser #${purchaserId}`} />
      <Container className="pt-6 pb-9">
        {!accountFetching && ( !accountData || accountError ) && (
          <Alert type="error">{accountError?.message}</Alert>
        )}
        {!ordersFetching && ( !orderData || ordersError ) && (
          <Alert type="error">{ordersError?.message}</Alert>
        )}
        {orderDetailError && <Alert type="error">{orderDetailError?.message}</Alert>}

        {purchaserAccount && (
          <CustomerProfile
            pageLimit={pageLimit}
            placeNewOrderLink={makeLinkUrls().checkoutForPurchaser( purchaserAccount.id )}
            account={{
              id: purchaserAccount?.id,
              firstName: purchaserAccount?.firstName,
              lastName: purchaserAccount?.lastName,
              email: purchaserAccount?.email,
              phone: purchaserAccount?.phone,
              isGuest: purchaserAccount?.isGuestAccount,
            }}
            accountAddresses={
              ( purchaserAccount?.addresses?.map(( a ) => ({
                ...a,
                countryCode: a.country,
              })) as IAddress[]) ?? []
            }
            accountSummary={{
              numTotalOrders: purchaserAccount?.numberOfTotalOrders ?? 0,
              numGiftOrders: purchaserAccount?.numberOfGiftOrders ?? 0,
              totalAmountSpentInCents: purchaserAccount?.totalSpentInCents ?? 0,
              averageOrderValueInCents: purchaserAccount?.aovInCents ?? 0,
              numRewardPoints: purchaserAccount?.totalRewardPoints ?? 0,
            }}
            accountHistory={{
              createdOn: stringAsDate( purchaserAccount?.createdAt ),
              firstPurchaseOn:
                purchaserAccount?.firstPurchaseAt &&
                stringAsDate( purchaserAccount?.firstPurchaseAt ),
              mostRecentPurchaseOn:
                purchaserAccount?.lastPurchaseAt && stringAsDate( purchaserAccount?.lastPurchaseAt ),
            }}
            orders={orders ?? []}
            isLoadingMoreOrders={ordersFetching}
            handleGetOrderSummaryContents={( orderId ) => {
              if ( !orderId ) return
              setOrderIdToFetch( orderId )
            }}
            handleViewMoreOrders={() => setPage( page + 1 )}
            gbmcLink={gbmcCustomerLink( purchaserAccount?.id )}
            showLinkToGbmc={isAdmin}
          />
        )}
      </Container>
    </>
  )
}

export default PurchaserProfile

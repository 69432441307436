import { FC } from 'react'
import { useMutation } from 'urql'

import AssignFacilityModal, {
  IAssignFacilityModalProps,
} from 'src/components/03-organisms/AssignFacilityModal'

import AssignMutation from 'src/graphql/mutations/assignPackagesToFacility.graphql'
import {
  IAssignPackagesToFacilityMutation,
  IAssignPackagesToFacilityMutationVariables,
} from 'src/graphql/mutations/assignPackagesToFacility.types'
import { Facility } from 'src/utils/types/Facility'
import useAppParams from 'src/utils/hooks/useAppParams'

interface IFacilityAssignmentProps
  extends Pick<IAssignFacilityModalProps, 'open' | 'facilities' | 'handleDone' | 'handleClose'> {
  /**
   * The list of packages selected from the table.
   */
  selectedPackages: Set<string>
}

const FacilityAssignment: FC<IFacilityAssignmentProps> = ({ selectedPackages, ...props }) => {
  const { mdashAccountId } = useAppParams()
  const [
    { error: assignmentError, data: assignment, fetching: assignmentPending },
    assignPackages,
  ] = useMutation<IAssignPackagesToFacilityMutation, IAssignPackagesToFacilityMutationVariables>(
    AssignMutation
  )

  const errors =
    !!assignment?.assignPackagesToFacility?.errors &&
    assignment?.assignPackagesToFacility?.errors.length > 0
      ? assignment?.assignPackagesToFacility?.errors?.map(
          ( e ) => `Package ID #${e.packageId}: ${e.message}`
        )
      : assignmentError?.message
      ? [ assignmentError?.message ]
      : []

  return (
    <AssignFacilityModal
      {...props}
      errors={errors}
      success={
        !assignmentPending &&
        !assignmentError &&
        !!assignment?.assignPackagesToFacility?.packages &&
        assignment?.assignPackagesToFacility?.packages.length > 0 &&
        errors.length === 0
      }
      fetching={assignmentPending}
      numPackages={selectedPackages.size}
      handleSubmit={( selected: Facility ) => {
        assignPackages({
          mdashAccountId,
          facilityId: selected.id,
          facilityType: selected.type,
          packageIds: Array.from( selectedPackages ),
        })
      }}
    />
  )
}

export default FacilityAssignment

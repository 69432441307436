import { FC } from 'react'
import { useQuery } from 'urql'
import Skeleton from 'react-loading-skeleton'
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useParams } from 'react-router-dom'

import Alert from 'src/components/01-atoms/Alert'
import Container from 'src/components/01-atoms/Container'
import Button from 'src/components/01-atoms/Button'
import Grid from 'src/components/01-atoms/Grid'
import SectionCard from 'src/components/01-atoms/SectionCard'
import USDCellValue from 'src/components/01-atoms/USDCellValue'

import MDashHead from 'src/pages/elements/MDashHead'
import useAppParams from 'src/utils/hooks/useAppParams'

import getShipperInvoice from 'src/graphql/queries/getShipperInvoice.graphql'
import {
  IGetShipperInvoicesQuery,
  IGetShipperInvoicesQueryVariables,
} from 'src/graphql/queries/getShipperInvoice.types'

const Statement: FC = () => {
  const { mdashAccountId } = useAppParams()
  const { statementId } = useParams()
  const [{ fetching, error, data }] = useQuery<
    IGetShipperInvoicesQuery,
    IGetShipperInvoicesQueryVariables
  >({
    query: getShipperInvoice,
    variables: {
      mdashAccountId: mdashAccountId ?? '',
      id: statementId ?? '',
    },
    requestPolicy: 'cache-and-network',
    pause: !mdashAccountId,
  })

  const rowClasses =
    'flex justify-between font-semibold border-x-2 border-b-2 border-gb-gray-400 h-14 px-4 items-center'
  const cellClasses = 'grid gap-4 grid-flow-col -mr-2 items-center'

  const statement = data?.shipperInvoices?.length ? data?.shipperInvoices[0] : null

  return (
    <>
      <MDashHead pageTitle="Statement" />
      <Container className="pt-6 pb-9">
        {fetching && <Skeleton height="50vh" />}
        {!fetching && ( !data || error ) && <Alert type="error">{error?.message}</Alert>}
        {statement && (
          <>
            <div className="mb-8 flex justify-between">
              <div>
                <Link
                  className="text-gb-gray-800 font-semibold text-xs"
                  to={`/${mdashAccountId}/statements/`}
                >
                  <FontAwesomeIcon icon={faAngleLeft} /> Back to Statements
                </Link>
                <h1 className="text-2xl font-semibold block mt-1">Statement #{statement.id}</h1>
              </div>
              <div className="flex items-end">
                {statement.pdfDocumentUrl && (
                  <Button
                    to={statement.pdfDocumentUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="block"
                    outline
                  >
                    Print Statement
                  </Button>
                )}
              </div>
            </div>

            <SectionCard className="col-span-12 mb-8">
              <Grid>
                <div
                  data-testid="section-statment"
                  className="col-span-12 lg:col-span-5 md:row-span-1"
                >
                  <div className={`${rowClasses} rounded-t border-t-2`}>
                    <div>Statement Start</div>
                    <div>{statement.statementStart}</div>
                  </div>
                  <div className={rowClasses}>
                    <div>Statement Start</div>
                    <div>{statement.statementEnd}</div>
                  </div>
                  <div className={`${rowClasses} rounded-b bg-gb-gray-100`}>
                    <div>Invoice Total</div>
                    <div>
                      <USDCellValue amount={statement.invoiceableTotal ?? '$0.00'} />
                    </div>
                  </div>
                </div>

                <div
                  data-testid="section-statment"
                  className="col-span-12 lg:col-span-7 md:row-span-1"
                >
                  <div className={`${rowClasses} rounded-t border-t-2`}>
                    <div>Sales</div>
                    <div className={cellClasses} data-testid="sales">
                      <div>
                        <USDCellValue amount={statement.salesTotal} />
                      </div>
                      <Button outline to="sales">
                        View Details
                      </Button>
                    </div>
                  </div>
                  <div className={rowClasses}>
                    <div>Missed Shipments</div>
                    <div className={cellClasses} data-testid="missed-shipments">
                      <div>
                        <USDCellValue amount={statement.missedShipmentsTotal} />
                      </div>
                      <Button outline to="missed_shipments">
                        View Details
                      </Button>
                    </div>
                  </div>
                  <div className={rowClasses}>
                    <div>Reshipments</div>
                    <div className={cellClasses} data-testid="reshipments">
                      <div>
                        <USDCellValue amount={statement.reshipmentsTotal} />
                      </div>
                      <Button outline to="reshipments">
                        View Details
                      </Button>
                    </div>
                  </div>
                  <div className={rowClasses}>
                    <div>Refunds</div>
                    <div className={cellClasses} data-testid="refunds">
                      <div>
                        <USDCellValue amount={statement.refundsTotal} />
                      </div>
                      <Button outline to="refunds">
                        View Details
                      </Button>
                    </div>
                  </div>
                  <div className={rowClasses} data-testid="cancellations">
                    <div>Cancellations</div>
                    <div className={cellClasses}>
                      <div>
                        <USDCellValue amount={statement.cancellationsTotal} />
                      </div>
                      <Button outline to="cancellations">
                        View Details
                      </Button>
                    </div>
                  </div>
                  <div className={rowClasses}>
                    <div>Promotions</div>
                    <div className={cellClasses} data-testid="promotions">
                      <div>
                        <USDCellValue amount={statement.promotionsTotal} />
                      </div>
                      <Button outline to="promotions">
                        View Details
                      </Button>
                    </div>
                  </div>
                  <div className={rowClasses}>
                    <div>Gift Card Redemptions</div>
                    <div className={cellClasses} data-testid="gift-card-redemptions">
                      <div>
                        <USDCellValue amount={statement.giftCardRedemptionsTotal} />
                      </div>
                      <Button outline to="gift_card_redemptions">
                        View Details
                      </Button>
                    </div>
                  </div>
                  <div className={`${rowClasses} rounded-b`}>
                    <div>Others</div>
                    <div className={cellClasses} data-testid="others">
                      <div>
                        <USDCellValue amount={statement.adjustmentsTotal} />
                      </div>
                      <Button outline to="others">
                        View Details
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </SectionCard>
          </>
        )}
      </Container>
    </>
  )
}

export default Statement

import { ChangeEvent, FC, MouseEventHandler, useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { FieldValues, useFormContext } from 'react-hook-form'

import Alert from 'src/components/01-atoms/Alert'
import Button from 'src/components/01-atoms/Button'
import Fieldset from 'src/components/01-atoms/Fieldset'
import SectionCard from 'src/components/01-atoms/SectionCard'
import StatusIconWrapper from 'src/components/01-atoms/StatusIconWrapper'
import AlertList from 'src/components/02-molecules/AlertList'
import RecipientInfoForm from 'src/components/02-molecules/RecipientInfoForm'
import ShippingAddressForm from 'src/components/02-molecules/ShippingAddressForm'

import useManageOrderPartial from 'src/utils/hooks/useManageOrderPartial'
import {
  ShippingInfoOnly,
  isShippingAddressIncomplete,
  requiredShippingFieldNames,
  allShippingFieldNames,
  addressOnly,
} from 'src/utils/helpers/shipping'

import updateShippingInfoMutation from 'src/graphql/mutations/updateShippingInfo.graphql'
import { IUpdateShippingInfoMutation } from 'src/graphql/mutations/updateShippingInfo.types'
import useGetDeliveryStates from 'src/utils/hooks/useGetDeliveryStates'
import useSmartyStreets, { IDeliveryAddress } from 'src/utils/hooks/useSmartyStreets'
import { getFieldErrors } from 'src/utils/helpers/forms'
import getShippingInfoQuery from 'src/graphql/queries/getOrderShippingInfo.graphql'
import { IGetOrderShippingInfoQuery } from 'src/graphql/queries/getOrderShippingInfo.types'
import AddressOverrideModal from 'src/components/03-organisms/AddressOverrideModal'

const ShippingInfo: FC = () => {
  const { data: stateData, fetching: fetchingStates, error: statesError } = useGetDeliveryStates()
  const { data, fetching, errors, update } = useManageOrderPartial<
    IGetOrderShippingInfoQuery,
    IUpdateShippingInfoMutation,
    ShippingInfoOnly
  >( getShippingInfoQuery, updateShippingInfoMutation )
  const [ overrideModalText, setOverrideModalText ] = useState<string | undefined>()
  const [ addressNote, setAddressNote ] = useState<string>()
  const [ addressIsValidated, setAddressIsValidated ] = useState<boolean>( false )
  const [ showOverrideModal, setShowOverrideModal ] = useState<boolean>( false )

  const resetErrorsAndNotes = () => {
    setAddressNote( undefined )
    setOverrideModalText( undefined )
  }

  const {
    validateAddress,
    debounceAutocompleteSearch,
    addressSearchResults,
    makePrettyAddressString,
  } = useSmartyStreets()

  const { register, setValue, getValues, formState, reset } = useFormContext()

  const requiredShippingValues = Object.fromEntries(
    Object.entries( getValues()).filter(([ k ]) => requiredShippingFieldNames.includes( k ))
  ) as ShippingInfoOnly
  const savedShippingValues =
    ( data?.order &&
      Object.fromEntries(
        Object.entries( data?.order ).filter(([ k ]) => requiredShippingFieldNames.includes( k ))
      )) ||
    {}
  const shippingAddressIsComplete = !isShippingAddressIncomplete( requiredShippingValues )
  const addressIsSaved =
    isEqual( requiredShippingValues, savedShippingValues ) &&
    shippingAddressIsComplete &&
    getValues( 'deliveryStreet2' ) === data?.order?.deliveryStreet2

  const handleAddressSelection = ({ street1, street2, city, state, zipCode }: IDeliveryAddress ) => {
    setValue( 'deliveryStreet1', street1 )
    setValue( 'deliveryStreet2', street2 )
    setValue( 'deliveryCity', city )
    setValue( 'deliveryState', state )
    setValue( 'deliveryPostalCode', zipCode )
  }

  useEffect(() => {
    const fieldsAreDirty = addressOnly.some(( fieldName ) => !!formState.dirtyFields[fieldName])
    setAddressIsValidated( !fieldsAreDirty )
  })

  useEffect(() => {
    if ( !addressIsValidated ) return
    resetErrorsAndNotes()
  }, [ addressIsValidated ])

  useEffect(() => {
    const fieldsAreDirty = allShippingFieldNames.some(
      ( fieldName ) => formState.dirtyFields[fieldName]
    )
    if ( !data?.order || fetching || fieldsAreDirty ) return
    setValue( 'deliveryFirstName', data.order.deliveryFirstName )
    setValue( 'deliveryLastName', data.order.deliveryLastName )
    setValue( 'deliveryCompany', data.order.deliveryCompany )
    setValue( 'recipientEmail', data.order.recipientEmails?.[0])
    setValue( 'deliveryPhone', data.order.deliveryPhone )
    handleAddressSelection({
      street1: data.order?.deliveryStreet1 || '',
      street2: data.order?.deliveryStreet2 || '',
      city: data.order?.deliveryCity || '',
      state: data.order?.deliveryState || '',
      zipCode: data.order?.deliveryPostalCode || '',
    })
  }, [ data, fetching ])

  const saveAddressToDB = () => {
    const values = getValues()
    const validatedAddress: ShippingInfoOnly = {
      deliveryFirstName: values.deliveryFirstName,
      deliveryLastName: values.deliveryLastName,
      deliveryPhone: values.deliveryPhone,
      deliveryCompany: values.deliveryCompany,
      deliveryStreet1: values.deliveryStreet1,
      deliveryStreet2: values.deliveryStreet2,
      deliveryCity: values.deliveryCity,
      deliveryState: values.deliveryState,
      deliveryPostalCode: values.deliveryPostalCode,
      recipientEmails: [ values.recipientEmail ],
    }
    update( validatedAddress ).then(( response ) => {
      resetErrorsAndNotes()
      setAddressIsValidated(
        response.data?.updateShippingAddress?.order?.isValidShippingAddress || true
      )
      reset()
    })
  }

  const handleSubmitAddress: MouseEventHandler<HTMLButtonElement> = async () => {
    resetErrorsAndNotes()

    const values: FieldValues = getValues()

    const addressIsValid = await validateAddress({
      street1: values.deliveryStreet1,
      street2: values.deliveryStreet2,
      city: values.deliveryCity,
      state: values.deliveryState,
      zipCode: values.deliveryPostalCode,
    })
    if (
      !( addressIsValid.valid && addressIsValid.address ) &&
      !addressIsValidated &&
      !addressIsSaved
    ) {
      if ( addressIsValid.error ) {
        setAddressNote( undefined )
      }
      setShowOverrideModal( !!addressIsValid?.showOverrideModal )
      setOverrideModalText( addressIsValid?.error ?? undefined )
      return
    }

    if ( addressIsValid.note ) {
      setAddressNote( addressIsValid.note )
    }

    if ( addressIsValid.address ) {
      handleAddressSelection( addressIsValid.address )
    }
    saveAddressToDB()
  }

  if ( statesError ) {
    Object.assign( errors, statesError?.message )
  }
  const validationErrors = getFieldErrors( formState, allShippingFieldNames )
  const hasValidationErrors = Object.values( validationErrors ).length > 0

  return (
    <Fieldset disabled={!stateData?.states || fetching}>
      <SectionCard
        size="medium"
        title={
          <div className="flex justify-between">
            Shipping Address{' '}
            {addressIsValidated && (
              <StatusIconWrapper className="text-green-500" size="small" level="success">
                Address Validated
              </StatusIconWrapper>
            )}
          </div>
        }
        error={errors.length > 0 || hasValidationErrors}
        contentClassName="flex flex-col gap-y-8"
      >
        {errors.length > 0 && <AlertList alerts={errors} type="error" className="mb-4" />}
        <RecipientInfoForm
          fieldProps={{
            firstName: {
              error: validationErrors.deliveryFirstName,
              ...register( 'deliveryFirstName', {
                required: true,
              }),
            },
            lastName: {
              error: validationErrors.deliveryLastName,
              ...register( 'deliveryLastName', {
                required: true,
              }),
            },
            company: {
              error: validationErrors.deliveryCompany,
              ...register( 'deliveryCompany' ),
            },
            email: {
              error: validationErrors.recipientEmail,
              ...register( 'recipientEmail' ),
            },
            phone: {
              error: validationErrors.deliveryPhone,
              ...register( 'deliveryPhone' ),
            },
          }}
        />
        {addressNote && <Alert type="info">{addressNote}</Alert>}
        {!!stateData?.states && (
          <ShippingAddressForm
            fieldProps={{
              addressLine1: {
                error: validationErrors.deliveryStreet1,
                ...register( 'deliveryStreet1', {
                  required: true,
                  onChange: ( e: ChangeEvent<HTMLInputElement> ) => {
                    debounceAutocompleteSearch( e.currentTarget.value )
                  },
                }),
                searchResults: addressSearchResults?.map(( v, i ) => ({
                  value: `${i}`,
                  label: makePrettyAddressString( v ),
                })),
                handleSelect: ( v ) => {
                  const i = Number( v )
                  if ( !( addressSearchResults && addressSearchResults[i])) return
                  handleAddressSelection( addressSearchResults[i])
                },
              },
              addressLine2: {
                ...register( 'deliveryStreet2' ),
              },
              city: {
                error: validationErrors.deliveryCity,
                ...register( 'deliveryCity', {
                  required: true,
                }),
              },
              state: {
                options: [
                  { value: '', label: '' },
                  ...( stateData?.states.map(({ abbreviation, name }) => ({
                    value: abbreviation,
                    label: name,
                  })) || []),
                ],
                error: validationErrors.deliveryState,
                ...register( 'deliveryState', {
                  required: true,
                }),
                disabled: stateData?.states.length === 0 || fetchingStates || !!statesError,
              },
              zip: {
                error: validationErrors.deliveryPostalCode,
                ...register( 'deliveryPostalCode', {
                  required: true,
                }),
              },
            }}
          />
        )}
        <div>
          <Button
            type="button"
            onClick={handleSubmitAddress}
            disabled={
              hasValidationErrors || isShippingAddressIncomplete( requiredShippingValues ) || fetching
            }
            outline
          >
            {addressIsValidated ? 'Update Shipping Information' : 'Validate and Save Address'}
          </Button>
        </div>
      </SectionCard>
      <AddressOverrideModal
        open={showOverrideModal}
        errorDescription={overrideModalText}
        address={{
          street1: getValues().deliveryStreet1,
          street2: getValues().deliveryStreet2,
          city: getValues().deliveryCity,
          state: getValues().deliveryState,
          postalCode: getValues().deliveryPostalCode,
        }}
        handleApprove={() => saveAddressToDB()}
        handleClose={() => setShowOverrideModal( false )}
      />
    </Fieldset>
  )
}

export default ShippingInfo

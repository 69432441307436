import searchAccounts from 'src/graphql/queries/searchAccount.graphql'
import {
  ISearchAccountsQuery,
  ISearchAccountsQueryVariables,
} from 'src/graphql/queries/searchAccount.types'
import { useQuery } from 'urql'
import useAppParams from './useAppParams'

interface IProps {
  query: string
}

const useSearchAccounts = ({ query }: IProps ) => {
  const { isAdmin } = useAppParams()
  const [{ data, fetching }] = useQuery<ISearchAccountsQuery, ISearchAccountsQueryVariables>({
    query: searchAccounts,
    variables: {
      query: query.trim(),
    },
    requestPolicy: 'cache-and-network',
    pause: !isAdmin || query.trim().length < 3,
  })

  return { data, fetching }
}

export default useSearchAccounts

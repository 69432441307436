import { useQuery, UseQueryResponse } from 'urql'

import useAppParams from 'src/utils/hooks/useAppParams'
import getManifest from 'src/graphql/queries/getManifestPackages.graphql'
import {
  IGetManifestPackagesQuery,
  IGetManifestPackagesQueryVariables,
} from 'src/graphql/queries/getManifestPackages.types'

import { IUseGetManifestVariables } from 'src/utils/types/GetManifestHookQueryTypes'

const useGetManifestPackages = ({
  variables,
  ...useQueryOptionOverrides
}: IUseGetManifestVariables<
  IGetManifestPackagesQuery,
  IGetManifestPackagesQueryVariables
> ): UseQueryResponse<IGetManifestPackagesQuery, IGetManifestPackagesQueryVariables> => {
  const { mdashAccountId, toShipOnAsString: toShipOn } = useAppParams()

  const [ response, refetch ] = useQuery<
    IGetManifestPackagesQuery,
    IGetManifestPackagesQueryVariables
  >({
    query: getManifest,
    variables: {
      mdashAccountId,
      toShipOn,
      ...variables,
    },
    requestPolicy: 'cache-and-network',
    ...useQueryOptionOverrides,
  })

  return [ response, refetch ]
}

export default useGetManifestPackages

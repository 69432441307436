import { FC } from 'react'
import { useMutation } from 'urql'

import CancelPackageModalComponent, {
  ICancelPackageModalProps as ICancelPackageModalComponentProps,
} from 'src/components/03-organisms/CancelPackageModal'
import cancelPackage from 'src/graphql/mutations/cancelPackage.graphql'
import {
  ICancelPackageMutation,
  ICancelPackageMutationVariables,
} from 'src/graphql/mutations/cancelPackage.types'

import useAppParams from 'src/utils/hooks/useAppParams'

interface ICancelPackageModalProps extends ICancelPackageModalComponentProps {
  /**
   * The ID associated with the package that needs to be cancelled.
   */
  packageId: string
}

const CancelPackageModal: FC<ICancelPackageModalProps> = ({
  packageId,
  ...cancelPackageModalProps
}) => {
  const { mdashAccountId } = useAppParams()

  const [ cancelSuborderResponse, cancelSuborder ] = useMutation<
    ICancelPackageMutation,
    ICancelPackageMutationVariables
  >( cancelPackage )

  const handleCancel = () => {
    cancelSuborder({
      mdashAccountId,
      packageId,
    }).then(() => {
      cancelPackageModalProps.handleClose?.()
    })
  }

  return (
    <CancelPackageModalComponent
      handleCancel={handleCancel}
      cancelInProgress={cancelSuborderResponse.fetching}
      error={
        cancelSuborderResponse.error?.message ||
        cancelSuborderResponse.data?.cancelPackage?.errors.join( ' ' )
      }
      {...cancelPackageModalProps}
    />
  )
}

export default CancelPackageModal

mutation assignPackagesToFacility(
  $mdashAccountId: ID
  $facilityId: ID
  $facilityType: FacilityType
  $packageIds: [ID!]!
) {
  assignPackagesToFacility(
    input: {
      mdashAccountId: $mdashAccountId
      facilityId: $facilityId
      facilityType: $facilityType
      packageIds: $packageIds
    }
  ) {
    errors {
      message
      packageId
    }
    packages {
      id
    }
  }
}

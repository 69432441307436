query getPackagesWithLabelIssue(
  $toShipOn: String!
) {
  manifestPackagesWithLabelIssues(
    toShipOn: $toShipOn
  ) {
    id
    orderId
    fulfillmentSystem
    deliveryFirstName
    deliveryLastName
    deliveryCity
    deliveryState
    deliveryCountry
    mdashAccount {
      id
      name
    }
    lineItems {
      id
      name
      quantityOrWeight
      product {
        id
        name
      }
    }
    currentShippingLabel {
      id
      serviceName
      carrierResponse
    }
    
  }
}
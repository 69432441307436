query getInvoiceAdjustmentsReport(
    $mdashAccountId: ID!,
    $invoiceId: ID!) {
  invoiceAdjustmentsReport(mdashAccountId: $mdashAccountId, invoiceId: $invoiceId) {
    id
    orderId
    createdAt
    packageId
    amount
    details
  }
}

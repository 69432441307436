query getOrderDeliveryOptions($orderId: ID!, $mdashAccountId: ID) {
  order(id: $orderId, mdashAccountId: $mdashAccountId) {
    __typename
    id
    packages {
      __typename
      id
      cartItems {
        __typename
        id
        product {
          __typename
          id
          name
          sku
          priceInCents
        }
        selectedProductOptions {
          productOptionId
          productOption {
            id
            name
          }
          varianceId
          variance {
            id
            name
          }
        }
      }
      requestedDeliveryOn
      shippingCalendarData {
        requestedDeliveryOn
        rateInCents
        isSoldOut
      }
    }
  }
}

query getOrderInfo($mdashAccountId: ID, $id: ID!) {
  order(id: $id, mdashAccountId: $mdashAccountId) {
    id
    purchaserAccountId
    purchaserFirstName
    purchaserLastName
    purchaserPhone
    purchaserEmail
    corporateOrderCompanyName
    deliveryFirstName
    deliveryLastName
    deliveryCompany
    deliveryStreet1
    deliveryStreet2
    deliveryCity
    deliveryState
    deliveryPostalCode
    deliveryCountry
    giftMessage
    giftMessageFrom
    packages {
      id
      toShipOn
      requestedDeliveryOn
      gbmcState
      cartItems {
        id
        quantity
        weight
        product {
          id
          name
        }
        selectedProductOptions {
          productOption {
            id
            name
          }
          variance {
            id
            name
          }
        }
        specialInstructions
      }
      currentShippingLabel {
        id
        carrier
        serviceName
        trackingCode
        trackingLink
      }
      isReshipment
      isCorporateOrder
      isInStoreOrder
      isWhitelabel
      isSubscription
      corporateOrderCompanyName
    }
    isCorporateOrder
    isInStoreOrder
    isWhitelabel
    isSubscription
    processedAt
    discountInCents
    productsSubtotalInCents
    shippingSubtotalInCents
    taxInCents
    totalInCents
  }
}

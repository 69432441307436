query getPackageDeliveryOptions($mdashAccountId: ID, $packageId: ID!) {
  package(id: $packageId, mdashAccountId: $mdashAccountId) {
    __typename
    id
    isUpchargeable
    shippingChargedToCustomerInCents
    shippingCalendarData {
      requestedDeliveryOn
      rateInCents
      isSoldOut
    }
  }
}
query getPantryItemLimits($mdashAccountId: ID!, $toShipOn: String!, $pantryItemIds: [ID!]) {
  pantryItemLimits(
    mdashAccountId: $mdashAccountId
    toShipOn: $toShipOn
    pantryItemIds: $pantryItemIds
  ) {
    pantryItemId
    pantryItemName
    toShipOn
    currentQuantity
    limit
    isShippable
  }
}

query getRefundIssues($mdashAccountId: ID, $startDate: String, $endDate: String) {
  refundIssues( mdashAccountId: $mdashAccountId, startDate: $startDate, endDate: $endDate ){
    adjustmentsInCents
    category
    label
    numberOfIssues
    numberOfPackages
    percentOfPackagesWithIssues
    refunds {
      adjustmentCostInCents
      createdAt
      comment
      reasonDescription
      refundsShipping
      responsibleParty
      shippedOn
      id
      orderId
      packageId
      packageId
      productNames
    }
  }
}
query getOrderPurchaserInfo($orderId: ID!, $mdashAccountId: ID) {
  order(id: $orderId, mdashAccountId: $mdashAccountId) {
    __typename
    id
    purchaserEmail
    purchaserFirstName
    purchaserLastName
    purchaserPhone
  }
}

query getMetabaseReportsByCategory($mdashAccountId: ID) {
  metabaseReportsByCategory(mdashAccountId: $mdashAccountId) {
    category
    displayName
    displayOrder
    reports {
      id
      dashboardId
      name
      url
    }
  }
}

mutation updatePurchaserInfo(
  $orderId: ID!
  $purchaserFirstName: String!
  $purchaserLastName: String!
  $purchaserEmail: String!
  $purchaserPhone: String!
) {
  updatePurchaserInfo(
    input: {
      orderId: $orderId
      purchaserFirstName: $purchaserFirstName
      purchaserLastName: $purchaserLastName
      purchaserEmail: $purchaserEmail
      purchaserPhone: $purchaserPhone
    }
  ) {
    order {
      __typename
      id
    }
    errors
  }
}

mutation editGiftMessageOnCompletedSuborder($mdashAccountId: ID, $packageId: ID!, $body: String!, $from: String! ) {
  editGiftMessage(input: { mdashAccountId: $mdashAccountId, packageId: $packageId, body: $body, from: $from }) {
    errors
    package {
      id
      giftMessage {
        body
        from
      }
    }
  }
}
import { FC } from 'react'
import { ISvgIconProps } from '../types'

export const WhatsNewPrinting: FC<ISvgIconProps> = ( props ) => (
  <svg
    width="48"
    height="60"
    viewBox="0 0 48 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 4V58L7 53.5L13 59L17.5 54H19.5L24.5 58.5L30.5 53.5L35.5 58.5L41.5 53.5L46 58L47 4L43 1H4L1 4Z"
      fill="#DCFDFF"
    />
    <path
      d="M1 50V58L7 53.5L13 59L17.5 54H19.5L24.5 58.5L30.5 53.5L35.5 58.5L41.5 53.5L46 58L46.5 37L1 50Z"
      fill="#C1F2F5"
    />
    <path d="M36 24.5L24 45.5L11.5 24.5L21 19.5L27 20L36 24.5Z" fill="#FFE194" />
    <path d="M36 24.5L24 45.5L11.5 24.5L21 19.5L27 20L36 24.5Z" fill="#FFE194" />
    <path d="M27 20L36 24.5L33.5 28L23.5 25L14 28L11.5 24.5L21 19.5L27 20Z" fill="#CD9A4F" />
    <g clipPath="url(#clip0_5881_90480)">
      <path
        d="M10.957 23.2897C10.9149 23.3271 10.8728 23.3646 10.8307 23.4066C10.2695 23.9538 10.1526 24.8096 10.5408 25.4877L23.2563 47.2897C23.4901 47.6966 24.0139 47.8322 24.4161 47.5937C24.4769 47.5563 24.533 47.5142 24.5844 47.4628C24.6405 47.4066 24.6873 47.3458 24.7247 47.2757L37.2064 25.2165C37.6273 24.4776 37.4215 23.5469 36.7528 23.0185C29.3077 17.112 18.3038 17.2009 10.9523 23.2851L10.957 23.2897ZM23.9718 43.5298L15.5213 29.0466C20.7918 25.296 26.9554 25.2492 32.2446 28.9016L23.9671 43.5298H23.9718ZM33.4839 26.7176C27.4465 22.6724 20.268 22.7192 14.2586 26.8766L13.0568 24.819C19.2812 20.0536 28.3537 19.96 34.6811 24.5945L33.4792 26.7176H33.4839ZM19.2672 29.1494C18.6125 29.8042 18.6125 30.861 19.2672 31.5111C19.9219 32.1611 20.9788 32.1658 21.6289 31.5111C22.2789 30.8564 22.2836 29.7995 21.6289 29.1494C20.9741 28.4994 19.9172 28.4947 19.2672 29.1494ZM22.812 35.0606C22.1573 35.7153 22.1573 36.7722 22.812 37.4222C23.4667 38.0723 24.5236 38.077 25.1737 37.4222C25.8237 36.7675 25.8284 35.7106 25.1737 35.0606C24.519 34.4105 23.4621 34.4059 22.812 35.0606ZM26.3568 31.5158C27.0115 32.1705 28.0684 32.1705 28.7185 31.5158C29.3685 30.8611 29.3732 29.8042 28.7185 29.1541C28.0638 28.5041 27.0069 28.4994 26.3568 29.1541C25.7068 29.8088 25.7021 30.8657 26.3568 31.5158Z"
        fill="#9C4413"
      />
      <path
        d="M35.5744 60H35.5651C35.0881 60 34.6391 59.8083 34.3071 59.4669L29.8597 54.926L25.0756 59.4949C24.7249 59.827 24.2572 60.0047 23.7662 59.986C23.2845 59.9626 22.8262 59.7428 22.5082 59.378L18.7202 55.0101L14.3242 59.4715C13.9875 59.8129 13.5386 60 13.0616 60H13.0522C12.5705 60 12.1216 59.8036 11.7849 59.4575L7.39828 54.9213L2.97896 59.0086C2.45986 59.4856 1.70694 59.6165 1.06157 59.3313C0.416212 59.0507 0 58.41 0 57.7038V4.41465C0 1.97818 1.97818 0 4.41465 0H43.3235C45.7599 0 47.7381 1.97818 47.7381 4.41465V57.5027C47.7381 58.2463 47.2892 58.8963 46.597 59.1582C45.9049 59.4248 45.138 59.2377 44.6422 58.6859L41.3219 54.9821L36.8277 59.4762C36.491 59.8129 36.0468 59.9953 35.5744 59.9953V60ZM29.8691 52.3632C30.332 52.3632 30.7903 52.5409 31.1364 52.8917L35.5744 57.4232L40.0873 52.9104C40.4287 52.569 40.887 52.3819 41.3921 52.3913C41.8784 52.4053 42.3414 52.6204 42.6641 52.9805L45.3952 56.0296V4.41465C45.3952 3.2689 44.4645 2.33827 43.3188 2.33827H4.41465C3.2689 2.33827 2.33827 3.2689 2.33827 4.41465V56.4131L6.21512 52.8262C6.9166 52.1761 8.02962 52.2089 8.69836 52.8963L13.0663 57.4139L17.4809 52.9291C17.827 52.5783 18.304 52.3772 18.7997 52.4006C19.2907 52.4147 19.7631 52.6391 20.0857 53.0132L23.8878 57.3952L28.6438 52.8542C28.9852 52.5269 29.4295 52.3679 29.8691 52.3679V52.3632Z"
        fill="#689C9F"
      />
      <path
        d="M22.3628 6.97656H8.16478C7.3667 6.97656 6.71973 7.62353 6.71973 8.42161C6.71973 9.21969 7.3667 9.86666 8.16478 9.86666H22.3628C23.1608 9.86666 23.8078 9.21969 23.8078 8.42161C23.8078 7.62353 23.1608 6.97656 22.3628 6.97656Z"
        fill="#345D60"
      />
      <path
        d="M26.0292 12.2344H8.16478C7.3667 12.2344 6.71973 12.8813 6.71973 13.6794C6.71973 14.4775 7.3667 15.1245 8.16478 15.1245H26.0292C26.8272 15.1245 27.4742 14.4775 27.4742 13.6794C27.4742 12.8813 26.8272 12.2344 26.0292 12.2344Z"
        fill="#345D60"
      />
      <path
        d="M40.5459 12.2344H30.5896C29.7915 12.2344 29.1445 12.8813 29.1445 13.6794C29.1445 14.4775 29.7915 15.1245 30.5896 15.1245H40.5459C41.344 15.1245 41.991 14.4775 41.991 13.6794C41.991 12.8813 41.344 12.2344 40.5459 12.2344Z"
        fill="#345D60"
      />
    </g>
    <g clipPath="url(#clip1_5881_90480)">
      <path
        d="M10.957 23.2897C10.9149 23.3271 10.8728 23.3646 10.8307 23.4066C10.2695 23.9538 10.1526 24.8096 10.5408 25.4877L23.2563 47.2897C23.4901 47.6966 24.0139 47.8322 24.4161 47.5937C24.4769 47.5563 24.533 47.5142 24.5844 47.4628C24.6405 47.4066 24.6873 47.3458 24.7247 47.2757L37.2064 25.2165C37.6273 24.4776 37.4215 23.5469 36.7528 23.0185C29.3077 17.112 18.3038 17.2009 10.9523 23.2851L10.957 23.2897ZM23.9718 43.5298L15.5213 29.0466C20.7918 25.296 26.9554 25.2492 32.2446 28.9016L23.9671 43.5298H23.9718ZM33.4839 26.7176C27.4465 22.6724 20.268 22.7192 14.2586 26.8766L13.0568 24.819C19.2812 20.0536 28.3537 19.96 34.6811 24.5945L33.4792 26.7176H33.4839ZM19.2672 29.1494C18.6125 29.8042 18.6125 30.861 19.2672 31.5111C19.9219 32.1611 20.9788 32.1658 21.6289 31.5111C22.2789 30.8564 22.2836 29.7995 21.6289 29.1494C20.9741 28.4994 19.9172 28.4947 19.2672 29.1494ZM22.812 35.0606C22.1573 35.7153 22.1573 36.7722 22.812 37.4222C23.4667 38.0723 24.5236 38.077 25.1737 37.4222C25.8237 36.7675 25.8284 35.7106 25.1737 35.0606C24.519 34.4105 23.4621 34.4059 22.812 35.0606ZM26.3568 31.5158C27.0115 32.1705 28.0684 32.1705 28.7185 31.5158C29.3685 30.8611 29.3732 29.8042 28.7185 29.1541C28.0638 28.5041 27.0069 28.4994 26.3568 29.1541C25.7068 29.8088 25.7021 30.8657 26.3568 31.5158Z"
        fill="#9C4413"
      />
      <path
        d="M35.5744 60H35.5651C35.0881 60 34.6391 59.8083 34.3071 59.4669L29.8597 54.926L25.0756 59.4949C24.7249 59.827 24.2572 60.0047 23.7662 59.986C23.2845 59.9626 22.8262 59.7428 22.5082 59.378L18.7202 55.0101L14.3242 59.4715C13.9875 59.8129 13.5386 60 13.0616 60H13.0522C12.5705 60 12.1216 59.8036 11.7849 59.4575L7.39828 54.9213L2.97896 59.0086C2.45986 59.4856 1.70694 59.6165 1.06157 59.3313C0.416212 59.0507 0 58.41 0 57.7038V4.41465C0 1.97818 1.97818 0 4.41465 0H43.3235C45.7599 0 47.7381 1.97818 47.7381 4.41465V57.5027C47.7381 58.2463 47.2892 58.8963 46.597 59.1582C45.9049 59.4248 45.138 59.2377 44.6422 58.6859L41.3219 54.9821L36.8277 59.4762C36.491 59.8129 36.0468 59.9953 35.5744 59.9953V60ZM29.8691 52.3632C30.332 52.3632 30.7903 52.5409 31.1364 52.8917L35.5744 57.4232L40.0873 52.9104C40.4287 52.569 40.887 52.3819 41.3921 52.3913C41.8784 52.4053 42.3414 52.6204 42.6641 52.9805L45.3952 56.0296V4.41465C45.3952 3.2689 44.4645 2.33827 43.3188 2.33827H4.41465C3.2689 2.33827 2.33827 3.2689 2.33827 4.41465V56.4131L6.21512 52.8262C6.9166 52.1761 8.02962 52.2089 8.69836 52.8963L13.0663 57.4139L17.4809 52.9291C17.827 52.5783 18.304 52.3772 18.7997 52.4006C19.2907 52.4147 19.7631 52.6391 20.0857 53.0132L23.8878 57.3952L28.6438 52.8542C28.9852 52.5269 29.4295 52.3679 29.8691 52.3679V52.3632Z"
        fill="#689C9F"
      />
      <path
        d="M22.3628 6.97656H8.16478C7.3667 6.97656 6.71973 7.62353 6.71973 8.42161C6.71973 9.21969 7.3667 9.86666 8.16478 9.86666H22.3628C23.1608 9.86666 23.8078 9.21969 23.8078 8.42161C23.8078 7.62353 23.1608 6.97656 22.3628 6.97656Z"
        fill="#345D60"
      />
      <path
        d="M26.0292 12.2344H8.16478C7.3667 12.2344 6.71973 12.8813 6.71973 13.6794C6.71973 14.4775 7.3667 15.1245 8.16478 15.1245H26.0292C26.8272 15.1245 27.4742 14.4775 27.4742 13.6794C27.4742 12.8813 26.8272 12.2344 26.0292 12.2344Z"
        fill="#345D60"
      />
      <path
        d="M40.5459 12.2344H30.5896C29.7915 12.2344 29.1445 12.8813 29.1445 13.6794C29.1445 14.4775 29.7915 15.1245 30.5896 15.1245H40.5459C41.344 15.1245 41.991 14.4775 41.991 13.6794C41.991 12.8813 41.344 12.2344 40.5459 12.2344Z"
        fill="#345D60"
      />
    </g>
    <defs>
      <clipPath id="clip0_5881_90480">
        <rect width="47.7381" height="60" fill="white" />
      </clipPath>
      <clipPath id="clip1_5881_90480">
        <rect width="47.7381" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
